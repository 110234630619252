import { Colors } from '../../themes/colors';
import { Icon } from '../Icon';
import { Row } from '../Row';

interface IProps {
  text: string;
  extraText?: string;
  error?: boolean;
  marginBottom?: number;
  required?: boolean;
}

export const Label = ({
  text,
  extraText,
  error = false,
  marginBottom = 8,
  required = true,
}: IProps) => (
  <Row data-testid="label" gap={4} style={{ marginTop: 0, marginBottom }}>
    <span className="input_names" style={{ width: 'fit-content' }}>
      {text}
    </span>
    {extraText && (
      <span
        style={{
          color: Colors.neutral_500,
          fontWeight: 400,
          fontSize: 16,
        }}
      >
        {` ${extraText}`}
      </span>
    )}
    {required && (
      <span
        style={{
          color: Colors.neutral_500,
          fontWeight: 400,
          fontSize: 16,
        }}
      >
        {' (Required)'}
        <span
          style={{
            color: Colors.error_500,
          }}
        >
          *
        </span>
      </span>
    )}

    {error && <Icon name="warning-solid" color={Colors.warning_500} />}
  </Row>
);
