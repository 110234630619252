import { ConfirmationSelectIncident } from './ConfirmationSelectIncident';
import { ConfirmationIncident } from './ConfirmationIncident';
import { ConfirmationToRepost } from './ConfirmationToRepost';
import { SuccessReport } from './SuccessReport';
import { SuccessRepost } from './SuccessRepost';
import { popupIncidentType } from '../utils/incidentPopupUtil';

export const popupStateMetadata = {
  [popupIncidentType.CONFIRMATION_SELECT_INCIDENT]: ConfirmationSelectIncident,
  [popupIncidentType.CONFIRMATION_INCIDENT]: ConfirmationIncident,
  [popupIncidentType.CONFIRMATION_TO_REPOST]: ConfirmationToRepost,
  [popupIncidentType.SUCCESS_REPORT]: SuccessReport,
  [popupIncidentType.SUCCESS_REPOST]: SuccessRepost,
};
