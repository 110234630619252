import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Portal from './Portal';

import AnimatedCheckCircle from '../components/shared/AnimatedCheckCircle';
import ConfirmPopup from '../components/shared/ConfirmPopup';
import history from '../history';
import '../themes/alertmessage.scss';

export default function CreateJobPortal({ route, params, submitPressed }) {
  return (
    <Portal>
      <ConfirmPopup
        Icon={() => <AnimatedCheckCircle />}
        title="Request Successfully Submitted!"
        titleStyle={{ marginTop: 20 }}
        description="Your shift is now available for professionals nearby. Check back for new candidates."
        descriptionStyle={{ width: '90%' }}
        rightButtonText="Done"
        rightButtonAction={() => {
          submitPressed();
          history.push(route, params);
        }}
      />
    </Portal>
  );
}
