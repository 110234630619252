import { Column, Row, Text } from '../../commonComponents';
import StarIcon from '../../images/icons/StarIcon';
import { Colors } from '../../themes/colors';

export const RatingItem = ({ description, rating, title }) => {
  const totalRatingStar = Math.floor(rating);

  return (
    <Column key={title}>
      <Text bold textAlign="center">
        {title}
      </Text>
      <Text
        color={Colors.neutral_500}
        textAlign="center"
        style={{
          fontStyle: 'italic',
        }}
      >
        {description}
      </Text>
      <Row justifyContent="center" alignItems="center" gap={12} marginTop={8}>
        <Row justifyContent="center" alignItems="center" gap={8}>
          {Array.from({ length: 5 }, (_, i) => i + 1).map((order) => (
            <StarIcon
              key={order}
              color={order > totalRatingStar ? Colors.neutral_100 : Colors.success_400}
              height={18}
              width={18}
            />
          ))}
        </Row>
        <Text>{rating?.toFixed(1)}</Text>
      </Row>
    </Column>
  );
};
