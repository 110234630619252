import '../../themes/global.scss';
import '../../themes/job_summary.scss';
import '../../themes/payment.scss';

import { useSelector } from 'react-redux';
import { Icon, Row, Text } from '../../commonComponents';
import CustomSpinner from '../../commonComponents/CustomSpinner';
import OfficeAvatar1 from '../../images/avatars/Office-Avatar-1.svg';
import { Colors } from '../../themes/colors';
import { OfficeRating } from '../OfficeRating/OfficeRating';

export default function ProfileHeaderCard() {
  const user = useSelector((state) => state.user.selectedChildOffice || state.user.user);
  const masterOfficeId = useSelector((state) => state.user.user.id);
  const showAdminLabel = user.id === masterOfficeId;
  const profileImage = user?.profile_image;
  const headerIcons = [
    { iconName: 'at', value: user.email_id },
    { iconName: 'phone', value: user.contact_number },
  ];

  const rendeAdminLabel = () => {
    if (showAdminLabel) {
      return (
        <div
          style={{
            backgroundColor: Colors.secondary_500,
            borderRadius: 3,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: -10,
            zIndex: 4,
            alignSelf: 'center',
            position: 'initial',
            width: 55,
            height: 20,
          }}
        >
          <div
            style={{
              color: Colors.white,
              fontFamily: 'Nunito',
              fontSize: 12,
              fontWeight: 900,
            }}
          >
            ADMIN
          </div>
        </div>
      );
    }
    return <></>;
  };

  const renderProfileIcon = () => (
    <div
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column-reverse',
        display: 'flex',
      }}
    >
      {rendeAdminLabel()}
      <img
        alt="profile"
        src={profileImage ?? OfficeAvatar1}
        style={{
          width: 120,
          height: 120,
          borderRadius: '100%',
          resizeMode: 'contain',
        }}
      />
    </div>
  );

  const headerItemListDNT = () => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: 4,
      }}
    >
      <Row gap={12} alignItems="center">
        <strong
          style={{
            fontSize: 28,
            color: Colors.neutral_600,
          }}
        >
          {`${user.office_name || ''}`}
        </strong>
        <OfficeRating />
      </Row>

      <span
        style={{
          fontFamily: 'Nunito',
          fontSize: 16,
          width: '100%',
          color: Colors.neutral_600,
        }}
      >
        {user.address && user.address.length !== 0 ? user.address[0]?.formatted_address : 'N/A'}
      </span>

      <Row gap={4}>
        {headerIcons.map((item, index) => (
          <>
            <Row alignItems="center">
              <Icon name={item.iconName} />
              <Text bold fontSize={18} marginLeft={2} marginRight={8}>
                :
              </Text>
              <Text>{item.value}</Text>
            </Row>
            {index === 0 && (
              <Text bold fontSize={18} marginLeft={8} marginRight={8}>
                /
              </Text>
            )}
          </>
        ))}
      </Row>
    </div>
  );

  if (!user) {
    return <CustomSpinner isLoading />;
  }

  return (
    <div
      style={{
        height: 130,
        marginBottom: 20,
      }}
    >
      <div
        className="card-body"
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <div style={{ margin: 20 }}>{renderProfileIcon()}</div>
        {headerItemListDNT()}
      </div>
    </div>
  );
}
