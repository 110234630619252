import { motion } from 'framer-motion';
import moment from 'moment';
import { useState } from 'react';
import { NUMBER_OF_DAYS_TO_ADD } from '../../../../../../commonComponents/CalendarPopup';
import history from '../../../../../../history';
import PlusIconSVG from '../../../../../../images/plus.svg';
import { Colors } from '../../../../../../themes/colors';
import ConfirmPopup from '../../../../../shared/ConfirmPopup';
import { getCalendarJobStatus, onCardClick } from '../../../../utils';
import { JobListPopup } from '../../../JobList/JobListPopup';

const getDayColor = ({ disabled, isToday, isStatusFiltered, status }) => {
  let textColor = Colors.neutral_500;
  let backgroundColor = 'transparent';

  if (disabled) {
    textColor = Colors.neutral_200;
  } else if (isToday) {
    textColor = Colors.white;
    backgroundColor = Colors.primary_500;
  } else if (isStatusFiltered) {
    textColor = Colors.white;
    backgroundColor = getCalendarJobStatus({ status }).color;
  }

  return { textColor, backgroundColor };
};

const renderCards = (jobs) => (
  <div
    style={{
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginLeft: 12,
      marginRight: 12,
      marginTop: 44,
    }}
  >
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
      }}
    >
      {jobs?.slice(0, 2).map((job) => (
        <motion.div
          key={job.id}
          initial={{ opacity: 0, x: -10 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: 8,
          }}
        >
          <div
            style={{
              minWidth: 4,
              maxWidth: 4,
              height: '100%',
              backgroundColor: getCalendarJobStatus(job).color,
              borderRadius: 8,
            }}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
            }}
          >
            <span
              style={{
                fontFamily: 'Nunito',
                fontSize: 15,
                color: Colors.neutral_600,
                fontWeight: '700',
                lineHeight: 1,
                whiteSpace: 'nowrap',
              }}
            >
              {`${job.offerStarts.replace(' ', '').toLowerCase()} / ${
                job.subProfession ?? job.profession
              }`}
            </span>
            <span
              style={{
                fontFamily: 'Nunito',
                fontSize: 16,
                color: Colors.neutral_600,
                lineHeight: 1,
                whiteSpace: 'nowrap',
              }}
            >
              {job.professional[0]?.full_name}
            </span>
          </div>
        </motion.div>
      ))}
    </div>

    {jobs?.length > 2 && (
      <div
        style={{
          marginBottom: 8,
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <span
          style={{
            fontFamily: 'Nunito',
            fontSize: 16,
            fontWeight: '700',
            color: Colors.secondary_500,
          }}
        >
          +{jobs?.length - 2} more
        </span>
      </div>
    )}
  </div>
);

const renderAddJobButton = () => (
  <div
    style={{
      display: 'flex',
      height: '100%',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      pointerEvents: 'none',
    }}
  >
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 40,
        height: 40,
        borderRadius: '50%',
        backgroundColor: Colors.neutral_100,
        boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.4)',
      }}
    >
      <img alt="Add job" src={PlusIconSVG} />
    </div>
  </div>
);

export const DayCard = ({ jobs, status, dateString, disabled = false }) => {
  const [isHovering, setIsHovering] = useState(false);
  const [openJobListPopup, setOpenJobListPopup] = useState(false);
  const [openAddJobPopup, setOpenAddJobPopup] = useState(false);
  const dateObj = moment(dateString);
  const is99DaysBefore = moment().add(NUMBER_OF_DAYS_TO_ADD, 'days').isAfter(dateObj);
  const isToday = dateObj.isSame(moment(), 'day');
  const isTodayOrAfter = dateObj.isSameOrAfter(moment(), 'day');
  const isStatusFiltered = jobs?.length && status !== 'all' && status !== 'today';
  const dayColor = getDayColor({
    isToday,
    isStatusFiltered,
    status,
    disabled,
  });
  const enableAddJobButton =
    !disabled &&
    !jobs?.length &&
    isHovering &&
    status === 'all' &&
    isTodayOrAfter &&
    is99DaysBefore;

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  const handleJobListPopup = (open) => {
    setOpenJobListPopup(open);
  };

  const handleCardClick = () => {
    if (jobs?.length === 1) {
      return onCardClick(jobs[0]);
    }

    if (jobs?.length > 1) {
      return handleJobListPopup(true);
    }

    if (enableAddJobButton) {
      return setOpenAddJobPopup(true);
    }

    return null;
  };

  return (
    <>
      <button
        type="button"
        style={{
          all: 'unset',
          minWidth: '100%',
          maxWidth: '100%',
          cursor: disabled ? 'default' : 'pointer',
        }}
        onFocus={handleMouseOver}
        onBlur={handleMouseOut}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        disabled={disabled}
        onClick={handleCardClick}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: 148,
            borderRadius: 12,
            backgroundColor: Colors.neutral_50,
            ...(isHovering &&
              !disabled && {
                boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.4)',
              }),
          }}
        >
          <div
            style={{
              position: 'absolute',
              display: 'flex',
              paddingTop: 8,
              paddingRight: 8,
              justifyContent: 'flex-end',
              marginBottom: 8,
              width: 'fit-content',
              alignSelf: 'flex-end',
            }}
          >
            <div
              style={{
                backgroundColor: dayColor.backgroundColor,
                width: 28,
                height: 28,
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <span
                style={{
                  color: dayColor.textColor,
                  fontFamily: 'Nunito',
                  fontSize: 16,
                }}
              >
                {dateObj.format('D')}
              </span>
            </div>
          </div>
          {!disabled && jobs?.length > 0 && renderCards(jobs)}
          {enableAddJobButton && renderAddJobButton()}
        </div>
      </button>
      {openJobListPopup && (
        <JobListPopup onClose={() => handleJobListPopup(false)} jobList={jobs} />
      )}

      {openAddJobPopup && (
        <ConfirmPopup
          title="Request A Temp"
          titleStyle={{ marginTop: 20 }}
          description={
            <span>
              Would you like to request a Dental Professional for
              <br />
              <span style={{ fontWeight: 700 }}>{dateObj.format('ddd, MMM D, YYYY')}?</span>
            </span>
          }
          leftButtonText="Go Back"
          closePopup={() => setOpenAddJobPopup(false)}
          leftButtonAction={() => setOpenAddJobPopup(false)}
          rightButtonText="Continue"
          rightButtonAction={() => {
            history.push('/dashboard/multi-day/info', { selectedDate: dateString });
          }}
        />
      )}
    </>
  );
};
