import { AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchJob, inviteFavoriteToJob } from '../../actions';
import { clearSuccess } from '../../actions/success';
import { createSuccessSelector } from '../../api/selectors';
import AnimatedCheckCircle from '../../components/shared/AnimatedCheckCircle';
import ConfirmPopup from '../../components/shared/ConfirmPopup';
import useLoading from '../../hooks/useLoading';
import { HeaderComponent } from './HeaderComponent';
import { ProfessionalRow } from './ProfessionalRow';
import { SendToModal } from './SendToModal';

const successActions = ['INVITE_FAVORITE_TO_JOB'];
const successSelector = createSuccessSelector(successActions);
const loadingActions = [
  'FETCH_RECENT_PROFESSIONALS',
  'FETCH_FAVORITE_PROFESSIONALS',
  'FETCH_HIGHLY_RATED_PROFESSIONALS',
  'INVITE_FAVORITE_TO_JOB',
  'FETCH_JOB_ACTION',
];

export const SendInviteToModal = ({
  fetchProfessionalsAction,
  professionalsSelector,
  filterFunction,
  EmptyComponent,
  headerTitle,
  headerSubtitle,
  onClose,
  mapProfessionalToRowProps,
  listener,
  refetchJob = true,
}) => {
  const dispatch = useDispatch();
  const { isLoading } = useLoading(loadingActions);
  const invitedSuccessfully = useSelector((state) => successSelector(state));
  const [selectedProfessionals, setSelectedProfessionals] = useState([]);
  const job = useSelector((state) => state.job.job);
  const professionalsList = useSelector(professionalsSelector);
  const [searchFilter, setSearchFilter] = useState('');
  const allProfessions = useSelector((state) => state.user.allProfessions);
  const filteredProfessionals = filterFunction(professionalsList, searchFilter);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleOnSelect = (item) => {
    if (item?.inviteSent) return;

    if (selectedProfessionals.includes(item._id)) {
      setSelectedProfessionals(selectedProfessionals.filter((userId) => userId !== item?._id));
    } else {
      setSelectedProfessionals([...selectedProfessionals, item?._id]);
    }
  };

  useEffect(() => {
    if (refetchJob) {
      dispatch(fetchJob({ jobId: job?.id }));
    }
    dispatch(fetchProfessionalsAction(job?.id));
    setSelectedProfessionals([]);
  }, [dispatch, job?.id, fetchProfessionalsAction, refetchJob]);

  useEffect(() => {
    if (invitedSuccessfully) {
      setSelectedProfessionals([]);
      setShowSuccessModal(true);
      dispatch(clearSuccess(successActions));
    }
  }, [invitedSuccessfully, dispatch]);

  return (
    <>
      <SendToModal
        isOpen={!showSuccessModal}
        onClose={onClose}
        isLoading={isLoading}
        isEmpty={!isLoading && !professionalsList?.length}
        EmptyComponent={!isLoading ? EmptyComponent : null}
        HeaderComponent={() => <HeaderComponent title={headerTitle} subtitle={headerSubtitle} />}
        onSearchChange={setSearchFilter}
        isDisabled={isLoading || selectedProfessionals.length === 0}
        onSend={() => {
          listener?.(job, selectedProfessionals);
          dispatch(inviteFavoriteToJob(job?.id, selectedProfessionals));
        }}
      >
        <AnimatePresence>
          {filteredProfessionals?.map((item) => {
            const mappedItem = { ...mapProfessionalToRowProps(item, job, allProfessions) };
            return (
              <ProfessionalRow
                key={item._id}
                item={mappedItem}
                selected={selectedProfessionals.includes(mappedItem._id)}
                onSelect={handleOnSelect}
              />
            );
          })}
        </AnimatePresence>
      </SendToModal>

      {showSuccessModal && (
        <ConfirmPopup
          Icon={() => (
            <div style={{ marginBottom: 20 }}>
              <AnimatedCheckCircle />
            </div>
          )}
          title="Invite(s) Successfully Sent!"
          description="This shift request has been successfully sent to your selected professionals."
          rightButtonAction={onClose}
          rightButtonText="Close"
        />
      )}
    </>
  );
};
