import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { cancelConfirmReportIncidentPopup, showRepostNcnsPopup } from '~/actions';
import { Text } from '~/commonComponents';
import { ConfirmPopupContent } from '~/components/shared/ConfirmPopupContent';
import { getShouldEnableNCNSRepost } from '~/growthbook';
import WarningIcon from '~/images/warning-icon.svg';
import { incidentTypes } from '../utils/incidentPopupUtil';

export const ConfirmationIncident = ({ incidentType, data, onSubmit }) => {
  const dispatch = useDispatch();

  const handleSubmit = useCallback(() => {
    if (getShouldEnableNCNSRepost() && incidentType === incidentTypes.noShow && data?.canRepost) {
      dispatch(showRepostNcnsPopup());
      return;
    }

    onSubmit();
  }, [data?.canRepost, dispatch, incidentType, onSubmit]);

  return (
    <ConfirmPopupContent
      Icon={() => <img src={WarningIcon} style={{ marginBottom: 20 }} alt="warning_icon" />}
      title={incidentType === incidentTypes.noShow ? 'Report a No-Show' : 'Report a Walked Out'}
      description={
        <Text textAlign="center" marginBottom={-40}>
          Are you sure you would like to report <strong>{data?.professionalName}</strong> as a{' '}
          {incidentType}?
        </Text>
      }
      leftButtonAction={() => {
        dispatch(cancelConfirmReportIncidentPopup());
      }}
      leftButtonText="Cancel"
      rightButtonAction={handleSubmit}
      rightButtonText="Continue"
    />
  );
};
