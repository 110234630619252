import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import InputSelect from '../../../../commonComponents/InputSelect';
import Tooltip from '../../../Tooltip';
import UserIcon from '../../../../images/user-icon.svg';
import { useJobCandidates } from '../../hooks/useJobCandidates';
import { Row, Text } from '../../../../commonComponents';
import { Colors } from '../../../../themes/colors';
import { trackEvent } from '../../../../api/analytics';
import { candidateSortEventMapping, candidateSortOptions } from '../../utils';

export const Filters = () => {
  const { onFilterChange, filters } = useJobCandidates();
  const tooltipRef = useRef(null);

  const { job } = useSelector((state) => state.job);

  const [isTooltipHovered, setIsTooltipHovered] = useState(false);

  const handleMouseEnter = useCallback(() => {
    if (!isTooltipHovered) {
      trackEvent('Confirm Candidate - Sort By Tooltip Hovered/Clicked', {
        hours: job?.totalHours,
        shiftId: job?.id,
        shiftDate: job?.job_datetime,
        shiftSpecialty: job?.specialty,
        profession: job?.profession,
        procedures: job?.procedures,
        arrivalTime: job?.local_start_time,
        departureTime: job?.local_end_time,
        breakTime: job?.lunch_break_time,
        autoConfirm: job?.autoFill,
        autoFillFavorite: job?.autofillOptions?.favorite,
        autoFillHighlyRated: job?.autofillOptions?.highlyRated,
        softwares: job?.softwares,
        assistedHygiene: job?.isAssistedHygiene,
        rate: job?.rate,
        tpp: job?.amount,
        candidateCount: job?.multipleCounters?.length,
      });
      setIsTooltipHovered(true);
    }
  }, [isTooltipHovered]);

  const handleMouseLeave = useCallback(() => {
    setIsTooltipHovered(false);
  }, []);

  useEffect(() => {
    if (tooltipRef.current) {
      const element = tooltipRef.current;

      element.addEventListener('mouseenter', handleMouseEnter);
      element.addEventListener('mouseleave', handleMouseLeave);

      return () => {
        element.removeEventListener('mouseenter', handleMouseEnter);
        element.removeEventListener('mouseleave', handleMouseLeave);
      };
    }

    return undefined;
  }, [tooltipRef, isTooltipHovered, handleMouseEnter, handleMouseLeave]);

  return (
    <Row alignItems="center">
      <img alt="icon" src={UserIcon} style={{ width: 24, height: 24, marginRight: 4 }} />

      <Text fontSize={20} bold>
        Sort By:
      </Text>

      <div style={{ width: 210, marginLeft: 12, marginRight: 4 }}>
        <InputSelect
          value={filters?.sort}
          setValue={(option) => {
            const eventName = candidateSortEventMapping[option];

            if (eventName) {
              trackEvent(eventName, {
                hours: job?.totalHours,
                shiftId: job?.id,
                shiftDate: job?.job_datetime,
                shiftSpecialty: job?.specialty,
                profession: job?.profession,
                procedures: job?.procedures,
                arrivalTime: job?.local_start_time,
                departureTime: job?.local_end_time,
                breakTime: job?.lunch_break_time,
                autoConfirm: job?.autoFill,
                autoFillFavorite: job?.autofillOptions?.favorite,
                autoFillHighlyRated: job?.autofillOptions?.highlyRated,
                softwares: job?.softwares,
                assistedHygiene: job?.isAssistedHygiene,
                rate: job?.rate,
                tpp: job?.amount,
                candidateCount: job?.multipleCounters?.length,
              });
            }

            onFilterChange({ sort: option });
          }}
          isSelectVisible={false}
          options={[
            { title: 'Local Favorites', value: candidateSortOptions.localFavorites },
            { title: 'Skill Match', value: candidateSortOptions.skillsMatch },
            { title: 'Previously Rated', value: candidateSortOptions.previouslyRated },
          ]}
        />
      </div>

      <Tooltip
        ref={tooltipRef}
        content={
          <Text color={Colors.white}>
            <Text color={Colors.white} bold>
              Local Favorites
            </Text>
            : Professionals who are often liked by other offices.
            <br />
            <br />
            <Text color={Colors.white} bold>
              Skill Match
            </Text>
            : Professionals whose skills and procedures most closely match your needs.
            <br />
            <br />
            <Text color={Colors.white} bold>
              Previously Rated
            </Text>
            : Professionals that have most likely worked with your office before.
          </Text>
        }
      />
    </Row>
  );
};
