import React, { Component } from 'react';

export default class HowItWorks extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (sessionStorage.getItem('UserDetail') !== null) {
      if (sessionStorage.getItem('UserDetail').length > 0) {
        if (JSON.parse(sessionStorage.getItem('loggedIn')) !== true) {
          this.props.history.push('/');
        }

        this.setState({
          userDetails: JSON.parse(sessionStorage.getItem('UserDetail')),
          elementHeight: this.divRef.clientHeight,
        });
      }
    } else {
      this.props.history.push('/');
    }
  }

  render() {
    return (
      <div className="component_container" ref={(element) => (this.divRef = element)}>
        <div className="floating_container" style={{ height: this.state.elementHeight }}>
          <div style={{ margin: '15px 50px' }}>
            <h1 className="global_font f-dark">How It Works</h1>
            <p className="global_font f-dark" style={{ lineHeight: '2em' }}>
              GoTu’s dental professional are licensed, verified, and are available when YOU need
              them. You set their schedule and the hourly rate they earn and we’ll handle the rest.
              After you approve the successful completion of the day, the funds will automatically
              be transferred to the dental professional account. It’s just that easy. After each
              shift, you will get the opportunity to rate your dental professional and select if you
              would like to hire them again. The dental professional will do the same.
            </p>
            <p className="global_font f-dark" style={{ lineHeight: '2em' }}>
              GoTu is designed to simplify and streamline the slow and frustrating process of
              finding a temporary dental professional. It is quick, easy and cost effective. No need
              to use the time consuming sub lists, unreliable message boards, or overpriced temp
              services. With top rated licensed dental professional on-demand your office will never
              miss a beat.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
