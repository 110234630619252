import { motion } from 'framer-motion';
import { ReactNode } from 'react';
import { EmptyScreen } from '../../../../commonComponents/EmptyScreen/EmptyScreen';
import { CalendarTodayFilterIcon } from '../../../../images/emptyScreenIcons';

interface IProps {
  description?: string | ReactNode;
  title?: string;
  width?: number | string;
  height?: number | string;
}

export const EmptyRecentProfessionals = ({
  description,
  height = 600,
  title,
  width = 540,
}: IProps) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.5 }}
    style={{
      maxWidth: '827px',
      height,
      display: 'flex',
      flexDirection: 'column',
      gap: 32,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: -20,
    }}
  >
    <EmptyScreen
      Icon={CalendarTodayFilterIcon}
      title={title || 'No recent professionals yet!'}
      width={width}
      description={
        description || (
          <>
            It looks like you haven&apos;t completed any shifts yet! Once a professional completes a
            shift, they will appear on this screen where you will be able to:
            <ul
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 24,
                marginTop: 20,
                marginLeft: -20,
              }}
            >
              <li>Add them to your favorites.</li>
              <li>Block the professional.</li>
              <li>Send direct invites to upcoming shifts.</li>
            </ul>
          </>
        )
      }
      descriptionStyle={{
        textAlign: 'left',
      }}
      buttonText={undefined}
      onButtonClick={undefined}
    />
  </motion.div>
);
