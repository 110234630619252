import * as actions from '~/actions';
import { apiMethodsConst } from '~/services/methods';
import { IPaymentReducerState, TGetAllPaymentsRequestAction, TPaymentAction } from './interfaces';

export const initialState: IPaymentReducerState = {
  allPayments: [],
  plaidToken: '',
  isShowPaymentMissingPopup: false,
  showSuccessBankAddedPopup: false,
  showSuccessCardAddedPopup: false,
};

export default (
  state: IPaymentReducerState = initialState,
  action: TPaymentAction,
): IPaymentReducerState => {
  switch (action.type) {
    case 'SELECT_OFFICE_REQUEST':
      return initialState;
    case apiMethodsConst.GET_ALL_PAYMENTS_REQUEST: {
      const typedAction = action as TGetAllPaymentsRequestAction;

      return {
        ...state,
        allPayments: typedAction.payload!,
      };
    }
    case apiMethodsConst.DELETE_PAYMENT_REQUEST: {
      const index = state.allPayments.findIndex((paymnet) => paymnet.id === action.payload);
      if (index === -1) {
        return state;
      }

      return {
        ...state,
        allPayments: [...state.allPayments.slice(0, index), ...state.allPayments.slice(index + 1)],
      };
    }
    case apiMethodsConst.SELECT_PAYMENT_REQUEST: {
      const indexSelected = state.allPayments.findIndex(
        (paymnet) => paymnet.id === (action.payload?.id ?? action.payload),
      );

      if (indexSelected === -1) {
        return state;
      }

      return {
        ...state,
        allPayments: state.allPayments.map((el, index) => ({
          ...el,
          is_selected: index === indexSelected,
        })),
      };
    }
    case apiMethodsConst.VERIFY_BANK_REQUEST: {
      const indexVerify = state.allPayments.findIndex(
        (paymnet) => paymnet.id === action.payload!.paymentId,
      );
      if (indexVerify === -1) {
        return state;
      }

      return {
        ...state,
        allPayments: state.allPayments.map((pm, index) => {
          if (index === indexVerify) {
            return { ...pm, status: 'verified' };
          }
          return pm;
        }),
      };
    }
    case apiMethodsConst.ADD_PAYMENT_REQUEST:
      return {
        ...state,
        allPayments: action.payload ? [...state.allPayments, action.payload] : state.allPayments,
        showSuccessCardAddedPopup: true,
      };
    case apiMethodsConst.SUBMIT_PLAID_TOKEN:
      return {
        ...state,
        allPayments: action.payload ? [...state.allPayments, action.payload] : state.allPayments,
        showSuccessBankAddedPopup: true,
      };
    case 'GET_PLAID_TOKEN_REQUEST':
      return {
        ...state,
        plaidToken: '',
      };
    case apiMethodsConst.GET_PLAID_TOKEN:
      return {
        ...state,
        plaidToken: action.payload!.token,
      };
    case actions.SHOW_PAYMENT_MISSING_POPUP:
      return {
        ...state,
        isShowPaymentMissingPopup: true,
      };
    case actions.HIDE_PAYMENT_MISSING_POPUP:
      return {
        ...state,
        isShowPaymentMissingPopup: false,
      };
    case actions.HIDE_SUCCESS_BANK_ADDED_POPUP:
      return {
        ...state,
        showSuccessBankAddedPopup: false,
        showSuccessCardAddedPopup: false,
      };

    default:
      return state;
  }
};
