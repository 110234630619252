import '../themes/footer.scss';

import { useState } from 'react';

import { Links } from '../constants';
import { FacebookIcon, InstagramIcon, LinkedinIcon } from '../images/footerIcons';
import { Colors } from '../themes/colors';

const ICONS = {
  facebook: 'facebook',
  linkedin: 'linkedin',
  instagram: 'instagram',
};

export default function Footer() {
  const [isHovering, setIsHovering] = useState(false);
  const [hoverIcon, setHoverIcon] = useState('');

  const handleMouseOver = (type) => {
    setIsHovering(true);
    setHoverIcon(type);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
    setHoverIcon('');
  };

  return (
    <div className="background">
      <div className="link_content_container">
        <div className="link_container">
          <a href={Links.temporaryStaffing} target="_blank" rel="noopener noreferrer">
            Dental Offices
          </a>
          <a href={Links.home} target="_blank" rel="noopener noreferrer">
            How it works
          </a>
          <a
            href={Links.dentistsSideFaqs}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textTransform: 'none' }}
          >
            FAQs
          </a>
        </div>
        <div className="link_container">
          <a href={Links.about} target="_blank" rel="noopener noreferrer">
            About
          </a>
          <a href={Links.termsOfUse} target="_blank" rel="noopener noreferrer">
            Terms of Service
          </a>
          <a href={Links.privacyPolicy} target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
        </div>
        <div style={{ flex: 1 }} />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'column',
          }}
        >
          <div className="icon_box">
            <a
              href="https://www.linkedin.com/company/tempmee"
              target="_blank"
              rel="noopener noreferrer"
              onFocus={() => handleMouseOver(ICONS.linkedin)}
              onBlur={() => handleMouseOut()}
              onMouseOver={() => handleMouseOver(ICONS.linkedin)}
              onMouseOut={() => handleMouseOut()}
            >
              <LinkedinIcon
                fill={
                  isHovering && hoverIcon === ICONS.linkedin ? Colors.success_400 : Colors.white
                }
              />
            </a>

            <a
              href="https://www.facebook.com/tempmeeapp"
              target="_blank"
              rel="noopener noreferrer"
              onFocus={() => handleMouseOver(ICONS.facebook)}
              onBlur={() => handleMouseOut()}
              onMouseOver={() => handleMouseOver(ICONS.facebook)}
              onMouseOut={() => handleMouseOut()}
            >
              <FacebookIcon
                fill={
                  isHovering && hoverIcon === ICONS.facebook ? Colors.success_400 : Colors.white
                }
              />
            </a>
            <a
              href="https://www.instagram.com/tempmee/"
              target="_blank"
              rel="noopener noreferrer"
              onFocus={() => handleMouseOver(ICONS.instagram)}
              onBlur={() => handleMouseOut()}
              onMouseOver={() => handleMouseOver(ICONS.instagram)}
              onMouseOut={() => handleMouseOut()}
            >
              <InstagramIcon
                fill={
                  isHovering && hoverIcon === ICONS.instagram ? Colors.success_400 : Colors.white
                }
              />
            </a>
          </div>
          <div style={{ marginBottom: 10 }}>
            <p className="temp-mee-inc">
              ©{new Date().getFullYear()} GoTu, Inc. - All Right Reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
