import { IErrorState, TErrorAction } from './interface';

const initialState: IErrorState = {};

export default (state: IErrorState = initialState, action: TErrorAction): IErrorState => {
  const { payload, type } = action;

  const isHideErrorAction = type === 'HIDE_ERROR_ACTION';

  if (isHideErrorAction) {
    return {
      ...state,
      [payload]: '',
    };
  }

  const matches = /(.*)_(FAILURE)/.exec(type);
  if (!matches) return { ...state };

  const [, requestName, requestState] = matches;
  const responseMessage = payload?.message || '';
  const message = requestState === 'FAILURE' ? responseMessage : '';
  return {
    ...state,
    [requestName]: {
      message,
    },
  };
};
