import { combineReducers } from 'redux';
import alertsReducer from './alertsReducer';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import feedbackReducer from './feedbackReducer';
import jobReducer from './jobReducer';
import loadingReducer from './loadingReducer';
import messageReducer from './messageReducer';
import messagesReducer from './messagesReducer';
import multidayReducer from './multidayReducer';
import officeReviewReducer from './officeReviewReducer';
import paymentReducer from './paymentReducer';
import ppReducer from './ppReducer';
import successReducer from './successReducer';
import userCredentialsReducer from './userCredentialsReducer';
import userReducer from './userReducer';

const appReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  userCredentials: userCredentialsReducer,
  error: errorReducer,
  success: successReducer,
  loading: loadingReducer,
  feedback: feedbackReducer,
  payments: paymentReducer,
  job: jobReducer,
  message: messageReducer,
  alerts: alertsReducer,
  multiday: multidayReducer,
  messages: messagesReducer,
  pp: ppReducer,
  officeRating: officeReviewReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_ACTION_SUCCESS') {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
