import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchFavoriteProfessionals,
  fetchHighlyRatedProfessionals,
  fetchRecentProfessionals,
  getUserEducation,
  getUserOvertimeLimits,
  selectDaysMDB,
} from '../../../../actions';
import { UserProfession, UserSubProfession } from '../../../../enums/UserProfession';
import { getShouldOfficeShowEfda } from '../../../../growthbook';
import history from '../../../../history';
import { useUserSelector } from '../../../../hooks/useUserSelector';
import { getAllProfessionTypeOptions } from '../../../../utils/DentistUtils';
import { EnumHelper } from '../../../../utils/EnumHelper';

export const useShiftDetailsStep = (job) => {
  const dispatch = useDispatch();
  const selectedDate = history.location?.state?.selectedDate;
  const professional = history?.location?.state?.professional || { id: '', name: '' };

  const user = useUserSelector();
  const jobInfo = useSelector((state) => state.multiday.jobInfo);
  const officeCredentials = useSelector((state) => state.userCredentials.credentials);

  const initialValues = {
    offerStartTime: job?.localStart || null,
    offerEndTime: job?.localEnd || null,
    rate: job?.rate || 0,
    lunch: job?.break_time === 0 ? '0 min' : job?.break_time || null,
    isLunchPaid: job?.is_break_paid || false,
    professionType:
      UserSubProfession.getName(job?.subProfession) ?? UserProfession.getName(job?.profession),
    skills: job?.specialty || 'specialty_General Dentistry',
    procedures: job?.procedures ?? [],
    enableAutoFill: job?.autoFill || false,
    enableAutoFillFavorites: job?.autoFillFavorites || false,
    enableAutoFillHighlyRated: job?.autoFillHighlyRated || false,
    isAssistedHygiene: job?.isAssistedHygiene || user.office_info.isAssistedHygiene,
    professional,
  };

  const [formValues, setFormValues] = useState(initialValues);
  const educationInfo = officeCredentials?.education;

  // TODO(lucky): use specialty from userCredentials
  const defaultSpecialty =
    Object.keys(educationInfo?.specialtiesAndProcedures || {})[0] || 'specialty_General Dentistry';

  const selectedProfessionCode = useSelector((state) => {
    const { allProfessions } = state.user;
    const showEfda = getShouldOfficeShowEfda();
    const allProfessionOptions = getAllProfessionTypeOptions(allProfessions, { showEfda });

    return allProfessionOptions?.filter((item) => item.name === formValues.professionType)?.[0]
      ?.code;
  });

  const favoriteProfessionals = useSelector((state) => state.user.favoriteProfessionals);
  const recentProfessionals = useSelector((state) => state.user.recentProfessionals);
  const { data: highlyRatedProfessionals } = useSelector(
    (state) => state.user.highlyRatedProfessionals,
  );

  const isDisabled =
    !formValues.offerStartTime ||
    !formValues.offerEndTime ||
    formValues.rate === 0 ||
    !formValues.lunch ||
    (selectedProfessionCode === UserProfession.RDH && formValues.isAssistedHygiene === undefined);

  const jobData = {
    localStart: formValues.offerStartTime,
    localEnd: formValues.offerEndTime,
    rate: formValues.rate,
    is_break_paid: formValues.isLunchPaid,
    break_time: formValues.lunch,
    profession: UserProfession.getNormalizedCode(selectedProfessionCode),
    subProfession: EnumHelper.getObjectKeys(UserSubProfession).includes(selectedProfessionCode)
      ? selectedProfessionCode
      : null,
    specialty: formValues.skills || 'specialty_General Dentistry',
    autoFill: formValues.enableAutoFill,
    autoFillFavorites: formValues.enableAutoFillFavorites,
    autoFillHighlyRated: formValues.enableAutoFillHighlyRated,
    procedures: formValues.procedures,
    ...(selectedProfessionCode === UserProfession.RDH && {
      isAssistedHygiene: formValues.isAssistedHygiene,
    }),
    professional: formValues.professional,
  };

  useEffect(() => {
    setFormValues((prevState) => ({
      ...prevState,
      skills: defaultSpecialty,
      procedures: [],
      enableAutoFill: false,
      enableAutoFillFavorites: false,
      enableAutoFillHighlyRated: false,
    }));

    const professionCode = UserProfession.getNormalizedCode(
      UserProfession.getCode(formValues.professionType),
    );

    dispatch(getUserEducation({ profession: professionCode }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues.professionType]);

  useEffect(() => {
    setFormValues((prevState) => ({ ...prevState, procedures: [] }));
  }, [formValues.skills]);

  /*
    Add date selected in Calendar screen or erase the date state
  */
  useEffect(() => {
    if (!jobInfo?.length) {
      dispatch(
        selectDaysMDB({ jobData: selectedDate ? { [selectedDate]: new Date(selectedDate) } : [] }),
      );
    }
  }, [jobInfo, selectedDate, dispatch]);

  useEffect(() => {
    if (!job) {
      if (!favoriteProfessionals.length) dispatch(fetchFavoriteProfessionals());
      if (!recentProfessionals.length) dispatch(fetchRecentProfessionals());
      if (!highlyRatedProfessionals.length)
        dispatch(fetchHighlyRatedProfessionals({ page: 1, limit: 20 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, job, user]);

  useEffect(() => {
    if (!user?.overtimeLimits) {
      dispatch(getUserOvertimeLimits());
    }
  }, [dispatch, user?.overtimeLimits]);

  return { formValues, setFormValues, isDisabled, jobData };
};
