import { UserProfession } from '../enums/UserProfession';
import { filterObjectKeys } from './Object';

/**
 *
 * @enum {string}
 */
export const SpecialtiesAndProceduresPrefix = {
  specialty: 'specialty',
  procedure: 'procedure',
};

export const EducationUtils = {
  /**
   * Get desired specialties and procedures based on user profession.
   * Hide empty entries when user is signing up.
   *
   * @param {UserProfession} userProfession - to decide whether user is 'DN', 'DA', or 'RDH'
   * @param {boolean} isSignUp - to decide whether user is signing up
   * @param {object} education - education
   * @returns {object}
   */
  getProfessionSpecialtiesAndProcedures({ userProfession, education }) {
    return [UserProfession.DA, UserProfession.DN].includes(userProfession)
      ? {
          'specialties/procedures': education,
        }
      : {
          specialties: filterObjectKeys(education, SpecialtiesAndProceduresPrefix.specialty),
          procedures: filterObjectKeys(education, SpecialtiesAndProceduresPrefix.procedure),
        };
  },
  /**
   *
   * @param skillUpdates
   * @returns {string[]}
   */
  getSpecialtiesAndProceduresSkills({ skillUpdates: updatedSkills }) {
    const skills = updatedSkills['specialties/procedures'] || {};

    return Object.keys(skills).map((item) => item.split('_')?.[1]);
  },

  /**
   * Remove 'procedure_' prefix from procedure, replacing `?.split('_')?.[1]` method
   *
   * @param {string | undefined} procedure
   *
   * @returns {string}
   */
  removeProcedurePrefix(procedure) {
    if (procedure) {
      return procedure.replace(/^procedure_/g, '');
    }

    return procedure;
  },

  /**
   * Remove 'specialty_' prefix from specialty, replacing `?.split('_')?.[1]` method
   *
   * @param {string | undefined} specialty
   *
   * @returns {string}
   */
  removeSpecialtyPrefix(specialty) {
    if (specialty) {
      return specialty.replace(/^specialty_/g, '');
    }

    return specialty;
  },

  /**
   * @param {string[]} procedures
   */
  mapToRemoveProcedurePrefix(procedures) {
    return procedures.map((procedure) => this.removeProcedurePrefix(procedure));
  },

  mapToRemoveSpecialtyPrefix(specialties) {
    return specialties.map((specialty) => this.removeSpecialtyPrefix(specialty));
  },

  /**
   * @param specialtiesAndProcedures
   * @param {UserProfession|string} profession
   * @param {string[]} specialties
   *
   * @returns {string[]}
   */
  getProceduresOfProfession({ specialtiesAndProcedures, profession, specialties }) {
    if ([UserProfession.FD, UserProfession.RDH].includes(profession)) {
      const procedures = filterObjectKeys(specialtiesAndProcedures, 'procedure');

      return Object.keys(procedures);
    }

    if ([UserProfession.DA, UserProfession.DN].includes(profession)) {
      return (specialties ?? []).map((specialty) => specialtiesAndProcedures?.[specialty]).flat();
    }

    return [];
  },

  /**
   * Get only endorsed procedures based on profession with its endorsements
   * is more than renounced procedures
   *
   * @param {object[]} endorsements
   * @param {string[]} procedures
   * @param {UserProfession | string} profession
   *
   * @returns {string[]}
   */
  getEndorsedProcedures({ endorsements, procedures, profession }) {
    // Filtering RDH and FD procedures
    if ([UserProfession.RDH, UserProfession.FD].includes(profession)) {
      // Getting valid renounced procedures based on endorsements
      // that has more renounced than endorsed and get name of the procedure
      const renouncedProcedures = endorsements
        .filter(({ renounced, endorsed }) => {
          // filter out procedures that never endorsed or renounced
          if (renounced === undefined || endorsed === undefined) {
            return false;
          }

          // yield renounced procedures
          return renounced > endorsed;
        })
        // get name of the procedure
        .map(({ name }) => name);

      // return original procedures if there are no renounced procedures
      if (renouncedProcedures.length === 0) {
        return procedures;
      }

      // return procedures that are endorsed
      return procedures.filter((procedure) => !renouncedProcedures.includes(procedure));
    }

    // Filtering DA and DN procedures
    //
    // DA and DN have procedures inside specialties, so we have to flatten to available procedures
    // from all specialties
    if ([UserProfession.DA, UserProfession.DN].includes(profession)) {
      // Getting valid renounced procedures based on endorsements
      // that has more renounced than endorsed and get name of the procedure
      const renouncedProcedures = endorsements
        // getting all procedures from all specialties
        .map(({ procedures }) => procedures)
        // because procedures are member of specialties, we have to flatten it
        // to join available procedures from all specialties
        .flat()
        .filter(({ renounced, endorsed }) => {
          // filter out procedures that never endorsed or renounced
          if (renounced === undefined || endorsed === undefined) {
            return false;
          }

          // yield renounced procedures
          return renounced > endorsed;
        })
        // get name of the procedure
        .map(({ procedureName }) => procedureName);

      // return original procedures if there are no renounced procedures
      if (renouncedProcedures.length === 0) {
        return procedures;
      }

      // return procedures that are endorsed
      return procedures.filter((procedure) => !renouncedProcedures.includes(procedure));
    }

    // return original procedures if profession is not valid
    return procedures;
  },

  getEducationProcedures(education = {}) {
    const result = {};

    try {
      Object.keys(education).forEach((key) => {
        if (education[key]?.specialtiesAndProcedures) {
          result[key] = education[key].specialtiesAndProcedures;
        }
      });
    } catch (error) {
      console.error('Error extracting procedures from education', error);
    }

    return result;
  },
};
