import { Box, Text } from '~/commonComponents';
import { TRootState } from '~/store/types';
import { Colors } from '~/themes/colors';
import { fetchRecentProfessionals } from '../../../actions';
import { trackEvent } from '../../../api/analytics';
import { EmptyRecentProfessionals } from '../../../components/ProfessionalsHub/components/RecentProfessionalsList/EmptyRecentProfessionals';
import { MAX_RECENT_PROFESSIONALS } from '../../../components/ProfessionalsHub/components/RecentProfessionalsList/RecentProfessionalsList';
import { SendInviteToModal } from '../SendInviteToModal';
import { filterRecentProfessionals, mapRecentToRowProps } from '../utils';

interface IProps {
  onClose: () => void;
}

export const SendToRecentProfessionalsModal = ({ onClose }: IProps) => (
  <SendInviteToModal
    fetchProfessionalsAction={fetchRecentProfessionals}
    professionalsSelector={(state: TRootState) => state.user.recentProfessionals}
    filterFunction={filterRecentProfessionals}
    mapProfessionalToRowProps={mapRecentToRowProps}
    EmptyComponent={() => (
      <Box width="100%">
        <EmptyRecentProfessionals
          title="No Recent Professionals"
          description={
            <Text display="block" color={Colors.neutral_500} textAlign="center" width="100%">
              It looks like you haven&apos;t completed any shifts yet! Once a professional completes
              a shift, they will appear on this screen where you will able to send direct invites to
              upcoming shifts.
            </Text>
          }
          width="100%"
          height="auto"
        />
      </Box>
    )}
    headerTitle="Recent Professionals"
    headerSubtitle={`
      Below is a list of the ${MAX_RECENT_PROFESSIONALS} 
      most recent professionals who have worked at your office.
    `}
    onClose={onClose}
    listener={(job: any, professionalsIds: string[]) => {
      trackEvent('Send to - Recent invite sent/submitted', {
        job: {
          professionalsIds,
          jobId: job.id,
          profession: job.profession,
          rate: job.rate,
          shiftId: job.shiftId,
          totalHours: job.totalHours,
          local_date: job.local_date,
          local_end_time: job.local_end_time,
          local_start_time: job.local_start_time,
          local_timezone: job.local_timezone,
          lunch_break_time: job.lunch_break_time,
          lunch_final_break_time: job.lunch_final_break_time,
          specialty: job.specialty,
        },
      });
    }}
  />
);
