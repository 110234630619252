import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';

import { Colors } from '../themes/colors';
import {
  FETCH_CANCELLATION_REASONS,
  SUBMIT_CANCELLATION_REASON,
  fetchCancellationReasons,
  submitCancellationReason,
} from '../actions';
import { createLoadingSelector } from '../api/selectors';
import CustomSpinner from '../commonComponents/CustomSpinner';
import Button from '../commonComponents/Button';
import AnimatedCheckCircle from './shared/AnimatedCheckCircle';
import checkedCircleIcon from '../images/checkedCircleIcon.svg';
import uncheckedCircleIcon from '../images/uncheckedCircleIcon.svg';

export default function CancellationFeedback({ match }) {
  const loadingSelector = createLoadingSelector([FETCH_CANCELLATION_REASONS]);
  const isLoading = useSelector((state) => loadingSelector(state));

  const submitSelector = createLoadingSelector([SUBMIT_CANCELLATION_REASON]);
  const isSubmitting = useSelector((state) => submitSelector(state));

  const dispatch = useDispatch();

  const [cancellationFeedback, setCancellationFeedback] = useState('');
  const [cancellationReason, setsCancellationReason] = useState('');

  const { jobId } = match.params;
  const feedbackReasons = useSelector((state) => state.job.cancelationReasons);
  const buttonValidation =
    cancellationFeedback === '' ||
    (cancellationFeedback === 'other' && cancellationReason?.length < 3);

  useEffect(() => {
    dispatch(fetchCancellationReasons());
  }, [dispatch]);

  const submitFeedback = () => {
    dispatch(
      submitCancellationReason({
        jobId,
        cancellationFeedback,
        cancellationReason,
      }),
    );
  };

  return (
    <div
      style={{
        flex: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        zIndex: 10,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <div
        style={{
          backgroundColor: Colors.white,
          alignContent: 'center',
          justifyContent: 'center',
          maxWidth: 520,
          minWidth: 450,
          width: '35%',
          borderRadius: 12,
          display: 'flex',
          flexDirection: 'column',
          marginTop: 30,
          marginBottom: 50,
          boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3)',
          shadowColor: Colors.neutral_400,
          shadowOpacity: 0.3,
          shadowOffset: {
            height: 3,
            width: 0,
          },
        }}
      >
        {isLoading ? (
          <CustomSpinner isLoading={isLoading} />
        ) : (
          <div
            style={{
              width: '75%',
              alignSelf: 'center',
              padding: '0px 70px',
              overflowY: 'auto',
              maxHeight: '95vh',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 42,
                marginBottom: 18,
              }}
            >
              <AnimatedCheckCircle />
            </div>

            <div
              style={{
                color: Colors.neutral_600,
                fontSize: 18,
                fontFamily: 'Nunito',
                fontWeight: 'bold',
                textAlign: 'center',
              }}
            >
              Cancellation Successful!
            </div>

            <div
              style={{
                color: Colors.neutral_500,
                fontSize: 18,
                fontFamily: 'Nunito',
                textAlign: 'center',
                fontWeight: 'bold',
                margin: '25px 20px',
              }}
            >
              Help us improve your experience. Let us know why you cancelled this shift. Please
              select one of these options.
            </div>

            {feedbackReasons.map((reason) => (
              <div
                key={reason.code}
                onClick={() => setCancellationFeedback(reason.code)}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: 18,
                  cursor: 'pointer',
                  width: '100%',
                }}
              >
                <img
                  src={
                    cancellationFeedback === reason.code ? checkedCircleIcon : uncheckedCircleIcon
                  }
                  style={{ marginRight: 10 }}
                />
                <div
                  style={{
                    color: Colors.neutral_600,
                    fontSize: 16,
                    fontFamily: 'Nunito',
                    cursor: 'pointer',
                  }}
                >
                  {reason.name}
                </div>
              </div>
            ))}

            <motion.div
              initial="hide"
              animate={cancellationFeedback === 'other' ? 'show' : 'hide'}
              variants={textAreaVariants}
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
              }}
            >
              <textarea
                className="textarea-style"
                placeholder="Provide details here..."
                style={{
                  marginTop: 20,
                  width: '80%',
                  height: 60,
                  fontSize: 16,
                }}
                value={cancellationReason}
                onChange={(e) => setsCancellationReason(e.target.value)}
              />
            </motion.div>

            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 30,
                marginBottom: 48,
              }}
            >
              <Button
                disabled={isSubmitting || buttonValidation}
                text="Submit"
                color="primary"
                style={{ width: 150 }}
                onClick={() => submitFeedback()}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const textAreaVariants = {
  show: {
    maxHeight: 130,
    opacity: 1,
    display: 'flex',
  },
  hide: {
    maxHeight: 0,
    opacity: 0,
    transitionEnd: { display: 'none' },
  },
};
