import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { adjustHours, fetchJob } from '../../actions';
import CardSpinner from '../../commonComponents/CardSpinner';
import MobileAppModal from '../../commonComponents/MobileAppModal';
import { Colors } from '../../themes/colors';

import calendar from '../../images/Calendar.png';
import endClock from '../../images/End_Clock.png';
import arrow from '../../images/Right_arrow.png';
import startClock from '../../images/Start_Clock.png';

import BackButton from '../../commonComponents/BackButton';
import Button from '../../commonComponents/Button';
import ShowStartTime from '../../commonComponents/showStartTime';
import { timeDifferenceCalculator } from '../../hooks/timeDifferenceCalculator';
import ConfirmAdjustmentPopup from './confirmAdjustmentPopup';

export default function AdjustHoursScreen({ history, match }) {
  const jobId = history.location.state ? history.location.state.jobId : match.params.jobId;

  const job = useSelector((state) => state.job.job);

  const [message, setMessage] = useState('');
  const [localStart, setLocalStart] = useState(
    moment(`${job?.local_date} ${job?.local_start_time}`, 'ddd, MMM DD, YYYY hh:mm a').format(
      'hh:mm a',
    ),
  );
  const [localEnd, setLocalEnd] = useState(
    moment(`${job?.local_date} ${job?.local_end_time}`, 'ddd, MMM DD, YYYY hh:mm a').format(
      'hh:mm a',
    ),
  );
  const [lunchBreak, setBreak] = useState(job?.lunch_final_break_time || '0.0');
  const [isBreakPaid, setBreakPaid] = useState(job?.is_lunch_break_paid || false);

  const [modalIsOpen, setModalOpen] = useState(false);
  const [showConfirmAdjustmentPopupModal, setShowConfirmAdjustmentPopupModal] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [showStartTime, setShowStartTime] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchJob({ jobId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (job && job?.id === jobId) {
      setLocalStart(
        moment(`${job?.local_date} ${job?.local_start_time}`, 'ddd, MMM DD, YYYY hh:mm a').format(
          'hh:mm a',
        ),
      );
      setLocalEnd(
        moment(`${job?.local_date} ${job?.local_end_time}`, 'ddd, MMM DD, YYYY hh:mm a').format(
          'hh:mm a',
        ),
      );
      setBreakPaid(job?.is_lunch_break_paid);
      setBreak(job?.lunch_final_break_time);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job]);

  const submitAdjustment = () => {
    const data = {
      break: lunchBreak,
      localDate: job.local_date,
      localStart,
      localEnd,
      message,
    };

    dispatch(adjustHours({ data, jobId }));
  };

  const renderJobField = (title, value, contentType, icon) => (
    <div style={{ marginTop: 25 }}>
      <div className="row_container" onClick={() => showContent(contentType)}>
        <div className="name_icon_container">
          <img alt="icon" src={icon} style={{ width: 16, height: 16 }} />
          <p className="date">{title}</p>
          <p className="date_value">{value}</p>
        </div>
        <div>
          <img alt="arrow" src={arrow} style={{ paddingRight: 25 }} />
        </div>
      </div>
      <div className="line" />
    </div>
  );

  const showContent = (type) => {
    setModalContent(type);
    if (type === 'break') {
      setModalOpen(true);
    } else {
      setShowStartTime(true);
    }
  };

  let btnColor;
  let btnDisabled;

  if (!localStart || !localEnd || !lunchBreak || message === '') {
    btnColor = 'secondary';
    btnDisabled = true;
  } else {
    btnColor = 'primary';
    btnDisabled = false;
  }
  if (!job) {
    return <CardSpinner />;
  }
  return (
    <>
      <div className="component_container">
        <div
          className="floating_container"
          style={{
            position: 'relative',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: 40,
          }}
        >
          <div style={{ width: '98%' }}>
            <BackButton />
          </div>

          <h1 className="global_font f-dark" style={{ alignSelf: 'center', marginTop: 0 }}>
            Adjustment Request
          </h1>
          <div style={{ marginTop: 25, alignSelf: 'center' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginLeft: 50,
              }}
            >
              <div className="name_icon_container">
                <img alt="icon" src={calendar} style={{ width: 16, height: 16 }} />
                <p className="date">Shift Date</p>
                <p className="date_value"> {job?.local_date}</p>
              </div>
            </div>
          </div>
          <h1
            className="global_font f-dark"
            style={{
              alignSelf: 'center',
              fontSize: 16,
              fontStyle: 'italic',
              color: Colors.neutral_500,
              textAlign: 'center',
              maxWidth: 500,
              marginBottom: 20,
            }}
          >
            Please let us know if the shift has changed. After confirming with the other party, you
            will be notified of any adjustments.
          </h1>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '90%',
              alignItems: 'center',
            }}
          >
            <textarea
              className="textarea-style"
              placeholder="Reason for Adjustment"
              onChange={(e) => setMessage(e.target.value)}
              value={message}
              style={{ width: 500 }}
            />

            {renderJobField(
              'Arrival:',
              !localStart ? 'Time' : localStart,
              'start_time',
              startClock,
            )}

            {renderJobField('Departure:', !localEnd ? 'Time' : localEnd, 'end_time', endClock)}
            <div style={{ marginTop: 15 }}>
              <div className="row_container" onClick={() => showContent('break')}>
                <div className="name_icon_container">
                  <p className="date" style={{ marginRight: 16 }}>
                    Meal Break:
                  </p>
                  <p className="date_value">
                    {lunchBreak
                      ? `${lunchBreak} min ${isBreakPaid ? '(Paid)' : '(Unpaid)'}`
                      : 'Time'}
                  </p>
                </div>
                <div>
                  <img alt="arrow" src={arrow} style={{ paddingRight: 25 }} />
                </div>
              </div>
              <div className="line" />
            </div>
            <div style={{ marginBottom: 50, width: 400, marginTop: 50 }}>
              <Button
                text="Send Adjustment"
                size="large"
                color={btnColor}
                disabled={btnDisabled}
                onClick={() => setShowConfirmAdjustmentPopupModal(true)}
              />
            </div>

            <MobileAppModal
              show={modalIsOpen}
              closeModal={() => setModalOpen(false)}
              height={973}
              content={modalContent}
              result={(event) => {
                setBreak(event.time);
                setBreakPaid(event.paid);
              }}
            />

            {showStartTime ? (
              <ShowStartTime
                content={modalContent}
                result={(result) => {
                  if (modalContent === 'start_time') {
                    setLocalStart(result);
                  } else if (modalContent === 'end_time') {
                    setLocalEnd(result);
                  }
                }}
                closeModal={() => setShowStartTime(false)}
                height={973}
                defaultStartTime={localStart}
                defaultEndTime={localEnd}
              />
            ) : null}
          </div>
        </div>
      </div>

      {showConfirmAdjustmentPopupModal ? (
        <ConfirmAdjustmentPopup
          closePopupClicked={() => setShowConfirmAdjustmentPopupModal(false)}
          btnConfirmClicked={() => submitAdjustment()}
          adjustedHours={`${localStart} - ${localEnd}`}
          adjustedBreak={`${lunchBreak} minutes(${isBreakPaid ? 'Paid' : 'Unpaid'})`}
          totalDifferenceHours={timeDifferenceCalculator(
            job?.job_datetime,
            job?.job_datetime_end,
            `${job?.local_date} ${localStart}`,
            `${job?.local_date} ${localEnd}`,
            job?.is_lunch_break_paid ? '0.0' : job?.lunch_final_break_time,
            isBreakPaid ? '0.0' : lunchBreak,
          )}
        />
      ) : null}
    </>
  );
}
