export const validateEmail = (email) => {
  const re =
    // eslint-disable-next-line max-len, no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export function isPartialShift(job) {
  if (!job || !job.originalBegin || !job.begin || !job.originalEnd || !job.end) {
    return false;
  }

  const originalBegin = new Date(job.originalBegin);
  const currentBegin = new Date(job.begin);
  const originalEnd = new Date(job.originalEnd);
  const currentEnd = new Date(job.end);

  return (
    originalBegin.getTime() !== currentBegin.getTime() ||
    originalEnd.getTime() !== currentEnd.getTime()
  );
}

export const validatePhone = (phone) => {
  const cleanedNumber = phone.replace(/\D/g, '');

  const phoneRegex = /^(?:\d{1}\s?)?(\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/;

  return phoneRegex.test(cleanedNumber);
};

export function getAverageRating(ratings) {
  if (!ratings || !Array.isArray(ratings)) {
    return null;
  }

  const filteredRatings = ratings.filter((rating) => rating !== null);

  return filteredRatings.length > 0
    ? filteredRatings.reduce((sum, rating) => sum + rating, 0) / filteredRatings.length
    : null;
}

/**
 * Check if a rating given is considered good
 * @param {Number} rating
 * @returns {Boolean}
 */
export function isGoodRating(rating) {
  return rating === null || rating >= 3;
}
