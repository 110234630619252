import React from 'react';
import { motion } from 'framer-motion';

import { Colors } from '../themes/colors';

export default function Checkcircle({ checked, size = 'md' }) {
  const dimensionVariants = {
    md: {
      width: 20,
      height: 20,
      viewBox: '0 0 20 20',
      circleCX: 10,
      circleCY: 10,
      circleR: 4.5,
      rectRX: 10,
    },
    lg: {
      width: 24,
      height: 24,
      viewBox: '0 0 24 24',
      circleCX: 12,
      circleCY: 12,
      circleR: 6,
      rectRX: 12,
    },
  };

  const dimensions = dimensionVariants[size] || dimensionVariants.md;

  const circleVariants = {
    checked: {
      opacity: 1,
      r: dimensions.circleR,
    },
    unchecked: {
      opacity: 0,
      r: 0,
    },
  };

  return (
    <motion.svg
      animate={checked ? 'checked' : 'unchecked'}
      initial="unchecked"
      width={dimensions.width}
      height={dimensions.height}
      viewBox={dimensions.viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.rect
        x="2"
        y="2"
        width={dimensions.width - 4}
        height={dimensions.height - 4}
        rx={dimensions.rectRX}
        stroke={Colors.secondary_500}
        strokeWidth="2"
      />
      <motion.circle
        cx={dimensions.circleCX}
        cy={dimensions.circleCY}
        fill={Colors.secondary_500}
        variants={circleVariants}
      />
    </motion.svg>
  );
}
