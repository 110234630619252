import {
  CHANGE_HUB_FILTER,
  FETCH_HIGHLY_RATED_PROFESSIONALS_ACTION,
  HIDE_CONNECTIONS_HUB_POPUP,
  HIDE_PARTNERSHIP_CODE_SUCCESS_POPUP,
  HIDE_REGISTRATION_SUCCESS_POPUP,
  SHOW_CONNECTIONS_HUB_POPUP,
  SHOW_REGISTRATION_SUCCESS_POPUP,
} from '~/actions';
import { WC_MANAGEMENT_STATUS } from '~/components/WorkerClassificationManagement/utils';
import { IPartnerReferral, IUser } from '~/models';
import { apiMethodsConst } from '~/services/methods';
import { IUserReducerState, TSelectedChildOffice, TUserAction } from './interfaces';

export const initialState: IUserReducerState = {
  selectedChildOffice: null,
  selectedOfficeId: null,
  user: null,
  childOffices: [],
  filteredChilds: null,
  pendingPhoneVerification: false,
  userEducation: null,
  officeEducation: null,
  allProfessions: [],
  allSpecializations: [],
  allSoftwares: [],
  allMetadata: null,
  notificationSettings: null,
  referralLink: null,
  referralInfo: null,
  favoriteProfessionals: [],
  recentProfessionals: [],
  highlyRatedProfessionals: {
    page: 0,
    limit: 15,
    data: [],
    isActiveRequest: false,
    isFinal: false,
  },
  professionalHubFilter: 'all',
  partnershipOrganizations: [],
  showRegistrationSuccessPopup: false,
  showPartnershipCodeSuccess: false,
  showSuccessWorkerClassificationPopup: false,
  showConnectionsHubPopup: false,
  showWorkerClassificationManagementPopup: false,
  stateWorkerClassification: null,
  workerClassificationManagement: {
    unselected: [],
    confirmed: [],
    pending: [],
  },
};

export default (
  state: IUserReducerState = initialState,
  action: TUserAction,
): IUserReducerState => {
  switch (action.type) {
    case 'SELECT_OFFICE_REQUEST':
      return initialState;

    case 'SELECT_OFFICE_SUCCESS':
      return {
        ...state,
        selectedOfficeId: action.payload,
      };
    case 'CREATE_REFERRAL_LINK_SUCCESS':
      return {
        ...state,
        referralLink: action.payload.referralLink,
        referralInfo: action.payload.referralInfo,
      };
    case 'SEARCH_OFFICE_ACTION_SUCCESS': {
      const text = action.payload.toLowerCase();
      const filteredChilds = state.childOffices.filter((office) =>
        office.office_name.toLowerCase().includes(text),
      );

      return {
        ...state,
        filteredChilds,
      };
    }
    case 'VERIFY_PARTNERSHIP_CODE_SUCCESS':
      return {
        ...state,
        selectedChildOffice: {
          ...state.selectedChildOffice,
          partnerReferral: {
            partnershipCode: {
              code: action.payload.code,
              organization: action.payload.organization,
            },
          } as IPartnerReferral,
        } as TSelectedChildOffice,
        showPartnershipCodeSuccess: true,
      };
    case HIDE_PARTNERSHIP_CODE_SUCCESS_POPUP:
      return {
        ...state,
        showPartnershipCodeSuccess: false,
      };
    case SHOW_REGISTRATION_SUCCESS_POPUP:
      return {
        ...state,
        showRegistrationSuccessPopup: true,
      };
    case HIDE_REGISTRATION_SUCCESS_POPUP:
      return {
        ...state,
        showRegistrationSuccessPopup: false,
      };
    case apiMethodsConst.UPDATE_USER_EDUCATION_REQUEST:
    case apiMethodsConst.EDIT_USER_REQUEST: {
      const updatedUser = action.payload;

      const newMaster =
        state.user?.id === updatedUser.id ? { user: updatedUser } : { user: state.user };
      const newSelectedChildOffice =
        state.selectedChildOffice?.id === updatedUser.id
          ? { selectedChildOffice: updatedUser }
          : { selectedChildOffice: state.selectedChildOffice };

      return {
        ...state,
        ...newMaster,
        ...newSelectedChildOffice,
      };
    }
    case apiMethodsConst.UPLOAD_AVATAR_REQUEST: {
      return {
        ...state,
        ...(state.selectedChildOffice
          ? {
              selectedChildOffice: {
                ...state.selectedChildOffice,
                profile_image: action.payload.profile_image,
              } as TSelectedChildOffice,
            }
          : {
              user: {
                ...state.user,
                profile_image: action.payload.profile_image,
              } as IUser,
            }),
      };
    }
    case apiMethodsConst.BLOCK_PROFESSIONAL_REQUEST:
      return {
        ...state,
        user: {
          ...state.user,
          blocked_hygienist: action.payload.blocked_hygienist.map(({ id }: { id: string }) => id),
        } as IUser,
      };
    case apiMethodsConst.AUTH_ME_REQUEST: {
      sessionStorage.setItem('UserDetail', JSON.stringify(action.payload));
      sessionStorage.setItem('loggedIn', JSON.stringify(true));

      const user = action.payload;

      return {
        ...state,
        user,
        childOffices: user?.childOffices?.length > 1 ? user?.childOffices : [],
        selectedOfficeId: user?.childOffices?.length > 1 ? user?.childOffices[0].id : null,
        selectedChildOffice:
          user?.childOffices && user?.childOffices?.length >= 0 ? user?.childOffices[0] : null,
      };
    }
    case apiMethodsConst.GET_SELECTED_OFFICE_PROFILE_REQUEST:
      return {
        ...state,
        selectedChildOffice: action.payload,
      };
    case apiMethodsConst.AUTH_LOGOUT_REQUEST:
      localStorage.removeItem('refresh_token_dental');

      return initialState;
    case apiMethodsConst.DELETE_ADDRESSES_REQUEST:
      return {
        ...state,
        user: {
          ...state.user,
          address: [],
        } as IUser,
      };
    case apiMethodsConst.ADD_ADDRESS_REQUEST:
      return {
        ...state,
        user: {
          ...state.user,
          address: [action.payload],
        } as IUser,
      };
    case apiMethodsConst.SEND_SMS_CODE_REQUEST:
      return {
        ...state,
        pendingPhoneVerification: true,
      };
    case apiMethodsConst.PHONE_VERIFICATION_REQUEST:
    case apiMethodsConst.CLOSE_MODAL_ACTION:
      return {
        ...state,
        pendingPhoneVerification: false,
      };
    case apiMethodsConst.SEND_NPS_REQUEST: {
      return {
        ...state,
        user: {
          ...state.user,
          show_nps: false,
        } as IUser,
      };
    }
    case apiMethodsConst.GET_ALL_PROFESSIONS_REQUEST: {
      return {
        ...state,
        allProfessions: action.payload,
      };
    }
    case apiMethodsConst.GET_ALL_SPECIALIZATIONS_REQUEST: {
      return {
        ...state,
        allSpecializations: action.payload,
      };
    }
    case apiMethodsConst.GET_USER_EDUCATION_REQUEST: {
      return {
        ...state,
        userEducation: action.payload,
      };
    }
    case apiMethodsConst.GET_OFFICE_EDUCATION_REQUEST: {
      return {
        ...state,
        officeEducation: action.payload?.DNT,
      };
    }
    case apiMethodsConst.GET_ALL_SOFTWARES_REQUEST: {
      return {
        ...state,
        allSoftwares: action.payload,
      };
    }
    case apiMethodsConst.FETCH_NOTIFICATION_SETTINGS_REQUEST: {
      return {
        ...state,
        notificationSettings: action.payload,
      };
    }
    /* Fix saga and reducer. Action payload is incorrect. Add test */
    case apiMethodsConst.SAVE_GIFT_EMAIL_REQUEST: {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    }
    case apiMethodsConst.FETCH_FAVORITE_PROFESSIONALS_REQUEST:
      return {
        ...state,
        favoriteProfessionals: action.payload,
      };
    case apiMethodsConst.FETCH_RECENT_PROFESSIONALS_REQUEST:
      return {
        ...state,
        recentProfessionals: action.payload,
      };
    case FETCH_HIGHLY_RATED_PROFESSIONALS_ACTION:
      return {
        ...state,
        highlyRatedProfessionals: {
          ...state.highlyRatedProfessionals,
          page: action.payload.page,
          limit: action.payload.limit || state.highlyRatedProfessionals.limit,
          isFinal: false,
          isActiveRequest: true,
        },
      };
    case apiMethodsConst.FETCH_HIGHLY_RATED_PROFESSIONALS_REQUEST: {
      return {
        ...state,
        highlyRatedProfessionals: {
          ...state.highlyRatedProfessionals,
          data:
            action.payload.page === 1
              ? action.payload.response
              : [...state.highlyRatedProfessionals.data, ...action.payload.response],
          isFinal:
            action.payload.response.length === 0 ||
            action.payload.response.length < state.highlyRatedProfessionals.limit,
          isActiveRequest: false,
        },
      };
    }
    /* Fix saga and reducer. Action payload is incorrect. Add test */
    case 'ADD_FAVORITE_PROFESSIONAL_SUCCESS':
      return {
        ...state,
        user: {
          ...state.user,
          favoriteProfessionals: [...state.favoriteProfessionals, action.payload],
        } as IUser,
      };
    case apiMethodsConst.DELETE_FAVORITE_PROFESSIONAL_REQUEST:
      return {
        ...state,
        favoriteProfessionals: state.favoriteProfessionals.filter(
          ({ id }) => id !== action.payload,
        ),
      };
    case apiMethodsConst.FETCH_PARTNERSHIP_ORGANIZATIONS_REQUEST:
      return {
        ...state,
        partnershipOrganizations: action.payload,
      };
    case apiMethodsConst.FETCH_METADATA_REQUEST:
      return {
        ...state,
        allMetadata: action.payload,
      };
    case apiMethodsConst.SAVE_WORKER_CLASSIFICATION_REQUEST: {
      const isPendingWCRequest =
        action.payload?.workerClassificationRequest?.[0]?.status === 'requested';

      return {
        ...state,
        showSuccessWorkerClassificationPopup: isPendingWCRequest ? 'requested' : 'confirmed',
        user: {
          ...action.payload,
          isWorkerClassificationEnabled: state.user?.isWorkerClassificationEnabled,
        },
      };
    }
    case apiMethodsConst.GET_WORKER_CLASSIFICATION_MANAGEMENT:
      return {
        ...state,
        workerClassificationManagement: action.payload,
      };
    case apiMethodsConst.SAVE_CHILD_WORKER_CLASSIFICATION:
      return {
        ...state,
        showWorkerClassificationManagementPopup: WC_MANAGEMENT_STATUS.CONFIRMED,
      };
    case apiMethodsConst.SAVE_STATUS_CHILD_WORKER_CLASSIFICATION:
      return {
        ...state,
        showWorkerClassificationManagementPopup: action.payload.status,
      };
    case apiMethodsConst.GET_STATE_WORKER_CLASSIFICATION_REQUEST:
      return {
        ...state,
        stateWorkerClassification: action.payload,
      };
    case 'HIDE_SUCCESS_WORKER_CLASSIFICATION_POPUP':
      return {
        ...state,
        showSuccessWorkerClassificationPopup: false,
        showWorkerClassificationManagementPopup: false,
      };
    case SHOW_CONNECTIONS_HUB_POPUP:
      return {
        ...state,
        showConnectionsHubPopup: true,
      };

    case HIDE_CONNECTIONS_HUB_POPUP:
      return {
        ...state,
        showConnectionsHubPopup: false,
      };
    case apiMethodsConst.GET_USER_OVERTIME_LIMITS_REQUEST:
      return {
        ...state,
        selectedChildOffice: {
          ...state.selectedChildOffice,
          overtimeLimits: action.payload,
        } as TSelectedChildOffice,
      };
    case CHANGE_HUB_FILTER:
      return {
        ...state,
        professionalHubFilter: action.payload,
      };
    default:
      return state;
  }
};
