import { useUserSelector } from '../useUserSelector';

export const useCanPostShifts = () => {
  const user = useUserSelector();

  if (!user) {
    return false;
  }

  const allowedRequest = user?.allowedRequest || {};
  const missingWorkerClassification =
    user?.isWorkerClassificationEnabled && !user?.workerClassification?.classificationType;

  return allowedRequest?.tempOrMdbShifts && !missingWorkerClassification;
};
