import 'react-day-picker/dist/style.css';
import '../themes/registration.scss';
import '../themes/calendar.scss';

import moment from 'moment';
import React, { useState } from 'react';
import { DayPicker } from 'react-day-picker';

import ClosePopupIcon from '../images/ClosePopupIcon.svg';
import { Colors } from '../themes/colors';

export const NUMBER_OF_DAYS_TO_ADD = 99;

export default function CalendarPopup({ date, confirmDateBtn, closeModal }) {
  const [selectedDay, setSelectedDay] = useState(date ? new Date(date) : undefined);
  const someDate = moment().add(NUMBER_OF_DAYS_TO_ADD, 'days').toDate();

  return (
    <div className="modal">
      <div className="modal_content" style={{ position: 'relative' }}>
        <img
          src={ClosePopupIcon}
          style={{
            position: 'absolute',
            marginTop: 16,
            marginRight: 16,
            cursor: 'pointer',
            alignSelf: 'flex-end',
          }}
          onClick={() => closeModal()}
        />
        <h1 className="title_font" style={{ color: Colors.primary_500, fontSize: 24, margin: 25 }}>
          Select Date
        </h1>
        <div style={{ margin: '5px 10px' }}>
          <DayPicker
            onDayClick={(day, { selected }) => {
              if (moment(day).isBetween(moment().subtract(1, 'days'), moment(someDate))) {
                setSelectedDay(selected ? undefined : day);
              }
            }}
            defaultMonth={new Date()}
            selected={selectedDay}
            disabled={[
              {
                before: new Date(),
                after: someDate,
              },
            ]}
          />
        </div>
        <div style={{ margin: '20px 0px' }}>
          <button
            style={{ width: 150 }}
            className={!selectedDay ? 'light-blue-button' : 'blue-button'}
            onClick={() => {
              closeModal();
              confirmDateBtn(selectedDay);
            }}
            disabled={!selectedDay}
          >
            <p className="white-text-18">Confirm</p>
          </button>
        </div>
      </div>
    </div>
  );
}
