import { useState } from 'react';
import Button from '../../../../../commonComponents/Button';
import InputSelect from '../../../../../commonComponents/InputSelect';
import startClockIcon from '../../../../../images/start-clock.svg';
import { Colors } from '../../../../../themes/colors';
import { CollapsibleRow } from '../CollapsibleRow/CollapsibleRow';
import { Title } from '../CollapsibleRow/Title';
import { Value } from '../CollapsibleRow/Value';
import { Text } from '../../../../../commonComponents';

const hoursOfDay = [];

for (let hour = 0; hour < 24; hour += 1) {
  for (let minute = 0; minute < 60; minute += 15) {
    let formattedHour;
    if (hour === 0) {
      formattedHour = 12;
    } else if (hour > 12) {
      formattedHour = hour - 12;
    } else {
      formattedHour = hour;
    }
    const ampm = hour < 12 ? 'am' : 'pm';
    const formattedHourPadded = formattedHour.toString().padStart(2, '0');
    const formattedMinute = minute.toString().padStart(2, '0');
    const title = `${formattedHourPadded}:${formattedMinute} ${ampm}`;
    hoursOfDay.push({ title, value: title });
  }
}

export const HoursRow = ({ defaultArrival, defaultDeparture, onToggle, isOpen, onConfirm }) => {
  const [arrival, setArrival] = useState(defaultArrival ?? '08:00 am');
  const [departure, setDeparture] = useState(defaultDeparture ?? '05:00 pm');
  const isDisabled = !arrival || !departure;

  return (
    <CollapsibleRow
      isOpen={isOpen}
      onToggle={onToggle}
      TitleComponent={() => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Title text="Hours:" />

          <div
            style={{
              height: 16,
              width: 24,
              backgroundColor: Colors.neutral_300,
              borderRadius: 100,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '0 4px',
              marginLeft: 8,
            }}
          >
            <span
              style={{
                fontFamily: 'Nunito',
                fontSize: 12,
                color: Colors.white,
                fontWeight: 700,
              }}
            >
              EDT
            </span>
          </div>
        </div>
      )}
      ValueComponent={() => (
        <Value
          customStyles={{
            color:
              isOpen || !defaultArrival || !defaultDeparture
                ? Colors.neutral_300
                : Colors.neutral_600,
          }}
          text={
            !isOpen && defaultArrival && defaultDeparture
              ? `${`${arrival} - ${departure}`}`
              : 'Arrival/Departure'
          }
        />
      )}
      icon={startClockIcon}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 32,
        }}
      >
        <Text color={Colors.neutral_500} fontStyle="italic" textAlign="center">
          Setting “Arrival” 15-30 minutes before the first patient allows professionals to
          <br />
          become familiar with your office and be ready to work.
        </Text>
        <div
          style={{
            padding: '0 60px',
            display: 'flex',
            flexDirection: 'row',
            gap: 20,
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 12,
            }}
          >
            <Title text="Arrival:" />
            <InputSelect value={arrival} setValue={setArrival} options={hoursOfDay} />
          </div>
          <Title text="/" />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 12,
            }}
          >
            <Title text="Departure:" />
            <InputSelect value={departure} setValue={setDeparture} options={hoursOfDay} />
          </div>
        </div>

        <Button
          color="secondary"
          text="Confirm Hours"
          disabled={isDisabled}
          style={{
            height: 48,
            width: 180,
            backgroundColor: isDisabled ? Colors.secondary_100 : Colors.secondary_500,
          }}
          onClick={() => {
            onConfirm(arrival, departure);
          }}
        />
      </div>
    </CollapsibleRow>
  );
};
