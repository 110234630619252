import { useWatch } from 'react-hook-form';

export const useSoftware = (control) => {
  const watchedValues = useWatch({
    control,
    name: ['softwares'],
  });

  const formValues = {
    softwares: watchedValues[0],
  };

  const isValid = formValues.softwares?.length !== 0;

  return {
    formValues,
    isValid,
  };
};
