import { useSelector } from 'react-redux';
import { useReportIncidentItem } from '~/components/JobList/TodayJobs/ReportIncidentPopup/hooks';
import { FETCH_TODAY_JOBS_ACTION } from '../../../actions';
import { createLoadingSelector } from '../../../api/selectors';
import Button from '../../../commonComponents/Button';
import CustomSpinner from '../../../commonComponents/CustomSpinner';
import arrowRight from '../../../images/arrow_right.svg';
import { Colors } from '../../../themes/colors';
import { useTable } from '../hooks/useTable';
import { ReportIncidentPopup } from './ReportIncidentPopup/ReportIncidentPopup';

import WarningOutlineIcon from '../../../images/warning-outline-icon.svg';
import { UserProfession, UserSubProfession } from '../../../enums/UserProfession';

const tableHeaderStyle = {
  fontFamily: 'Nunito',
  fontWeight: 'bold',
  fontSize: '18px',
  color: Colors.primary_500,
  textAlign: 'center',
};

const tableDataBoldStyle = {
  fontFamily: 'Nunito',
  fontWeight: 700,
  fontSize: 18,
  color: Colors.neutral_600,
};

const tableDataNormalStyle = {
  fontFamily: 'Nunito',
  fontWeight: 400,
  fontSize: 16,
  color: Colors.neutral_500,
};

export const Table = ({ limit }) => {
  const { jobListScrollRef, onRowClick } = useTable({ type: 'today' });

  const jobList = useSelector((state) => state.job.todayJobs);
  const jobListToMap = limit ? jobList?.slice(0, limit) : jobList;

  const loadingSelector = createLoadingSelector([FETCH_TODAY_JOBS_ACTION]);
  const isLoading = useSelector((state) => loadingSelector(state));

  return (
    <>
      {!isLoading && (
        <table
          style={{
            width: '100%',
            borderCollapse: 'collapse',
          }}
        >
          <thead
            style={{
              borderBottom: '12px solid white',
            }}
          >
            <th style={{ opacity: 0, width: 60 }}>#</th>
            <th
              style={{
                ...tableHeaderStyle,
                textAlign: 'left',
                paddingLeft: 16,
              }}
            >
              Professional
            </th>
            <th style={tableHeaderStyle}>Office</th>
            <th style={tableHeaderStyle}>Shift Details</th>
            <th style={tableHeaderStyle}>{/* Report */}</th>
            <th style={{ opacity: 0 }}>#</th>
          </thead>
          <tbody ref={jobListScrollRef}>
            {jobListToMap.map((job) => (
              <TableItem job={job} onRowClick={onRowClick} />
            ))}
          </tbody>
        </table>
      )}

      {jobListToMap?.length === 0 && !isLoading && (
        <div>
          <p className="font_bold" style={{ textAlign: 'center' }}>
            There are no scheduled shifts for today.
          </p>
        </div>
      )}
      {isLoading && <CustomSpinner isLoading={isLoading} />}
      <ReportIncidentPopup />
    </>
  );
};

export const TableItem = ({ job, onRowClick }) => {
  const { canReport, canRepost, isReported, onReportIncidentClick } = useReportIncidentItem(job);

  return (
    <>
      <tr
        key={job.id}
        style={{
          cursor: 'pointer',
          borderBottom: `1px solid ${Colors.neutral_100}`,
          paddingBottom: 8,
        }}
        onClick={() => onRowClick(job)}
      >
        <td>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <img
              src={job.professional?.image}
              style={{
                width: 60,
                height: 60,
                objectFit: 'contain',
                borderRadius: 60,
                border: `1px solid ${Colors.neutral_100}`,
              }}
              alt=""
            />
          </div>
        </td>
        <td>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: 16,
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <span style={tableDataBoldStyle}>{`${job.professional?.full_name}`}</span>
              <span style={tableDataNormalStyle}>
                {`${
                  UserSubProfession.getName(job.subProfession) ??
                  UserProfession.getName(job.profession)
                }`}
              </span>
            </div>
          </div>
        </td>
        <td>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <span style={tableDataNormalStyle}>{job.offer_owner.office}</span>
          </div>
        </td>
        <td>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                ...tableDataBoldStyle,
                fontSize: 16,
                color: Colors.neutral_500,
              }}
            >
              {job.shift?.local_date}
            </span>
            <span style={tableDataNormalStyle}>
              {`${job.shift?.local_start_time} - ${job.shift?.local_end_time}`}
            </span>
          </div>
        </td>
        <td>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {(canReport || canRepost) && (
              <Button
                Icon={isReported ? WarningOutlineIcon : null}
                iconStyle={{ width: 18, height: 18, marginRight: 0 }}
                style={{
                  width: 138,
                  height: 30,
                  marginTop: 0,
                  backgroundColor: isReported ? Colors.neutral_200 : Colors.neutral_400,
                }}
                disabled={isReported}
                text={
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 8,
                    }}
                  >
                    <span
                      style={{
                        fontFamily: 'Nunito',
                        fontSize: 12,
                        fontWeight: 700,
                      }}
                    >
                      {isReported ? 'Reported' : 'Report Incident'}
                    </span>
                  </div>
                }
                onClick={(event) => {
                  event.stopPropagation();
                  onReportIncidentClick();
                }}
              />
            )}
          </div>
        </td>
        <td>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <img src={arrowRight} style={{ width: 10, objectFit: 'cover' }} alt="arrow_right" />
          </div>
        </td>
      </tr>
    </>
  );
};
