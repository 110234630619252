import { Colors } from '~/themes/colors';
import { Box, IBoxProps } from '../Box';

export const Screen = ({
  children,
  backgroundColor = Colors.neutral_50,
  flex = 1,
  minWidth = 1200,
  ...rest
}: IBoxProps) => (
  <Box backgroundColor={backgroundColor} flex={flex} minWidth={minWidth} {...rest}>
    {children}
  </Box>
);
