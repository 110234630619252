import { apiMethodsConst } from '~/services/methods';
import { IOfficeReviewState, TOfficeReviewAction } from './interfaces';

const initialState: IOfficeReviewState = {
  rating: {
    collaboration: 0,
    environment: 0,
    overall: 0,
    workload: 0,
  },
  totalReviews: 0,
};

export default (
  state: IOfficeReviewState = initialState,
  action: TOfficeReviewAction,
): IOfficeReviewState => {
  const { payload, type } = action;
  switch (type) {
    case apiMethodsConst.GET_OFFICE_REVIEW_INFO_REQUEST: {
      const {
        averageWorkEnvironment,
        averageWorkload,
        averageCollaborationAndRespect,
        overallAverageRating,
        totalReviews,
      } = payload;

      return {
        ...state,
        rating: {
          collaboration: averageCollaborationAndRespect,
          environment: averageWorkEnvironment,
          overall: overallAverageRating,
          workload: averageWorkload,
        },
        totalReviews,
      };
    }
    default: {
      return { ...state };
    }
  }
};
