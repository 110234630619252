import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { addPayment, showMessage } from '../actions';
import CvcCardIcon from '../images/cvc-card.svg';
import { Colors } from '../themes/colors';
import '../themes/payment.scss';
import Button from './Button';

export const CardForm = ({ onSuccess }) => {
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const [isCardNumberComplete, setCardNumberComplete] = useState(false);
  const [isCardExpiryComplete, setCardExpiryComplete] = useState(false);
  const [isCardCvcComplete, setCardCvcComplete] = useState(false);
  const [zipCode, setZipCode] = useState('');
  const isSubmitDisabled = !(
    isCardNumberComplete &&
    isCardExpiryComplete &&
    isCardCvcComplete &&
    zipCode.length === 5
  );

  const handleZipChange = (value) => {
    if (/^\d{0,5}$/.test(value)) {
      setZipCode(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    const { token, error } = await stripe.createToken(cardElement, {
      currency: 'usd',
      address_country: 'US',
      address_zip: zipCode,
    });

    if (error) {
      dispatch(showMessage({ title: 'Error', message: error, isError: true }));
    } else {
      dispatch(addPayment({ card: { token } }));
      onSuccess();
    }
  };

  return (
    <div>
      <div
        style={{
          width: '100%',
          backgroundColor: Colors.neutral_50,
          marginLeft: -40,
          padding: '40px 40px 60px 40px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 12,
          }}
        >
          <div style={styles.container}>
            <span style={styles.title}>Card Number</span>
            <CardNumberElement
              id="cardnumber"
              options={{
                showIcon: true,
                style: styles.cardInput,
              }}
              onChange={(event) => {
                setCardNumberComplete(event.complete);
                if (event.complete) {
                  const expiryElement = elements.getElement(CardExpiryElement);
                  if (expiryElement) {
                    expiryElement.focus();
                  }
                }
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 12,
            }}
          >
            <div style={{ ...styles.container, width: '50%' }}>
              <span style={styles.title}>Expiration</span>
              <CardExpiryElement
                id="exp-date"
                options={{
                  style: styles.cardInput,
                }}
                onChange={(event) => {
                  setCardExpiryComplete(event.complete);
                  if (event.complete) {
                    const cvcElement = elements.getElement(CardCvcElement);
                    if (cvcElement) {
                      cvcElement.focus();
                    }
                  }
                }}
              />
            </div>
            <div style={{ ...styles.container, width: '50%' }}>
              <span style={styles.title}>CVC</span>
              <div style={{ position: 'relative' }}>
                <CardCvcElement
                  id="cvc"
                  options={{
                    style: styles.cardInput,
                  }}
                  onChange={(event) => {
                    setCardCvcComplete(event.complete);
                  }}
                />
                <img
                  src={CvcCardIcon}
                  alt="CVC"
                  style={{
                    position: 'absolute',
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    width: '24px',
                    height: '24px',
                  }}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 12,
            }}
          >
            <div style={{ ...styles.container, width: '50%' }}>
              <span style={styles.title}>Country</span>
              <input
                value="United States"
                style={{
                  fontSize: 16,
                  color: Colors.neutral_600,
                  backgroundColor: Colors.neutral_70,
                  paddingTop: 11,
                  paddingBottom: 11,
                }}
                disabled
              />
            </div>
            <div style={{ ...styles.container, width: '50%' }}>
              <span style={styles.title}>ZIP</span>
              <input
                id="zip"
                value={zipCode}
                style={{
                  fontSize: 16,
                  color: Colors.neutral_600,
                  paddingTop: 11,
                  paddingBottom: 11,
                }}
                placeholder="12345"
                onChange={(event) => handleZipChange(event.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          text="Add Card"
          onClick={handleSubmit}
          disabled={isSubmitDisabled}
          style={{
            width: 152,
            height: 48,
            marginTop: 40,
            marginBottom: -72,
            backgroundColor: isSubmitDisabled ? Colors.primary_300 : Colors.primary_500,
          }}
        />
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontFamily: 'Nunito',
    fontSize: 16,
    fontWeight: 700,
    color: Colors.neutral_600,
    textAlign: 'left',
  },
  cardInput: {
    base: {
      fontSize: '16px',
      WebkitFontSmoothing: 'antialiased',
      color: Colors.neutral_600,
      '::placeholder': {
        color: Colors.neutral_300,
      },
    },
  },
};
