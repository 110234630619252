export const EmptyFavoritesDescription = () => (
  <>
    Your office have no added any professionals to your favorites list. There are three ways you can
    add a professional to your favorites:
    <ul
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
        marginTop: 20,
        marginLeft: -20,
      }}
    >
      <li>
        <b>End-of-day review:</b> Click “Add to Favorites” when prompted to leave a review for a
        professional.
      </li>
      <li>
        <b>Completed Shifts screen:</b> Go to Schedule {'>'} Completed Shifts, and view the Shift
        Details screen.
      </li>
      <li>
        <b>Professionals Hub:</b> You can add the professionals you have recently worked with or you
        rated highly to your favorites.
      </li>
    </ul>
  </>
);
