import { Box, Column, Text } from '~/commonComponents';
import { EmptyScreen } from '~/commonComponents/EmptyScreen/EmptyScreen';
import { EmptyHighlyRatedIcon } from '~/images/emptyScreenIcons';
import { fetchHighlyRatedProfessionals } from '../../../actions';
import { trackEvent } from '../../../api/analytics';
import { SendInviteToModal } from '../SendInviteToModal';
import { filterHighlyRatedProfessionals, mapHighlyRatedToRowProps } from '../utils';

export const SendToHighlyRatedProfessionalsModal = ({ onClose }) => (
  <SendInviteToModal
    fetchProfessionalsAction={fetchHighlyRatedProfessionals}
    professionalsSelector={(state) => state.user.highlyRatedProfessionals?.data}
    filterFunction={filterHighlyRatedProfessionals}
    mapProfessionalToRowProps={mapHighlyRatedToRowProps}
    EmptyComponent={() => (
      <Box marginTop={-32}>
        <EmptyScreen
          Icon={EmptyHighlyRatedIcon}
          width={420}
          description={
            <Column>
              <Text fontSize={18} bold marginBottom={20} textAlign="center">
                No Highly-Rated Professionals
              </Text>
              It looks like you have not rated any professionals with an average rating greater than
              4.2 yet! There are two ways to add professionals to this list:
              <ul
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 24,
                  marginTop: 20,
                  marginLeft: -20,
                }}
              >
                <li>
                  <b>End-of-day review:</b> Leave a rating for a professional during the end of the
                  day review.
                </li>
                <li>
                  <b>Professional Hub:</b> Add a rating for a professional you recently worked with
                  on the “Recent Professionals” tab.
                </li>
              </ul>
            </Column>
          }
          descriptionStyle={{
            textAlign: 'left',
          }}
        />
      </Box>
    )}
    headerTitle="Your Highly-Rated Professionals"
    onClose={onClose}
    listener={(job, professionalsIds) => {
      trackEvent('Send to - Highly-Rated invite sent/submitted', {
        job: {
          professionalsIds,
          jobId: job.id,
          profession: job.profession,
          rate: job.rate,
          shiftId: job.shiftId,
          totalHours: job.totalHours,
          local_date: job.local_date,
          local_end_time: job.local_end_time,
          local_start_time: job.local_start_time,
          local_timezone: job.local_timezone,
          lunch_break_time: job.lunch_break_time,
          lunch_final_break_time: job.lunch_final_break_time,
          specialty: job.specialty,
        },
      });
    }}
  />
);
