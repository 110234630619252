import { Colors } from '../../themes/colors';

export const ThunderLightningIcon = ({ color = Colors.secondary_500, size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.416 21.5765C9.33991 21.5765 9.26268 21.5612 9.18926 21.5294C8.93156 21.4182 8.792 21.1368 8.85968 20.8645L10.799 13.0576H5.57335C5.38409 13.0576 5.20706 12.9644 5.10038 12.8084C4.99371 12.6524 4.97038 12.4535 5.03844 12.2773L8.87191 2.36668C8.95756 2.14568 9.16976 2 9.40682 2H14.6818C14.8756 2 15.0565 2.09788 15.1624 2.26038C15.2683 2.42288 15.2851 2.62744 15.2067 2.80485L12.9367 7.95744H17.4263C17.6362 7.95744 17.8289 8.07176 17.9295 8.25606C18.03 8.43997 18.022 8.66441 17.9088 8.84106L9.89853 21.313C9.79032 21.4816 9.60641 21.5765 9.416 21.5765Z"
      fill={color}
    />
  </svg>
);
