import React, { useState, useRef, forwardRef, useImperativeHandle, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import '../themes/carousel.scss';

import ActiveIcon from '../images/carousel/carousel-active.svg';
import InactiveIcon from '../images/carousel/carousel-inactive.svg';

export const Carousel = forwardRef(
  ({ data, renderItem, loop = false, onSnapToItem, containerCustomStyle }, ref) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const directionRef = useRef(1); // Store the direction of movement
    const sliderRef = useRef(null); // Ref to access the slider element

    // Allow parent to call next/prev methods via ref
    useImperativeHandle(ref, () => ({
      goToNext: handleNext,
      goToPrev: handlePrev,
    }));

    const updateIndex = (newIndex) => {
      setCurrentIndex(newIndex);
      directionRef.current = newIndex > currentIndex ? 1 : -1;
      onSnapToItem?.(newIndex);
    };

    const handleNext = () => {
      const nextIndex = currentIndex + 1;
      updateIndex(loop && nextIndex >= data.length ? 0 : Math.min(nextIndex, data.length - 1));
    };

    const handlePrev = () => {
      const prevIndex = currentIndex - 1;
      updateIndex(loop && prevIndex < 0 ? data.length - 1 : Math.max(prevIndex, 0));
    };

    const handleDotClick = (index) => {
      if (index !== currentIndex) updateIndex(index);
    };

    const slideVariants = {
      enter: (direction) => ({ x: direction > 0 ? 300 : -300, opacity: 0 }),
      center: { x: 0, opacity: 1 },
      exit: (direction) => ({ x: direction < 0 ? 300 : -300, opacity: 0 }),
    };

    // Reset scroll position on slide change
    useEffect(() => {
      if (sliderRef.current) {
        sliderRef.current.scrollTop = 0;
      }
    }, [currentIndex]);

    return (
      <div className="carousel" style={containerCustomStyle}>
        <div className="carousel-controls">
          <div className="carousel-slider" ref={sliderRef}>
            <AnimatePresence initial={false} custom={directionRef.current}>
              <motion.div
                key={currentIndex}
                custom={directionRef.current}
                variants={slideVariants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{
                  x: { type: 'spring', stiffness: 300, damping: 30 },
                  opacity: { duration: 0.2 },
                }}
                className="carousel-slide"
              >
                {renderItem({ item: data[currentIndex], index: currentIndex })}
              </motion.div>
            </AnimatePresence>
          </div>

          <div className="carousel-dots">
            {data.map((_, index) => (
              <img
                key={index}
                src={currentIndex === index ? ActiveIcon : InactiveIcon}
                alt="carousel-dot"
                className="carousel-dot"
                onClick={() => handleDotClick(index)}
                style={{ cursor: 'pointer' }}
              />
            ))}
          </div>
        </div>
      </div>
    );
  },
);

Carousel.displayName = 'Carousel';
