import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  DELETE_ALL_ALERTS_ACTION,
  DELETE_ONE_ALERT_ACTION,
  GET_USER_MESSAGES,
} from '../../../actions';
import { trackEvent } from '../../../api/analytics';
import { Column } from '../../../commonComponents';
import CustomSpinner from '../../../commonComponents/CustomSpinner';
import history from '../../../history';
import useLoading from '../../../hooks/useLoading';
import { EmptyChatIcon } from '../../../images/icons';
import Messages from '../../messages/Messages';
import { EmptyInbox } from '../Empty';
import { useChatsDeletion } from './hooks/useChatsDeletion';
import { useChatsInfiniteScroll } from './hooks/useChatsInfiniteScroll';

export const Chat = ({ isRootComponent }) => {
  const chats = useSelector((state) => state.messages.allJobsWithMessages);
  const dashboardInfo = useSelector((state) => state.job.dashboardInfo);

  const { handleDeleteAll, handleDeleteOneAlertSubmit } = useChatsDeletion();
  const { elRef, getUserMessages, onScrollListener } = useChatsInfiniteScroll();
  const isFinalPage = useSelector((state) => state.messages.isFinalPage);
  const { isLoading } = useLoading([
    GET_USER_MESSAGES,
    DELETE_ALL_ALERTS_ACTION,
    DELETE_ONE_ALERT_ACTION,
  ]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      if (scrollPosition + windowHeight >= documentHeight) {
        getUserMessages();
      }
    };

    if (isRootComponent) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isRootComponent, getUserMessages]);

  if (isLoading && chats.length === 0) {
    return <CustomSpinner isLoading size={50} />;
  }

  if (!isLoading && chats.length === 0) {
    return (
      <EmptyInbox
        Icon={EmptyChatIcon}
        title="No chats yet!"
        description={
          <>
            Get started by reaching out to a professional
            <br />
            once they’re scheduled for their shift.
          </>
        }
        descriptionStyle={{
          textAlign: 'center',
        }}
      />
    );
  }

  const selectedMessage = (job) => {
    trackEvent('Office Dashboard - Inbox Chats Tile Clicked', {
      actionNeededShiftsCount: dashboardInfo?.actionRequired,
      scheduledShiftsCount: dashboardInfo?.scheduled,
      openShiftsCount: dashboardInfo?.activeRequest,
    });

    history.push(`/dashboard/chat/${job.id}`, { jobId: job.id });
  };

  return (
    <Column
      ref={elRef}
      onScroll={onScrollListener}
      style={{
        borderRadius: 25,
        boxShadow: '0px 2px 4px rgba(111, 131, 141, 0.325068)',
        maxHeight: isRootComponent ? undefined : 500,
        overflowY: 'scroll',
        flex: 1,
      }}
    >
      <Messages
        isLoading={isLoading}
        selectedJobs={selectedMessage}
        deleteAll={handleDeleteAll}
        deleteOneAlert={handleDeleteOneAlertSubmit}
      />
      {!isFinalPage && isLoading && <CustomSpinner isLoading size={50} />}
    </Column>
  );
};
