import { apiMethodsConst } from '~/services/methods';
import {
  IUserCredentialsReducerState,
  TUserCredentialAction,
} from '~/reducers/userCredentialsReducer/interfaces';
import { DefaultGeneralSpecialty } from '~/constants';

export const initialState: IUserCredentialsReducerState = {
  credentials: {
    education: {
      specialtiesAndProcedures: {},
      softwares: [],
    },
    insurances: [],
    softwares: [],
    educationUpdatedAt: null,
  },
  specialty: DefaultGeneralSpecialty,
};

export default (
  state = initialState,
  action: TUserCredentialAction,
): IUserCredentialsReducerState => {
  switch (action.type) {
    case apiMethodsConst.USER_CREDENTIALS_REQUEST: {
      const credentials = action.payload;

      return {
        ...state,
        credentials,
        specialty:
          Object.keys(credentials.education?.specialtiesAndProcedures || {})[0] ||
          DefaultGeneralSpecialty,
      };
    }
    default:
      return state;
  }
};
