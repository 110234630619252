import { motion } from 'framer-motion';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { showMessage } from '../actions';
import JobPostingPopup from '../components/shared/JobPostingPopup';
import downArrow from '../images/down-arrow.svg';
import upArrow from '../images/up-arrow.svg';
import warningAlert from '../images/warning_alert.svg';
import { Colors } from '../themes/colors';

const ShowStartTime = ({
  closeModal,
  content,
  result,
  defaultStartTime,
  defaultEndTime,
  minuteInterval = 15,
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useDispatch();

  const time = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  const minutes = [];
  for (let i = 0; i < 60; i += minuteInterval) {
    minutes.push(i.toString().padStart(2, '0'));
  }

  const [selectedTime, setSelectedTime] = useState('hours');
  const [selectedStartHour, setSelectedStartHour] = useState('08');
  const [selectedEndHour, setSelectedEndHour] = useState('05');
  const [hourIsFocused, setHourIsFocused] = useState(false);
  const [hourIsValid, setHourIsValid] = useState(true);
  const [minutesIsFocused, setMinutesIsFocused] = useState(false);
  const [minutesIsValid, setMinutesIsValid] = useState(true);
  const [selectedStartMinutes, setSelectedStartMinutes] = useState('00');
  const [selectedEndMinutes, setSelectedEndMinutes] = useState('00');

  const hourInputRef = useRef(null);
  const minutesInputRef = useRef(null);

  const [timeOfDay, setTimeOfDay] = useState('');
  const [disabledBtn, setDisabledBtn] = useState(false);

  const selectedHour = content === 'start_time' ? selectedStartHour : selectedEndHour;
  const selectedMinutes = content === 'start_time' ? selectedStartMinutes : selectedEndMinutes;
  const currentTimeIndex = time.findIndex((value) => value === selectedHour);
  const currentMinutesIndex = minutes.findIndex((value) => value === selectedMinutes);

  useEffect(() => {
    if (typeof defaultEndTime === 'string' || typeof defaultStartTime === 'string') {
      const defaulFormatStart = moment(defaultStartTime, 'hh:mm aa');
      const defaulFormatEnd = moment(defaultEndTime, 'hh:mm aa');

      formatTime(defaulFormatStart, defaulFormatEnd);
    } else {
      formatTime(defaultStartTime, defaultEndTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultStartTime, defaultEndTime, content]);

  useEffect(() => {
    let isBtnDisabled = false;
    if (Number(selectedHour) > 12 || Number(selectedHour) < 0) {
      setHourIsValid(false);
      isBtnDisabled = true;
    } else {
      setHourIsValid(true);
    }

    if (Number(selectedMinutes) > 59 || Number(selectedMinutes) < 0) {
      setMinutesIsValid(false);
      isBtnDisabled = true;
    } else {
      setMinutesIsValid(true);
    }

    setDisabledBtn(isBtnDisabled);
  }, [selectedHour, selectedMinutes]);

  const formatTime = (formatStartTime, formatEndTime) => {
    const amOrPmStart = moment(formatStartTime).isValid()
      ? moment(formatStartTime, 'hh').format('A')
      : 'AM';
    const amOrPmEnd = moment(formatEndTime).isValid()
      ? moment(formatEndTime, 'hh').format('A')
      : 'PM';

    setTimeOfDay(content === 'start_time' ? 'AM' : 'PM');

    if (defaultStartTime) {
      const startTime = moment(defaultStartTime, 'hh:mm A').format('h');
      const startMinutes = moment(defaultStartTime, 'hh:mm A').format('mm');

      setSelectedStartHour(Number(startTime) > 9 ? `${startTime}` : `0${startTime}`);
      setSelectedStartMinutes(startMinutes);

      setTimeOfDay(content === 'start_time' ? amOrPmStart : amOrPmEnd);
    }
    if (defaultEndTime) {
      const endTime = moment(defaultEndTime, 'hh:mm A').format('h');
      const endMinutes = moment(defaultEndTime, 'hh:mm A').format('mm');

      setSelectedEndHour(Number(endTime) > 9 ? `${endTime}` : `0${endTime}`);
      setSelectedEndMinutes(endMinutes);

      setTimeOfDay(content === 'start_time' ? amOrPmStart : amOrPmEnd);
    }
  };

  const selectInputHour = (event) => {
    const inputText = event.target.value;

    if (Number.isNaN(Number(event.nativeEvent.data))) {
      return;
    }

    if (inputText.length > 2) {
      return;
    }

    if (content === 'start_time') {
      setSelectedStartHour(inputText);
    } else {
      setSelectedEndHour(inputText);
    }
  };

  const selectInputMinutes = (event) => {
    const inputText = event.target.value;

    if (Number.isNaN(Number(event.nativeEvent.data))) {
      return;
    }

    if (inputText.length > 2) {
      return;
    }

    if (content === 'start_time') {
      setSelectedStartMinutes(inputText);
    } else {
      setSelectedEndMinutes(inputText);
    }
  };

  const submitTime = () => {
    if (timeOfDay.length < 1) {
      dispatch(
        showMessage({ title: 'Error', message: 'Please select a time of day', isError: true }),
      );
    } else {
      const dataTime = `${
        content === 'start_time' ? selectedStartHour : selectedEndHour
      }:${selectedMinutes} ${timeOfDay}`;

      result(dataTime);
      setDisabledBtn(true);
      setTimeOfDay('');

      closeModal();
    }
  };

  const renderInvalidMessage = () => {
    if (!hourIsValid && !minutesIsValid) {
      return 'Invalid hour and minutes';
    }

    if (!hourIsValid) {
      return 'Invalid hour';
    }

    if (!minutesIsValid) {
      return 'Invalid minutes';
    }

    return '';
  };

  const opacityVariants = {
    show: { opacity: 1 },
    hide: { opacity: 0 },
  };

  const hoursBgColor = hourIsValid ? Colors.primary_500 : Colors.error_300;
  const minutesBgColor = minutesIsValid ? Colors.primary_500 : Colors.error_300;

  return (
    <JobPostingPopup closeModal={closeModal}>
      <h1
        className="title_font"
        style={{
          color: Colors.primary_500,
          fontSize: 24,
          marginTop: 35,
          marginBottom: 40,
        }}
      >
        Select {content === 'start_time' ? 'Arrival' : 'Departure'} Time
      </h1>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: Colors.neutral_50,
          padding: '40px 45px 20px 45px',
          borderRadius: 20,
          boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.15)',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div
            data-cy="hour"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {selectedTime === 'hours' && (
              <motion.img
                initial={time[currentTimeIndex + 1] ? 'show' : 'hide'}
                animate={time[currentTimeIndex + 1] ? 'show' : 'hide'}
                variants={opacityVariants}
                transition={{ duration: 0.3 }}
                src={upArrow}
                className="pressable-img"
                style={{ marginBottom: 12, width: 20, cursor: 'pointer' }}
                onClick={() => {
                  const updatedHour = String(time[currentTimeIndex + 1]);

                  if (!time[currentTimeIndex + 1]) return;

                  if (content === 'start_time') {
                    setSelectedStartHour(updatedHour);
                  } else {
                    setSelectedEndHour(updatedHour);
                  }
                }}
                alt="up_arrow"
              />
            )}

            <div
              onClick={() => setSelectedTime('hours')}
              style={{
                boxSizing: 'border-box',
                width: 80,
                height: 56,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: selectedTime === 'hours' ? hoursBgColor : Colors.neutral_200,
                border: hourIsValid ? 'none' : `2px solid ${Colors.error_500}`,
                borderRadius: 17,
                boxShadow: hourIsValid ? '0px 2.2px 3.6px 0px rgba(0, 0, 0, 0.35) inset' : 'none',
                cursor: 'pointer',
                transition: 'background-color 0.3s ease-in-out',
              }}
            >
              <motion.input
                ref={hourInputRef}
                type="text"
                key={hourIsFocused || selectedHour}
                onFocus={() => setHourIsFocused(selectedHour)}
                onBlur={() => {
                  setHourIsFocused(false);

                  if (content === 'start_time') {
                    setSelectedStartHour(selectedHour.padStart(2, '0'));
                  } else {
                    setSelectedEndHour(selectedHour.padStart(2, '0'));
                  }
                }}
                initial={{ opacity: 0, y: -5 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
                style={{
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'transparent',
                  borderRadius: 17,
                  fontFamily: 'Nunito',
                  fontSize: 48,
                  color: Colors.white,
                  textAlign: 'center',
                  margin: 0,
                  padding: 0,
                }}
                value={selectedHour}
                onChange={selectInputHour}
              />
            </div>

            {selectedTime === 'hours' && (
              <motion.img
                initial={time[currentTimeIndex - 1] ? 'show' : 'hide'}
                animate={time[currentTimeIndex - 1] ? 'show' : 'hide'}
                variants={opacityVariants}
                transition={{ duration: 0.3 }}
                src={downArrow}
                className="pressable-img"
                style={{ marginTop: 12, width: 20, cursor: 'pointer' }}
                onClick={() => {
                  const updatedHour = String(time[currentTimeIndex - 1]);

                  if (!time[currentTimeIndex - 1]) return;

                  if (content === 'start_time') {
                    setSelectedStartHour(updatedHour);
                  } else {
                    setSelectedEndHour(updatedHour);
                  }
                }}
                alt="down_arrow"
              />
            )}
          </div>

          <p
            style={{
              fontFamily: 'Nunito',
              fontSize: 48,
              margin: '0px 6px 5px 6px',
              color: Colors.neutral_300,
            }}
          >
            :
          </p>

          <div
            data-cy="minutes"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {selectedTime === 'minutes' && (
              <motion.img
                initial={minutes[currentMinutesIndex + 1] ? 'show' : 'hide'}
                animate={minutes[currentMinutesIndex + 1] ? 'show' : 'hide'}
                variants={opacityVariants}
                transition={{ duration: 0.3 }}
                src={upArrow}
                className="pressable-img"
                style={{ marginBottom: 12, width: 20, cursor: 'pointer' }}
                onClick={() => {
                  const updatedMinutes = minutes[currentMinutesIndex + 1];

                  if (!updatedMinutes) return;

                  if (content === 'start_time') {
                    setSelectedStartMinutes(updatedMinutes);
                  } else {
                    setSelectedEndMinutes(updatedMinutes);
                  }
                }}
                alt="up_arrow"
              />
            )}

            <div
              onClick={() => setSelectedTime('minutes')}
              style={{
                width: 80,
                height: 56,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: selectedTime === 'minutes' ? minutesBgColor : Colors.neutral_200,
                border: minutesIsValid ? 'none' : `2px solid ${Colors.error_500}`,
                borderRadius: 17,
                boxShadow: '0px 2.2px 3.6px 0px rgba(0, 0, 0, 0.35) inset',
                cursor: 'pointer',
                transition: 'all 0.3s ease-in-out',
              }}
            >
              <motion.input
                ref={minutesInputRef}
                type="text"
                key={minutesIsFocused || selectedMinutes}
                onFocus={() => setMinutesIsFocused(selectedMinutes)}
                onBlur={() => {
                  setMinutesIsFocused(false);

                  if (content === 'start_time') {
                    setSelectedStartMinutes(selectedMinutes.padStart(2, '0'));
                  } else {
                    setSelectedEndMinutes(selectedMinutes.padStart(2, '0'));
                  }
                }}
                initial={{ opacity: 0, y: -5 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
                style={{
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'transparent',
                  borderRadius: 17,
                  fontFamily: 'Nunito',
                  fontSize: 48,
                  color: Colors.white,
                  textAlign: 'center',
                  margin: 0,
                  padding: 0,
                }}
                value={selectedMinutes}
                onChange={selectInputMinutes}
              />
            </div>

            {selectedTime === 'minutes' && (
              <motion.img
                initial={minutes[currentMinutesIndex - 1] ? 'show' : 'hide'}
                animate={minutes[currentMinutesIndex - 1] ? 'show' : 'hide'}
                variants={opacityVariants}
                transition={{ duration: 0.3 }}
                src={downArrow}
                className="pressable-img"
                style={{ marginTop: 12, width: 20, cursor: 'pointer' }}
                onClick={() => {
                  const updatedMinutes = minutes[currentMinutesIndex - 1];

                  if (!updatedMinutes) return;

                  if (content === 'start_time') {
                    setSelectedStartMinutes(updatedMinutes);
                  } else {
                    setSelectedEndMinutes(updatedMinutes);
                  }
                }}
                alt="down_arrow"
              />
            )}
          </div>

          <div className="time_of_day_container">
            <div
              className={`top_time_btn ${timeOfDay === 'AM' ? 'selected_time_of_day' : ''}`}
              onClick={() => setTimeOfDay('AM')}
            >
              AM
            </div>
            <div
              className={`bottom_time_btn ${timeOfDay === 'PM' ? 'selected_time_of_day' : ''}`}
              onClick={() => setTimeOfDay('PM')}
            >
              PM
            </div>
          </div>
        </div>

        <motion.div
          initial={!hourIsValid || !minutesIsValid ? 'show' : 'hide'}
          animate={!hourIsValid || !minutesIsValid ? 'show' : 'hide'}
          variants={opacityVariants}
          transition={{ duration: 0.3 }}
          style={{
            display: 'flex',
            alignItems: 'center',
            columnGap: 5,
            marginTop: 15,
          }}
        >
          <img src={warningAlert} alt="warning_alert" />

          <p
            style={{
              fontFamily: 'Nunito',
              fontWeight: 'bold',
              fontSize: 16,
              color: Colors.error_500,
              margin: 0,
            }}
          >
            {renderInvalidMessage()}
          </p>
        </motion.div>
      </div>
      <div style={{ margin: '20px 0px' }}>
        <button
          style={{
            width: 154,
            backgroundColor: disabledBtn ? Colors.primary_200 : Colors.primary_500,
            transition: 'all 0.3s ease-in-out',
          }}
          type="button"
          className="green-button"
          onClick={submitTime}
          disabled={disabledBtn}
        >
          <p className="white-text-18">Confirm</p>
        </button>
      </div>
    </JobPostingPopup>
  );
};

export default ShowStartTime;
