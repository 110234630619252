import './index.css';

import { GrowthBookProvider } from '@growthbook/growthbook-react';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { MotionGlobalConfig } from 'framer-motion';
import 'moment-timezone';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { hotjar } from 'react-hotjar';

import App from './App';
import { store } from './store/configureStore';
import './services/runSagas';

import { growthbook } from './growthbook';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

growthbook.loadFeatures();

hotjar.initialize({
  id: process.env.REACT_APP_HOTJAR_ID,
  sv: process.env.REACT_APP_HOTJAR_SV,
  debug: process.env.REACT_APP_TEMP_MEE_ENV === 'development',
});

MotionGlobalConfig.skipAnimations = process.env.REACT_APP_TEMP_MEE_ENV === 'test';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_KEY,
  autoSessionTracking: true,
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.REACT_APP_TEMP_MEE_ENV,
  tracesSampleRate: 1.0,
});

// Get the root element
const container = document.getElementById('root');

// Create a root
const root = createRoot(container);

// Initial render
root.render(
  <GrowthBookProvider growthbook={growthbook}>
    <Provider store={store}>
      <Elements stripe={stripePromise}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Elements>
    </Provider>
  </GrowthBookProvider>,
);
