import '../themes/global.scss';
import '../themes/mapactiveoffer.scss';

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Colors } from '../themes/colors';
import { fetchExpiredCounterOffers, showMessage } from '../actions';

export default function ExpiredCounterOffers({ history, match }) {
  const dispatch = useDispatch();

  const job = useSelector((state) => state.job.job);
  const expiredCounteOffers = useSelector((state) => state.job.expiredCounteOffers);
  const jobId = history.location.state ? history.location.state.jobId : match.params.jobId;

  useEffect(() => {
    dispatch(fetchExpiredCounterOffers({ jobId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderRow = ({ item }) => (
    <div
      style={{ width: 500, cursor: 'pointer' }}
      onClick={() => {
        dispatch(
          showMessage({
            title: 'Expired Counter Offer',
            message:
              'Do you want to accept this counter offer? Please call/text our customer support at 786-460-2170',
          }),
        );
      }}
    >
      <div style={{ marginTop: 10, width: '95%' }}>
        <div
          style={{
            flexDirection: 'row',
            display: 'flex',
            width: '95%',
            marginLeft: 10,
            marginRight: 10,
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                fontSize: 18,
                color: Colors.neutral_800,
                fontFamily: 'Nunito',
                fontWeight: 'bold',
              }}
            >
              {`${item.requestor.first_name || 'No Name'} ${item.requestor.last_name || ''}`}
            </div>
            <div
              style={{
                display: 'flex',
                backgroundColor: Colors.neutral_300,
                borderRadius: 23,
                padding: 5,
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  fontSize: 12,
                  color: Colors.white,
                  fontFamily: 'Nunito',
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                {`Rate Requested - $${item.rate.toFixed(2)}`}
              </div>
              {/* <img src={whiteRightArrow} style={{ width: 5, height: 7, marginLeft: 4 }} /> */}
            </div>
          </div>
        </div>
        <div
          style={{
            flexDirection: 'row',
            flex: 1,
            marginLeft: 10,
            marginRight: 10,
            marginTop: 5,
            display: 'flex',
          }}
        >
          <div style={{ flex: 0.6 }}>
            <div
              style={{
                fontSize: 14,
                color: Colors.neutral_600,
                fontFamily: 'Nunito',
              }}
            >
              Registered Dental Hygienist
            </div>
          </div>
          <div style={{ flex: 0.4, alignItems: 'flex-end' }}>
            <div
              style={{
                fontSize: 14,
                color: Colors.neutral_600,
                fontFamily: 'Nunito',
              }}
            >
              {`${job.local_start_time.toUpperCase()} - ${job.local_end_time.toUpperCase()}`}
            </div>
          </div>
        </div>
        <div
          style={{
            flexDirection: 'row',
            flex: 1,
            marginLeft: 10,
            marginRight: 10,
            marginTop: 5,
            display: 'flex',
          }}
        >
          <div style={{ flex: 0.6 }}>
            <div
              style={{
                fontSize: 14,
                color: Colors.neutral_600,
                fontFamily: 'Nunito',
              }}
            >
              {job.local_date}
            </div>
          </div>
          <div style={{ flex: 0.4, alignItems: 'flex-end' }}>
            <div
              style={{
                fontSize: 14,
                color: Colors.neutral_600,
                fontFamily: 'Nunito',
              }}
            >
              {`$${item.rate.toFixed(2)}`}
            </div>
          </div>
        </div>

        <div
          style={{
            flexDirection: 'row',
            flex: 1,
            marginLeft: 10,
            marginRight: 10,
            marginTop: 5,
            display: 'flex',
          }}
        >
          <div style={{ flex: 0.6 }}>
            <div
              style={{
                fontSize: 12,
                color: item.status === 'declined' ? Colors.error_500 : Colors.neutral_500,
                fontFamily: 'Nunito',
                fontWeight: 'bold',
              }}
            >
              {item.status.toUpperCase()}
            </div>
          </div>
        </div>
        <div
          style={{
            marginTop: 10,
            height: 1,
            backgroundColor: Colors.neutral_100,
          }}
        />
      </div>
    </div>
  );

  if (!job || !expiredCounteOffers || expiredCounteOffers.length === 0) {
    return (
      <div className="component_container">
        <div className="floating_container">
          <div
            style={{
              fontSize: 16,
              color: '#546e7a',
              textAlign: 'center',
              marginTop: 20,
              fontFamily: 'Nunito',
            }}
          >
            There are no declined or expired offers for this job.
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="component_container">
      <div
        className="floating_container"
        style={{ flexDirection: 'column', justifyContent: 'flex-start' }}
      >
        <h1
          className="global_font f-dark"
          style={{
            fontSize: 24,
            marginTop: 50,
            textAlign: 'center',
            width: '100%',
            color: Colors.primary_500,
          }}
        >
          Expired Offers
        </h1>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {expiredCounteOffers.map((item) => renderRow({ item }))}
        </div>
        {/* <Spinner visible={isLoading} />
      <AlertShower error={error} /> */}
      </div>
    </div>
  );
}
