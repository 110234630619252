import { CSSProperties } from 'react';
import { TColorKeys, Colors } from './colors';

export const getResolvedStyles = (
  baseStyles: CSSProperties,
  rest: Record<string, any>,
): { styles: CSSProperties; htmlProps: Record<string, any> } => {
  const styles: CSSProperties = { ...baseStyles };
  const htmlProps: Record<string, any> = {};

  Object.entries(rest).forEach(([key, value]) => {
    if (key in document.body.style) {
      (styles as any)[key] = value; // Add valid CSS properties
    } else {
      htmlProps[key] = value; // Add non-CSS props
    }
  });

  return { styles, htmlProps };
};

export const getResolvedSpacing = (rest: Record<string, any>): CSSProperties => ({
  paddingRight: rest.paddingRight || rest.paddingHorizontal,
  paddingLeft: rest.paddingLeft || rest.paddingHorizontal,
  paddingTop: rest.paddingTop || rest.paddingVertical,
  paddingBottom: rest.paddingBottom || rest.paddingVertical,
  marginRight: rest.marginRight || rest.marginHorizontal,
  marginLeft: rest.marginLeft || rest.marginHorizontal,
  marginTop: rest.marginTop || rest.marginVertical,
  marginBottom: rest.marginBottom || rest.marginVertical,
});

export const getResolvedColor = (color: TColorKeys | string): string | undefined => {
  let resolvedColor: string | undefined;
  if (color) {
    resolvedColor =
      typeof color === 'string' && color in Colors ? Colors[color as TColorKeys] : color;
  }
  return resolvedColor;
};
