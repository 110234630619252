import { useWatch } from 'react-hook-form';

export const useParking = (control) => {
  const watchedValues = useWatch({
    control,
    name: ['isParkingFree', 'parkingCost', 'parkingCostType', 'parkingType'],
  });

  const formValues = {
    isParkingFree: watchedValues[0],
    parkingCost: watchedValues[1],
    parkingCostType: watchedValues[2],
    parkingType: watchedValues[3],
  };

  const parkingCostInformation =
    formValues.isParkingFree !== undefined &&
    (formValues.isParkingFree || (!!formValues.parkingCost && !!formValues.parkingCostType));

  const isValid = parkingCostInformation && !!formValues.parkingType;

  return {
    formValues,
    isValid,
    parkingCostInformation,
  };
};
