import { motion } from 'framer-motion';
import { ReactNode } from 'react';
import { EmptyScreen } from '../../../../commonComponents/EmptyScreen/EmptyScreen';
import { EmptyFavoritesListIcon } from '../../../../images/emptyScreenIcons';
import { EmptyFavoritesDescription } from './EmptyFavoritesDescription';

interface IProps {
  description?: string | ReactNode;
  title?: string;
  width?: number | string;
}

export const EmptyFavorites = ({ description, title, width = 540 }: IProps) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration: 0.5 }}
    style={{
      maxWidth: '827px',
      height: 600,
      display: 'flex',
      flexDirection: 'column',
      gap: 32,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: -20,
    }}
  >
    <EmptyScreen
      Icon={EmptyFavoritesListIcon}
      title={title || 'No favorite professionals yet!'}
      width={width}
      description={description || <EmptyFavoritesDescription />}
      descriptionStyle={{
        textAlign: 'left',
      }}
      buttonText={undefined}
      onButtonClick={undefined}
    />
  </motion.div>
);
