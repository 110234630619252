import { Box, Column, Divider, Label, RadioOption, Row, Text } from '../../../../commonComponents';
import { CollapsibleCard } from '../../../../commonComponents/CollapsibleCard';
import InputSelect from '../../../../commonComponents/InputSelect';
import { UserProfession } from '../../../../enums/UserProfession';
import { timePerPatientOptions } from '../../../../utils/Enum';
import {
  bookedOutPatientsOptions,
  numberOfOperatoriesOptions,
  numberOfPermanentStaffOptions,
} from '../../../registration/utils';
import { useFormContext } from '../../hooks/useFormContext';
import { CardTitle } from '../CardTitle';
import { PartnershipField } from './components/PartnershipField';
import { useOfficeInformation } from './hooks/useOfficeInformation';

export const OfficeInformationCard = ({ isOpen, onToggle }) => {
  const { setValue, control } = useFormContext();

  const { formValues, isValid, isTimerPerPatientValid, isProductivityOperatorsValid, hasWarning } =
    useOfficeInformation(control);

  return (
    <CollapsibleCard
      TitleComponent={() => (
        <CardTitle
          name="Office Information"
          showWarningIcon={!isOpen && (!isValid || hasWarning)}
        />
      )}
      isOpen={isOpen}
      onToggle={onToggle}
    >
      <Column gap={24} paddingHorizontal={80} marginBottom={40}>
        <Box>
          <Label
            text="Select the expected “time per patient” for each profession"
            required
            marginBottom={20}
            error={!isTimerPerPatientValid}
          />

          <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap={26}>
            <Column gap={2}>
              <Text marginBottom={10}>Dental Hygienist:</Text>
              <InputSelect
                value={formValues.timePerPatientRDH}
                setValue={(value) => setValue('timePerPatientRDH', value)}
                options={timePerPatientOptions?.map((item) => ({ title: item, value: item }))}
              />
            </Column>
            <Column gap={2}>
              <Text marginBottom={10}>Dental Assistant:</Text>
              <InputSelect
                value={formValues.timePerPatientDA}
                setValue={(value) => setValue('timePerPatientDA', value)}
                options={timePerPatientOptions?.map((item) => ({ title: item, value: item }))}
              />
            </Column>
            <Column gap={2}>
              <Text marginBottom={10}>Associate Dentist:</Text>
              <InputSelect
                value={formValues.timePerPatientDN}
                setValue={(value) => setValue('timePerPatientDN', value)}
                options={timePerPatientOptions?.map((item) => ({ title: item, value: item }))}
              />
            </Column>
          </Box>
        </Box>

        <Box>
          <Label
            text="Is assisted hygiene available at your office for temp hygienists?"
            marginBottom={20}
            required
            error={formValues.isAssistedHygiene === undefined}
          />
          <Row gap={32} marginLeft={40}>
            <RadioOption
              onClick={() => setValue('isAssistedHygiene', true)}
              text="Yes"
              checked={formValues.isAssistedHygiene}
            />
            <RadioOption
              onClick={() => setValue('isAssistedHygiene', false)}
              text="No"
              checked={formValues.isAssistedHygiene === false}
            />
          </Row>
        </Box>

        <Divider marginVertical={20} />

        <Box>
          <Label
            text="Number of operatories"
            required
            error={!isProductivityOperatorsValid || hasWarning}
          />
          <InputSelect
            value={formValues.productivity.operatories}
            setValue={(value) => setValue('productivity.operatories', value)}
            options={numberOfOperatoriesOptions.map((item) => ({ title: item, value: item }))}
          />
        </Box>

        <Box>
          <Label
            text="Number of permanent staff at this office:"
            required={false}
            marginBottom={16}
          />

          <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap={26}>
            {[
              { title: 'Dental Hygienists', value: UserProfession.RDH },
              { title: 'Dental Assistants', value: UserProfession.DA },
              { title: 'Doctors', value: UserProfession.DN },
            ].map((item) => (
              <Column key={item.value} gap={2}>
                <Text marginBottom={8}>{item.title}:</Text>
                <InputSelect
                  value={formValues.productivity.permanentStaff[item.value]}
                  setValue={(value) => setValue(`productivity.permanentStaff.${item.value}`, value)}
                  options={numberOfPermanentStaffOptions.map((item) => ({
                    title: item,
                    value: item,
                  }))}
                />
              </Column>
            ))}
          </Box>
        </Box>

        <Box>
          <Label text="How far are you currently booking out patients?" required={false} />
          <InputSelect
            value={formValues.productivity.bookedOutPatients}
            setValue={(value) => setValue('productivity.bookedOutPatients', value)}
            options={bookedOutPatientsOptions.map((item) => ({ title: item, value: item }))}
          />
        </Box>

        <Divider marginVertical={20} />

        <PartnershipField />
      </Column>
    </CollapsibleCard>
  );
};
