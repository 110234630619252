import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { POST_PP_JOB, getAllProfessionals, postPermanentHireJob } from '../../../actions';
import { createLoadingSelector } from '../../../api/selectors';
import BackButton from '../../../commonComponents/BackButton';
import CardSpinner from '../../../commonComponents/CardSpinner';
import PPView from './PPView';

export default function PPScreen() {
  const user = useSelector((state) => state.user.user);
  const loadingSelector = createLoadingSelector([POST_PP_JOB]);
  const isLoading = useSelector((state) => loadingSelector(state));
  const allProfessions = useSelector((state) => state.user.allProfessions);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!allProfessions || allProfessions?.length === 0) {
      dispatch(getAllProfessionals());
    }
  }, [dispatch, allProfessions]);

  if (isLoading) {
    return <CardSpinner />;
  }
  return (
    <div className="component_container">
      <div className="floating_container" style={{ flexDirection: 'column' }}>
        <div style={{ marginTop: 20, marginLeft: 30 }}>
          <BackButton />
        </div>

        <PPView
          btnSubmitClicked={(jobData) => dispatch(postPermanentHireJob({ jobData }))}
          user={user}
        />
      </div>
    </div>
  );
}
