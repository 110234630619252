import moment from 'moment';

export const popupIncidentType = {
  CONFIRMATION_SELECT_INCIDENT: 'CONFIRMATION_SELECT_INCIDENT',
  CONFIRMATION_INCIDENT: 'CONFIRMATION_INCIDENT',
  CONFIRMATION_TO_REPOST: 'CONFIRMATION_TO_REPOST',
  SUCCESS_REPORT: 'SUCCESS_REPORT',
  SUCCESS_REPOST: 'SUCCESS_REPOST',
};

export type TPopupIncidentType = (typeof popupIncidentType)[keyof typeof popupIncidentType];

export const incidentTypes = {
  noShow: 'no-show',
  walkedOut: 'walked-out',
} as const;

export type TIncidentType = (typeof incidentTypes)[keyof typeof incidentTypes];

export function generateHourlyOptions(startAt: Date, endAt: Date) {
  const options = [];
  const start = moment(startAt);

  // Round up to the next hour if minutes, seconds, or milliseconds > 0
  if (start.minutes() > 0 || start.seconds() > 0 || start.milliseconds() > 0) {
    start.add(1, 'hour').startOf('hour');
  } else {
    start.startOf('hour');
  }

  // Set end to be 1 hour before the original endAt
  const end = moment(endAt).startOf('hour').subtract(1, 'hour');

  for (let current = start.clone(); current.isSameOrBefore(end); current.add(1, 'hour')) {
    const formattedTime = current.format('h:mm a'); // Example: '10:00 am'
    options.push({
      title: formattedTime,
      value: current.toISOString(),
    });
  }

  return options;
}
