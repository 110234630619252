import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Column, Text } from '~/commonComponents';
import { editUser, saveWorkerClassification, showRegistrationPopup } from '../../../../actions';
import Button from '../../../../commonComponents/Button';
import Checkcircle from '../../../../commonComponents/Checkcircle';
import warningIcon from '../../../../images/warning-icon.svg';
import { Colors } from '../../../../themes/colors';
import ConfirmPopup from '../../../shared/ConfirmPopup';

export const WorkerClassificationSelection = ({ isRegistration }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { selectedChildOffice } = user;
  const userClassification = user?.workerClassification?.classificationType;
  const [workerClassification, setWorkerClassification] = useState(userClassification || '');
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [showNoneSelectedPopup, setShowNoneSelectedPopup] = useState(false);

  const handleConfirmButton = () => {
    dispatch(editUser({ data: { is_profile_complete: true } }));
    dispatch(showRegistrationPopup());
  };

  const handleSaveWorkerClassification = () => {
    dispatch(saveWorkerClassification(workerClassification));
    setShowConfirmationPopup(false);
  };

  const description = useMemo(() => {
    const isContracted = workerClassification === 'contractor';
    const isEmployed = workerClassification === 'employee';

    return (
      <Column>
        <Text color={Colors.neutral_500}>
          Worker classification is an important choice for using GoTu. Please confirm that your
          selection is {isContracted ? 'contracted' : 'employed'} professionals at{' '}
          {selectedChildOffice?.office_name}.
        </Text>
        {isEmployed && (
          <Text color={Colors.neutral_500} marginTop={20}>
            Please note: There is an additional fee associated with the employee option (20%
            professional pay).
          </Text>
        )}
      </Column>
    );
  }, [selectedChildOffice?.office_name, workerClassification]);

  const Option = ({ title, value }) => (
    <button
      type="button"
      style={{
        all: 'unset',
        display: 'flex',
        cursor: 'pointer',
        marginBottom: 22,
        alignItems: 'center',
      }}
      onClick={() => setWorkerClassification(value)}
    >
      <Checkcircle checked={workerClassification === value} />

      <p className="global_font f-dark" style={{ margin: '0px 0px 0px 22px' }}>
        {title}
      </p>
    </button>
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <span
        style={{
          fontFamily: 'Nunito',
          fontSize: 22,
          fontWeight: 700,
          textAlign: 'center',
          color: Colors.primary_500,
          marginTop: 20,
          marginBottom: 20,
        }}
      >
        Worker Type Selection
      </span>

      <p
        style={{
          marginBottom: 25,
          fontFamily: 'Nunito',
          fontSize: 18,
          fontWeight: 700,
          textAlign: 'center',
          color: Colors.neutral_600,
        }}
      >
        Please select a Worker Type for this Office:
      </p>

      <div>
        <Option title="Contractor option" value="contractor" />
        <Option title="Employee option" value="employee" />
        {isRegistration && <Option title="I'm not sure. I’ll decide later" value="none" />}
      </div>

      <span
        style={{
          fontFamily: 'Nunito',
          fontSize: 16,
          fontWeight: 400,
          textAlign: 'center',
          fontStyle: 'italic',
          color: Colors.neutral_600,
          marginBottom: 40,
        }}
      >
        Disclaimer: GoTu is not providing legal or tax advice. It is up to the office’s discretion
        to determine
        <br />
        which worker type best suits their needs.
      </span>

      <Button
        type="button"
        disabled={!workerClassification}
        onClick={() => {
          if (workerClassification === 'none') {
            setShowNoneSelectedPopup(true);
          } else {
            setShowConfirmationPopup(true);
          }
        }}
        text="Continue"
      />

      {showConfirmationPopup && (
        <ConfirmPopup
          Icon={() => <img src={warningIcon} style={{ marginBottom: 20 }} alt="warning_icon" />}
          title="Please Confirm"
          description={description}
          descriptionStyle={{ marginBottom: 45 }}
          containerStyle={{ paddingTop: 45, paddingBottom: 50 }}
          leftButtonText="Go Back"
          leftButtonStyle={{ backgroundColor: Colors.secondary_500 }}
          leftButtonAction={() => setShowConfirmationPopup(false)}
          rightButtonText="Confirm"
          rightButtonAction={handleSaveWorkerClassification}
        />
      )}

      {showNoneSelectedPopup && (
        <ConfirmPopup
          closePopup={() => setShowNoneSelectedPopup(false)}
          Icon={() => <img src={warningIcon} style={{ marginBottom: 20 }} alt="warning_icon" />}
          title="Worker Type Not Selected"
          description="Please note, this office will not be able to post shifts until a worker type is selected. You can submit your selection at a later time by navigating to Account > Worker Type."
          descriptionStyle={{ marginBottom: 45 }}
          leftButtonText="Go Back"
          leftButtonStyle={{
            backgroundColor: Colors.secondary_500,
          }}
          leftButtonAction={() => setShowNoneSelectedPopup(false)}
          containerStyle={{ paddingTop: 45, paddingBottom: 50 }}
          rightButtonText="Confirm"
          rightButtonAction={handleConfirmButton}
        />
      )}
    </div>
  );
};
