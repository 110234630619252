export const candidateSortOptions = {
  previouslyRated: 'previously-rated',
  skillsMatch: 'skills-match',
  localFavorites: 'local-favorites',
};

export const candidateSortEventMapping = {
  [candidateSortOptions.previouslyRated]: 'Confirm Candidate - Sort By Previously Rated Submitted',
  [candidateSortOptions.skillsMatch]: 'Confirm Candidate - Sort By Skill Match Submitted',
  [candidateSortOptions.localFavorites]: 'Confirm Candidate - Sort By Local Favorites Submitted',
};

export const defaultFilters = {
  sort: candidateSortOptions.localFavorites,
  page: 1,
  totalPages: 1,
  limit: 5,
};
