import { parsePhoneNumber } from 'libphonenumber-js';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FORGOT_PASSWORD_SEND_CODE_ACTION, forgotPasswordSendCode } from '../../../../actions';
import { createLoadingSelector } from '../../../../api/selectors';
import { Column, RadioOption } from '../../../../commonComponents';
import Button from '../../../../commonComponents/Button';
import { validateEmail, validatePhone } from '../../../../commonComponents/CommonFunctions';
import usePhoneNumber from '../../../../hooks/phoneNumber';
import { Colors } from '../../../../themes/colors';
import { capitalizeEveryFirstLetter } from '../../../../utils/String';
import InputError from '../../components/InputError';
import { ContentLayout } from '../ContentLayout';
import { forgotPasswordOptions } from '../utils';

const getInputError = (method, value) => {
  switch (method) {
    case forgotPasswordOptions.email:
      return {
        error: !validateEmail(value) && value.length > 0,
        message: 'Please enter a valid email',
      };
    case forgotPasswordOptions.phone:
      return {
        error: !validatePhone(value) && value.length > 0,
        message: 'Please enter a valid phone number',
      };
    default:
      return { error: false, message: '' };
  }
};

export const ChooseVerificationMethodScreen = () => {
  const dispatch = useDispatch();
  const [verificationMethod, setVerificationMethod] = useState(forgotPasswordOptions.email);
  const loadingSelector = createLoadingSelector([FORGOT_PASSWORD_SEND_CODE_ACTION]);
  const isLoading = useSelector((state) => loadingSelector(state));
  const [email, setEmail] = useState('');
  const [phone, setPhone] = usePhoneNumber('');
  const [submitError, setSubmitError] = useState(false);
  const isEmailMethod = verificationMethod === forgotPasswordOptions.email;
  const { error, message: errorMessage } = getInputError(
    verificationMethod,
    isEmailMethod ? email : phone,
  );
  const isButtonDisabled = !(verificationMethod && (email || phone));

  const handleSubmit = (e) => {
    e.preventDefault();
    if (error) {
      setSubmitError(true);
      return;
    }
    if (!isButtonDisabled && !error) {
      dispatch(
        forgotPasswordSendCode({
          value: isEmailMethod ? email : parsePhoneNumber(phone, 'US')?.number,
          method: isEmailMethod ? 'email' : 'phone',
        }),
      );
    }
  };

  useEffect(() => {
    setSubmitError(false);
  }, [email, phone, verificationMethod, setSubmitError]);

  return (
    <ContentLayout>
      <h1 style={{ fontFamily: 'Nunito', color: Colors.neutral_600 }}>Forgot Your Password?</h1>

      <h4
        style={{
          fontFamily: 'Nunito',
          color: Colors.neutral_500,
          margin: 0,
          marginTop: 20,
          marginBottom: 40,
          fontWeight: 400,
          textAlign: 'center',
          fontSize: 16,
        }}
      >
        We will send you a recovery code to reset your password.
        <br />
        Please select how you would like to receive your code.
      </h4>

      <form
        id="chooseResetMethodForm"
        style={{
          width: '100%',
          padding: 0,
          margin: 0,
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        onSubmit={handleSubmit}
      >
        <div
          style={{
            width: 540,
            display: 'flex',
            flexDirection: 'column',
            gap: 20,
          }}
        >
          {[forgotPasswordOptions.email, forgotPasswordOptions.phone].map((method) => {
            const isSelected = verificationMethod === method;

            return (
              <Column gap={8}>
                <RadioOption
                  checked={isSelected}
                  text={capitalizeEveryFirstLetter(method)}
                  onClick={() => setVerificationMethod(method)}
                />
                {isSelected && (
                  <>
                    <input
                      id={method}
                      className="input-field"
                      type={isEmailMethod ? 'email' : 'text'}
                      value={isEmailMethod ? email : phone}
                      style={{
                        width: 432,
                        marginLeft: 12,
                      }}
                      placeholder={`Enter your ${method}`}
                      onChange={(e) =>
                        isEmailMethod ? setEmail(e.target.value) : setPhone(e.target.value)
                      }
                    />
                    <InputError
                      textStyle={{
                        width: 'fit-content',
                        marginLeft: 12,
                        marginTop: -10,
                      }}
                      text={errorMessage}
                      showError={submitError}
                    />
                  </>
                )}
              </Column>
            );
          })}
        </div>
        <Button disabled={isButtonDisabled} type="submit" text="Send Code" isLoading={isLoading} />
      </form>
    </ContentLayout>
  );
};
