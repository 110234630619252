import { apiMethodsConst } from '~/services/methods';
import { IAlertState, TAlertAction } from './interface';

const initialState: IAlertState = {
  allAlerts: [],
  isActiveRequest: false,
  isFinalPage: false,
  searchParams: {
    limit: 10,
    page: 1,
  },
  totalAlerts: 0,
  totalReminders: 0,
};

export default (state: IAlertState = initialState, action: TAlertAction): IAlertState => {
  const { type } = action;

  switch (type) {
    case apiMethodsConst.DELETE_ALERTS_REQUEST: {
      return {
        ...state,
        allAlerts: [],
        totalAlerts: 0,
      };
    }
    case apiMethodsConst.DELETE_ONE_ALERT_REQUEST: {
      const index = state.allAlerts.findIndex((alert) => alert.id === action.payload);
      if (index === -1) {
        return state;
      }
      return {
        ...state,
        allAlerts: [...state.allAlerts.slice(0, index), ...state.allAlerts.slice(index + 1)],
        totalAlerts: state.totalAlerts - 1,
      };
    }
    case apiMethodsConst.GET_ALERTS_REQUEST: {
      const { total, page, response, limit } = action.payload;
      const mergedOffers = [...state.allAlerts, ...response];

      return {
        ...state,
        allAlerts: page === 1 ? response : mergedOffers,
        isFinalPage:
          response.length === 0 ||
          response.length < (limit || state.searchParams.limit) ||
          response.length === state.totalAlerts,
        totalAlerts: total,
        isActiveRequest: false,
      };
    }
    case 'GET_ALL_ALERTS_ACTION_REQUEST': {
      return {
        ...state,
        isActiveRequest: true,
        searchParams: action.payload,
      };
    }
    case 'SET_TOTAL_REMINDERS_ACTION': {
      return {
        ...state,
        totalReminders: action.payload || 0,
      };
    }
    default: {
      return { ...state };
    }
  }
};
