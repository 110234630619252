import React, { useMemo, useState } from 'react';
import '../../themes/badge.scss';

import { Colors } from '../../themes/colors';
import ConfirmPopup from '../shared/ConfirmPopup';
import { getPrioritizedBadges, reorderBadges } from './utils';

const MultiProfessionalBadges = ({
  badges = [],
  disabled,
  width,
  height,
  isPreview,
  showPriorityOnly,
  onClickBadge,
}) => {
  const [selectedBadge, setSelectedBadge] = useState(null);
  const displayedBadges = useMemo(
    () =>
      reorderBadges(showPriorityOnly ? getPrioritizedBadges({ badges }) : badges, {
        inverted: false,
      }),
    [badges, showPriorityOnly],
  );

  const renderPreviewBadge = (badge) => {
    const showBadgeCount = badge.badgeCount && badge.badgeCount > 0;

    return (
      <div
        className="badge"
        style={{
          position: 'relative',
          marginBlockStart: 11,
          paddingBlockStart: 4,
          paddingBlockEnd: 15,
          paddingInline: showBadgeCount ? 6 : 0,
          backgroundColor: showBadgeCount ? Colors.white : 'transparent',
          border: showBadgeCount ? `1px solid ${Colors.neutral_150}` : 'none',
          borderRadius: 5,
        }}
      >
        <img
          src={badge.imageUrl}
          alt={badge.label}
          style={{ width: width || 26, height: height || 26 }}
        />
        {showBadgeCount && (
          <div
            style={{
              fontFamily: 'Nunito',
              fontSize: 14,
              color: Colors.neutral_600,
              position: 'absolute',
              bottom: 0,
              left: '50%',
              transform: 'translateX(-50%)',
              textAlign: 'center',
            }}
          >
            {badge.badgeCount}
          </div>
        )}
      </div>
    );
  };

  const renderBadge = (badge) => {
    const showBadgeCount = badge.badgeCount && badge.badgeCount > 0;

    return (
      <>
        <div style={{ position: 'relative' }}>
          <img
            className="badge"
            src={badge.imageUrl}
            alt={badge.label}
            style={{ width: width || 50, height: height || 50 }}
          />
          {showBadgeCount && (
            <div
              style={{
                position: 'absolute',
                bottom: 0,
                right: -10,
                paddingInline: 6,
                backgroundColor: Colors.primary_700,
                borderRadius: 27,
                fontFamily: 'Nunito',
                fontSize: 13,
                color: Colors.white,
              }}
            >
              {badge.badgeCount}
            </div>
          )}
        </div>
        <div
          style={{
            fontFamily: 'Nunito',
            fontSize: 14,
            color: Colors.neutral_600,
            textAlign: 'center',
            paddingTop: 6,
            width: 73,
          }}
        >
          {badge.label}
        </div>
      </>
    );
  };

  if (!badges.length) {
    return null;
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          marginLeft: 10,
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            gap: 10,
          }}
        >
          {displayedBadges.map((badge) => (
            <button
              type="button"
              key={badge?.type}
              className={`badge-button ${disabled ? 'disabled' : ''}`}
              disabled={disabled}
              onClick={() => {
                setSelectedBadge(badge);
                onClickBadge?.(badge);
              }}
              style={{
                all: 'unset',
                display: 'inline-flex',
                flexDirection: 'column',
                alignItems: 'center',
                cursor: !disabled ? 'pointer' : 'auto',
              }}
            >
              {isPreview ? renderPreviewBadge(badge) : renderBadge(badge)}
            </button>
          ))}
        </div>
      </div>

      {selectedBadge && (
        <ConfirmPopup
          rightButtonAction={() => setSelectedBadge(null)}
          rightButtonText="Got it!"
          title={
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: 18,
              }}
            >
              <div className="global_font f-dark f-18 mr-5" style={{ marginRight: 5 }}>
                {selectedBadge?.popupTitleDnt}
              </div>
              {selectedBadge?.popupTitleIcon && (
                <img
                  src={selectedBadge?.popupTitleIcon}
                  alt="icon"
                  style={{ width: 22, height: 22 }}
                />
              )}
            </div>
          }
          titleStyle={{ marginBottom: 5 }}
          description={selectedBadge?.popupDescriptionDnt}
          Icon={() =>
            selectedBadge?.popupImageWebDnt ? (
              <img src={selectedBadge?.popupImageWebDnt} alt="popup" style={{ width: '50%' }} />
            ) : null
          }
        />
      )}
    </>
  );
};

export default MultiProfessionalBadges;
