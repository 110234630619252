import { useState, useEffect } from 'react';

export const useFixedAlignment = (parentRef) => {
  const [leftOffset, setLeftOffset] = useState(0);

  useEffect(() => {
    const updateOffset = () => {
      if (parentRef.current) {
        const rect = parentRef.current.getBoundingClientRect();
        setLeftOffset(rect.left);
      }
    };

    updateOffset();

    window.addEventListener('resize', updateOffset);
    window.addEventListener('scroll', updateOffset, true);

    return () => {
      window.removeEventListener('resize', updateOffset);
      window.removeEventListener('scroll', updateOffset, true);
    };
  }, [parentRef]);

  return leftOffset;
};
