import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DefaultGeneralSpecialty } from '../../../constants';
import useLoading from '../../../hooks/useLoading';
import {
  clearJobCandidates,
  FETCH_JOB_CANDIDATES_ACTION,
  fetchJobCandidates,
  showJobCandidatesForPage,
} from '../../../actions';
import { candidateSortOptions, defaultFilters } from '../utils';
import { trackEvent } from '../../../api/analytics';

export const useJobCandidates = () => {
  const dispatch = useDispatch();

  const { job, visibleCandidates, candidatesFilters, bestPickCandidates } = useSelector(
    (state) => state.job,
  );

  const { page, totalPages } = candidatesFilters || {};

  const { isLoading } = useLoading([FETCH_JOB_CANDIDATES_ACTION]);

  const fetchCandidates = useCallback(
    async (jobId, specialty) => {
      const isGeneralDentistryJob = specialty === DefaultGeneralSpecialty;
      await dispatch(clearJobCandidates());
      dispatch(
        fetchJobCandidates({
          jobId,
          filters: {
            ...defaultFilters,
            sort: !isGeneralDentistryJob
              ? candidateSortOptions.skillsMatch
              : candidateSortOptions.localFavorites,
          },
          resetBestPick: true,
        }),
      );
    },
    [dispatch],
  );

  const reloadCandidates = useCallback(
    (jobId) => {
      dispatch(fetchJobCandidates({ jobId, filters: candidatesFilters, resetBestPick: false }));
    },
    [dispatch, candidatesFilters],
  );

  const onFilterChange = useCallback(
    async (filterValues) => {
      if (job) {
        await dispatch(clearJobCandidates());
        const filters = { ...candidatesFilters, ...filterValues, page: 1 };
        dispatch(fetchJobCandidates({ jobId: job.id, filters, resetBestPick: true }));
      }
    },
    [dispatch, job, candidatesFilters],
  );

  const loadPrevPageCandidates = useCallback(() => {
    trackEvent('Confirm Candidate - Left Arrow Clicked', {
      hours: job?.totalHours,
      shiftId: job?.id,
      shiftDate: job?.job_datetime,
      shiftSpecialty: job?.specialty,
      profession: job?.profession,
      procedures: job?.procedures,
      arrivalTime: job?.local_start_time,
      departureTime: job?.local_end_time,
      breakTime: job?.lunch_break_time,
      autoConfirm: job?.autoFill,
      autoFillFavorite: job?.autofillOptions?.favorite,
      autoFillHighlyRated: job?.autofillOptions?.highlyRated,
      softwares: job?.softwares,
      assistedHygiene: job?.isAssistedHygiene,
      rate: job?.rate,
      tpp: job?.amount,
      candidateCount: job?.multipleCounters?.length,
    });
    dispatch(showJobCandidatesForPage(page - 1));
  }, [dispatch, page]);

  const loadNextPageCandidates = useCallback(() => {
    trackEvent('Confirm Candidate - Right Arrow Clicked', {
      hours: job?.totalHours,
      shiftId: job?.id,
      shiftDate: job?.job_datetime,
      shiftSpecialty: job?.specialty,
      profession: job?.profession,
      procedures: job?.procedures,
      arrivalTime: job?.local_start_time,
      departureTime: job?.local_end_time,
      breakTime: job?.lunch_break_time,
      autoConfirm: job?.autoFill,
      autoFillFavorite: job?.autofillOptions?.favorite,
      autoFillHighlyRated: job?.autofillOptions?.highlyRated,
      softwares: job?.softwares,
      assistedHygiene: job?.isAssistedHygiene,
      rate: job?.rate,
      tpp: job?.amount,
      candidateCount: job?.multipleCounters?.length,
    });
    dispatch(showJobCandidatesForPage(page + 1));
  }, [dispatch, page]);

  const isFirstPage = page === 1;
  const isLastPage = page === totalPages;

  return {
    fetchCandidates,
    reloadCandidates,
    onFilterChange,
    loadPrevPageCandidates,
    loadNextPageCandidates,
    candidates: visibleCandidates,
    filters: candidatesFilters,
    bestCandidates: bestPickCandidates,
    isLoading,
    isFirstPage,
    isLastPage,
  };
};
