export const Colors = {
  white: '#FFFFFF',
  black: '#000000',
  neutral_50: '#F4F8FA',
  neutral_70: '#F4F4F4',
  neutral_100: '#E9F1F4',
  neutral_150: '#E7EAEB',
  neutral_200: '#CFD6DA',
  neutral_300: '#AAB4B9',
  neutral_400: '#8E9BA1',
  neutral_500: '#697A82',
  neutral_600: '#435963',
  neutral_700: '#3A4C54',
  neutral_800: '#333F45',
  neutral_900: '#1E282D',
  primary_50: '#ECF9FE',
  primary_100: '#DFEBEE',
  primary_200: '#BFD7DD',
  primary_300: '#83B2BE',
  primary_400: '#5999A8',
  primary_500: '#307F92',
  primary_700: '#1C6B93',
  secondary_25: '#F0FAFE',
  secondary_50: '#E8FFFF',
  secondary_100: '#B4E5E7',
  secondary_200: '#A7EBED',
  secondary_300: '#7BDEE5',
  secondary_400: '#51D2DB',
  secondary_500: '#4FC0CF',
  secondary_700: '#328A94',
  secondary_800: '#2F6E79',
  favorites_50: '#EEEBF2',
  favorites_200: '#D3BDF0',
  favorites_300: '#C3A4EC',
  favorites_400: '#9D70D8',
  favorites_500: '#8261AB',
  favorites_700: '#664692',
  success_50: '#E7F6ED',
  success_100: '#DAF2E3',
  success_200: '#C0E3B6',
  success_300: '#8BE365',
  success_400: '#7ED957',
  success_500: '#6FC04D',
  success_600: '#3AAC09',
  success_700: '#6D9200',
  warning_30: '#FEFBF5',
  warning_50: '#FFE6C1',
  warning_300: '#FFD336',
  warning_400: '#F7C100',
  warning_500: '#F29E3B',
  warning_600: '#DE963E',
  warning_700: '#EA7E00',
  error_50: '#FFEBEB',
  error_200: '#F4C6CC',
  error_300: '#ECA0AA',
  error_400: '#DE4C5E',
  error_450: '#E53935',
  error_500: '#D00019',
  error_700: '#940012',
};

export type TColorKeys = keyof typeof Colors;
