import { Center, Text } from '~/commonComponents';
import AnimatedCheckCircle from '~/components/shared/AnimatedCheckCircle';
import { ConfirmPopupContent } from '~/components/shared/ConfirmPopupContent';
import { incidentTypes } from '../utils/incidentPopupUtil';
import browserHistory from '~/history';

export const SuccessReport = ({ data, onClose, incidentType }) => (
  <ConfirmPopupContent
    Icon={() => (
      <Center marginBottom={20}>
        <AnimatedCheckCircle />
      </Center>
    )}
    title={`${
      incidentType === incidentTypes.noShow ? 'No-Show' : 'Walked Out'
    } Successfully Reported`}
    description={
      <Text textAlign="center" marginTop={30} marginBottom={-40}>
        Our Customer Success team will reach out to <strong>{data?.profName}</strong> immediately
        and provide an update on their status.
        <br />
        <br />
        We apologize for any inconveniences that this may have caused. We care about our customer’s
        satisfaction, and will take the appropriate action to make sure incidents like these are few
        and far between on GoTu.
      </Text>
    }
    leftButtonStyle={{ width: 180 }}
    leftButtonAction={() => {
      onClose();
      browserHistory.push('/dashboard/help');
    }}
    leftButtonText="Contact Support"
    rightButtonStyle={{ width: 180 }}
    rightButtonAction={onClose}
    rightButtonText="Return"
  />
);
