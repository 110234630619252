import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { trackEvent } from '../../../../../api/analytics';
import InputSelect from '../../../../../commonComponents/InputSelect';
import { Row, Text } from '../../../../../commonComponents';
import MailIcon from '../../../../../images/mail-solid.svg';
import { Colors } from '../../../../../themes/colors';
import { RadioOption } from '../../../../registration/components/RadioOption';
import Tooltip from '../../../../Tooltip';
import { CollapsibleRow } from '../CollapsibleRow/CollapsibleRow';
import { Title } from '../CollapsibleRow/Title';
import { Value } from '../CollapsibleRow/Value';

const OPTIONS = {
  FAVORITE_PROFESSIONALS: 'Favorite Professionals',
  HIGHLY_RATED: 'Your Highly-Rated',
  RECENT_PROFESSIONALS: 'Recent Professionals',
};

export const InviteRow = ({ defaultProfessionalId, onToggle, isOpen, onConfirm }) => {
  const [professional, setProfessional] = useState({
    id: '',
    name: '',
  });
  const [selectedOption, setSelectedOption] = useState('');
  const favoriteProfessionals = useSelector((state) => state.user.favoriteProfessionals);
  const recentProfessionals = useSelector((state) => state.user.recentProfessionals);
  const { data: highlyRatedProfessionals } = useSelector(
    (state) => state.user.highlyRatedProfessionals,
  );

  const favoriteProfessionalsMapped = favoriteProfessionals?.map((item) => ({
    title: item?.fullname,
    value: item?.id,
  }));

  const recentProfessionalsMapped = recentProfessionals?.map((item) => ({
    title: item?.professional?.full_name,
    value: item?.professional?.id,
  }));

  const highlyRatedProfessionalsMapped = highlyRatedProfessionals?.map((item) => ({
    title: item?.professional?.fullName,
    value: item?.professional?.id,
  }));

  const showSelectComponent =
    !selectedOption ||
    (selectedOption === OPTIONS.FAVORITE_PROFESSIONALS &&
      favoriteProfessionalsMapped?.length > 0) ||
    (selectedOption === OPTIONS.RECENT_PROFESSIONALS && recentProfessionalsMapped?.length > 0) ||
    (selectedOption === OPTIONS.HIGHLY_RATED && highlyRatedProfessionalsMapped?.length > 0);

  const getOptions = () => {
    const optionsMap = {
      [OPTIONS.FAVORITE_PROFESSIONALS]: favoriteProfessionalsMapped,
      [OPTIONS.RECENT_PROFESSIONALS]: recentProfessionalsMapped,
      [OPTIONS.HIGHLY_RATED]: highlyRatedProfessionalsMapped,
    };

    return optionsMap[selectedOption] || [];
  };

  const handleOptionChange = (option) => {
    const selectedProfessional = {
      id: '',
      name: '',
    };
    setSelectedOption(option);
    setProfessional(selectedProfessional);
    onConfirm(selectedProfessional);
  };

  const handleValueChange = (value) => {
    const selectedProfessional = {
      id: value,
      name: [
        ...favoriteProfessionalsMapped,
        ...highlyRatedProfessionalsMapped,
        ...recentProfessionalsMapped,
      ].find((item) => item.value === value)?.title,
    };

    trackEvent('Re-Book Request - Invited Pro selected', {
      professionalId: selectedProfessional?.id,
      professionType: selectedOption,
    });
    setProfessional(selectedProfessional);
    onConfirm(selectedProfessional);
    if (isOpen) {
      onToggle();
    }
  };

  useEffect(() => {
    if (defaultProfessionalId && defaultProfessionalId !== professional?.id) {
      const professionalFromFavorites = favoriteProfessionalsMapped.find(
        (prof) => prof.value === defaultProfessionalId,
      );
      const professionalFromRecent = recentProfessionalsMapped.find(
        (prof) => prof.value === defaultProfessionalId,
      );
      const professionalFromHighlyRated = highlyRatedProfessionalsMapped.find(
        (prof) => prof.value === defaultProfessionalId,
      );

      let selectedOption = '';
      const selectedProfessional = {
        id: '',
        name: '',
      };
      if (professionalFromFavorites) {
        selectedOption = OPTIONS.FAVORITE_PROFESSIONALS;
        selectedProfessional.id = professionalFromFavorites?.value;
        selectedProfessional.name = professionalFromFavorites?.title;
      } else if (professionalFromHighlyRated) {
        selectedOption = OPTIONS.HIGHLY_RATED;
        selectedProfessional.id = professionalFromHighlyRated?.value;
        selectedProfessional.name = professionalFromHighlyRated?.title;
      } else if (professionalFromRecent) {
        selectedOption = OPTIONS.RECENT_PROFESSIONALS;
        selectedProfessional.id = professionalFromRecent?.value;
        selectedProfessional.name = professionalFromRecent?.title;
      }
      setSelectedOption(selectedOption);
      handleValueChange(selectedProfessional.id);
    }
  }, [
    defaultProfessionalId,
    favoriteProfessionalsMapped,
    recentProfessionalsMapped,
    highlyRatedProfessionalsMapped,
  ]);

  const getEmptyMessage = () => {
    const messages = {
      [OPTIONS.FAVORITE_PROFESSIONALS]: 'No Favorite Professionals Yet',
      [OPTIONS.RECENT_PROFESSIONALS]: 'No Recent Professionals Yet',
      [OPTIONS.HIGHLY_RATED]: 'No Highly-Rated Yet',
    };

    return messages[selectedOption] || '';
  };

  return (
    <CollapsibleRow
      isOpen={isOpen}
      onToggle={onToggle}
      TitleComponent={() => (
        <Row
          style={{
            alignItems: 'center',
          }}
        >
          <Title text="Invite:" />

          <Text color={Colors.neutral_500} marginLeft={4}>
            (Optional)
          </Text>

          <div
            style={{
              marginLeft: 4,
              position: 'relative',
              top: '2px',
            }}
          >
            <Tooltip content="Please note, invites will not be delivered to professionals on requested dates in which they are unavailable." />
          </div>
        </Row>
      )}
      ValueComponent={() => (
        <Value
          customStyles={{
            color: isOpen || !professional?.id ? Colors.neutral_300 : Colors.neutral_600,
          }}
          text={!isOpen && professional?.id ? professional?.name : 'Professional'}
        />
      )}
      icon={MailIcon}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 32,
        }}
      >
        <Text color={Colors.neutral_500} fontStyle="italic" textAlign="center" paddingInline={48}>
          Get re-booked with 1 professional you enjoyed working with. These shifts will also be
          posted on GoTu’s public marketplace.
        </Text>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            gap: 40,
            width: '100%',
          }}
        >
          <div>
            {showSelectComponent ? (
              <InputSelect
                value={professional?.id}
                setValue={handleValueChange}
                options={getOptions()}
                disabled={selectedOption === ''}
                showDropdownIcon={false}
              />
            ) : (
              <input
                className="input-field"
                placeholder={getEmptyMessage()}
                disabled
                style={{ width: 217, height: 42 }}
              />
            )}
          </div>
          <div style={{ marginTop: 12 }}>
            {[
              OPTIONS.FAVORITE_PROFESSIONALS,
              OPTIONS.HIGHLY_RATED,
              OPTIONS.RECENT_PROFESSIONALS,
            ].map((option) => (
              <div style={{ marginBottom: 10 }}>
                <RadioOption
                  key={option}
                  onClick={() => handleOptionChange(option)}
                  text={option}
                  checked={selectedOption === option}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </CollapsibleRow>
  );
};
