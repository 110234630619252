import { Colors } from '../../themes/colors';
import { CandidateCard, DecisionCard, InterviewCard } from './CandidateCards/Cards';

const style = {
  blueAreaWithCards: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 25,
    backgroundColor: '#E9F1F4',
    minHeight: 300,
    padding: 20,
    height: '100%',
  },
  transparentAreaWithCards: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 25,
    minHeight: 300,
    padding: 20,
  },
};

export const renderEmptyState = () => (
  <p
    style={{
      fontFamily: 'Nunito',
      fontWeight: 'bold',
      fontSize: 16,
      color: Colors.primary_500,
      textAlign: 'center',
    }}
  >
    No candidates
  </p>
);

export const renderCandidateStage = ({ candidateApplication, openCandidateClicked }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      width: '30%',
    }}
  >
    <div
      style={{
        borderRadius: 14,
        boxShadow: '0px 3px 3px rgba(0, 0, 0, 0.3)',
        backgroundColor: Colors.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 10,
      }}
    >
      <p
        style={{
          fontFamily: 'Nunito',
          fontWeight: 'bold',
          fontSize: 16,
          color: Colors.primary_500,
        }}
      >
        Candidate Stage
      </p>
    </div>
    <div style={style.blueAreaWithCards}>
      {candidateApplication.length === 0
        ? renderEmptyState()
        : candidateApplication.map((candidate) => (
          <CandidateCard
              key={candidate.id}
              professional={candidate.professional}
              openCandidateClicked={() => openCandidateClicked(candidate)}
            />
          ))}
    </div>
  </div>
);

export const renderInterviewStage = ({ interviewApplication, showInterviewClicked }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      width: '30%',
    }}
  >
    <div
      style={{
        borderRadius: 14,
        boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.6)',
        backgroundColor: Colors.primary_500,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 10,
      }}
    >
      <p
        style={{
          fontFamily: 'Nunito',
          fontWeight: 'bold',
          fontSize: 16,
          color: Colors.white,
        }}
      >
        Interview Stage
      </p>
    </div>
    <div style={style.blueAreaWithCards}>
      {interviewApplication.length === 0
        ? renderEmptyState()
        : interviewApplication.map((candidate) => (
          <InterviewCard
              key={candidate.id}
              professional={candidate.professional}
              interviewInfo={candidate.interview}
              showInterviewClicked={() => showInterviewClicked(candidate)}
            />
          ))}
    </div>
  </div>
);

export const renderDecisionStage = ({ decisiopnApplication, showDecisionPopupClicked }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      width: '30%',
    }}
  >
    <div
      style={{
        borderRadius: 14,
        boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.6)',
        backgroundColor: '#307F93',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 10,
      }}
    >
      <p
        style={{
          fontFamily: 'Nunito',
          fontWeight: 'bold',
          fontSize: 16,
          color: Colors.white,
        }}
      >
        Decision Stage
      </p>
    </div>
    <div style={style.blueAreaWithCards}>
      {decisiopnApplication.length === 0
        ? renderEmptyState()
        : decisiopnApplication.map((candidate) => (
          <DecisionCard
              key={candidate.id}
              professional={candidate.professional}
              interviewInfo={candidate.interview}
              showDecisionPoup={() => showDecisionPopupClicked(candidate)}
            />
          ))}
    </div>
  </div>
);

export const renderDeclinedStage = ({ declinedApplication, openCandidateClicked }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      width: '30%',
    }}
  >
    <p
      style={{
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: 20,
        color: Colors.neutral_800,
        textAlign: 'center',
      }}
    >
      Declined Candidates
    </p>
    <div style={style.transparentAreaWithCards}>
      {declinedApplication.length === 0
        ? renderEmptyState()
        : declinedApplication.map((candidate) => (
          <CandidateCard
              key={candidate.id}
              professional={candidate.professional}
              openCandidateClicked={() => openCandidateClicked(candidate)}
              isDeclined
            />
          ))}
    </div>
  </div>
);

export const renderRejectedStage = ({ openRejectedPopupClicked, rejectedApplication }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      width: '30%',
    }}
  >
    <p
      style={{
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: 20,
        color: Colors.neutral_800,
        textAlign: 'center',
      }}
    >
      Rejected Candidates
    </p>
    <div style={style.transparentAreaWithCards}>
      {rejectedApplication.length === 0
        ? renderEmptyState()
        : rejectedApplication.map((candidate) => (
          <CandidateCard
              key={candidate.id}
              professional={candidate.professional}
              openCandidateClicked={() => openRejectedPopupClicked(candidate)}
              isDeclined
            />
          ))}
    </div>
  </div>
);
