import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOfficeReviewInfo } from '../../../actions';
import { getEnableTappableOfficeRatting } from '../../../growthbook';
import { useUserSelector } from '../../../hooks/useUserSelector';

export const useOfficeRating = () => {
  const dispatch = useDispatch();

  const { id } = useUserSelector();
  const { rating } = useSelector((state) => state.officeRating);

  const isParentOffice = useSelector(
    (state) =>
      (state.user?.selectedChildOffice?.childOffices || state.user?.childOffices)?.length > 1,
  );
  const isOfficeRatingTappable = useRef(getEnableTappableOfficeRatting()).current;
  const isShowRating = !isParentOffice && isOfficeRatingTappable;

  useEffect(() => {
    if (isShowRating) {
      dispatch(getOfficeReviewInfo({ userId: id }));
    }
  }, [isShowRating, id, dispatch]);

  const criteria = useMemo(
    () => [
      {
        title: 'Work Environment',
        description: 'Workspace, cleanliness, equipment.',
        rating: rating?.environment,
      },
      {
        title: 'Workload',
        description: 'Productivity expectations match the shift info.',
        rating: rating?.workload,
      },
      {
        title: 'Collaboration & Respect',
        description: 'Communication, and respect by office staff.',
        rating: rating?.collaboration,
      },
    ],
    [rating],
  );

  return {
    criteria,
    isShowRating,
    overall: rating?.overall,
  };
};
