import { useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Box, Column, Label, RadioOption, Row } from '../../../../../commonComponents';
import { EducationUtils } from '../../../../../utils/EducationUtils';
import { filterObjectKeys } from '../../../../../utils/Object';
import { useFormContext } from '../../../hooks/useFormContext';

export const PrimaryPracticeField = () => {
  const officeEducation = useSelector((state) => state.user.officeEducation);
  const {
    setValue,
    formState: { errors },
  } = useFormContext();

  const selectedSpecialty = useWatch({
    name: 'selectedSpecialty',
  });

  const items = Object.keys(
    filterObjectKeys(officeEducation?.specialtiesAndProcedures, 'specialty') || {},
  );

  const halfLength = Math.ceil(items.length / 2);
  const firstColumnItems = items.slice(0, halfLength);
  const secondColumnItems = items.slice(halfLength);

  const renderColumnItems = (items) =>
    items.map((code) => (
      <RadioOption
        key={code}
        text={EducationUtils.removeSpecialtyPrefix(code)}
        checked={code === selectedSpecialty}
        onClick={() => setValue('selectedSpecialty', code, { shouldDirty: true })}
      />
    ));

  return (
    <Box>
      <Label
        text="What is your area of primary practice?"
        marginBottom={20}
        error={errors.selectedSpecialty}
      />
      <Row marginLeft={40} gap={60}>
        <Column gap={20}>{renderColumnItems(firstColumnItems)}</Column>
        <Column gap={20}>{renderColumnItems(secondColumnItems)}</Column>
      </Row>
    </Box>
  );
};
