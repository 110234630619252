import browserHistory from '~/history';
import { useCanPostShifts } from '~/hooks';
import { EmptyScreen } from '../../../../commonComponents/EmptyScreen/EmptyScreen';
import { getEmptyFilteredScreen } from '../utils';

export const EmptyMessage = ({ type }) => {
  const { title, description, Icon, buttonLink, buttonText } = getEmptyFilteredScreen(type);
  const canPostShifts = useCanPostShifts();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 300,
      }}
    >
      <EmptyScreen
        Icon={Icon}
        title={title}
        description={description}
        buttonText={buttonText}
        {...(canPostShifts && { onButtonClick: () => browserHistory.push(buttonLink) })}
      />
    </div>
  );
};
