import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  hideSuccessWorkerClassificationPopup,
  saveChildOfficeWorkerClassification,
} from '../../../actions';
import CustomPortal from '../../../commonComponents/CustomPortal';
import { EmptyScreen } from '../../../commonComponents/EmptyScreen/EmptyScreen';
import { Table } from '../../../commonComponents/Table/Table';
import { WCUnselectedIcon } from '../../../images/emptyScreenIcons';
import WarningIcon from '../../../images/icons/Warning';
import { Colors } from '../../../themes/colors';
import ConfirmPopup from '../../shared/ConfirmPopup';
import { WC_MANAGEMENT_STATUS, headerUnselectedTitles } from '../utils';
import { renderRow } from './renderRow';

export const Unselected = () => {
  const dispatch = useDispatch();
  const [modal, setModal] = useState({ isOpen: false, item: null, workerType: null });
  const unselectedRequests = useSelector(
    (state) => state.user.workerClassificationManagement.unselected,
  );
  const user = useSelector((state) => state.user);

  const handleOnClick = (item, workerType) => {
    setModal({ isOpen: true, item, workerType });
  };

  const handleConfirmButton = async () => {
    dispatch(
      saveChildOfficeWorkerClassification({
        id: modal.item.id,
        workerType: modal.workerType,
      }),
    );
    handleCloseModal();
  };

  const handleCloseSuccessPopup = async () => {
    dispatch(hideSuccessWorkerClassificationPopup());
  };

  const handleCloseModal = () => {
    setModal({ isOpen: false, item: null, workerType: null });
  };

  return (
    <>
      {!unselectedRequests?.length && (
        <div
          style={{
            marginTop: -20,
            height: 400,
          }}
        >
          <EmptyScreen
            width={500}
            Icon={WCUnselectedIcon}
            title="No worker type has been selected yet!"
            description={
              <>
                All offices have selected their worker type! The offices associated with this
                account have either requested a preference or already confirmed their worker type.
                Check the “Pending Requests“ tab for any outstanding requests.
              </>
            }
          />
        </div>
      )}

      {unselectedRequests?.length > 0 && (
        <Table
          data={unselectedRequests}
          renderRow={(item) => renderRow(item, handleOnClick)}
          headerTitles={headerUnselectedTitles}
        />
      )}

      {modal.isOpen && (
        <ConfirmPopup
          Icon={() => (
            <div style={{ marginBottom: 20 }}>
              <WarningIcon width={80} height={80} color={Colors.warning_500} />
            </div>
          )}
          title="Please Confirm"
          description={
            <>
              Please be aware, this decision will be final. By confirming,
              <br />
              you will be approving the worker type for <br />
              {modal.workerType === 'contractor' ? 'Contracted' : 'Employed'} professionals at{' '}
              {modal.item.officeName}.
            </>
          }
          descriptionStyle={{ width: '100%', marginBottom: 80 }}
          leftButtonText="Go Back"
          leftButtonAction={handleCloseModal}
          rightButtonText="Confirm"
          rightButtonAction={handleConfirmButton}
          closePopup={handleCloseModal}
        />
      )}

      {user?.showWorkerClassificationManagementPopup === WC_MANAGEMENT_STATUS.CONFIRMED && (
        <CustomPortal
          isSuccess
          redirect={false}
          title="Worker Type Successfully Selected!"
          text="This office’s worker type selection has been confirmed."
          submitPressed={handleCloseSuccessPopup}
        />
      )}
    </>
  );
};
