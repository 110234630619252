import '../../themes/confirm_hyg.scss';
import '../../themes/global.scss';

import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  CONFIRM_JOB_ACTION,
  DECLINE_CANDIDATE,
  FETCH_JOB_ACTION,
  fetchCandidateJobOvertime,
  fetchJob,
  hidePaymentMissingPopup,
} from '../../actions';
import { createSuccessSelector } from '../../api/selectors';
import BackButton from '../../commonComponents/BackButton';
import CardSpinner from '../../commonComponents/CardSpinner';
import useLoading from '../../hooks/useLoading';
import WarningIcon from '../../images/icons/Warning';
import { Colors } from '../../themes/colors';
import { FilterStatusEnum } from '../../utils/Enum';
import ConfirmPopup from '../shared/ConfirmPopup';
import { CandidateHeader } from './components/CandidateHeader';
import { CandidateInfo } from './components/CandidateInfo';
import { CandidateSelection } from './components/CandidateSelection/CandidateSelection';
import { CollapsibleCandidateHeader } from './components/CandidateSelection/CollapsibleCandidateHeader';
import { CollapsibleCandidateSelection } from './components/CandidateSelection/CollapsibleCandidateSelection';
import { ConfirmCandidateAnotherJobPopup } from './components/ConfirmCandidateAnotherJobPopup';
import { ShiftDetails } from './components/ShiftDetails';
import { useJobCandidates } from './hooks/useJobCandidates';
import { Filters } from './components/CandidateSelection/Filters';
import { getShouldEnableCandidatesListSort } from '../../growthbook';
import { CandidateSortAnnouncementPopup } from '../../commonComponents/popups/CandidateSortAnnouncementPopup/CandidateSortAnnouncementPopup';

const actions = [CONFIRM_JOB_ACTION, FETCH_JOB_ACTION, DECLINE_CANDIDATE];
const successSelector = createSuccessSelector([FETCH_JOB_ACTION]);

export const ConfirmCandidate = ({ match }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const jobId = history.location.state?.jobId || match.params.jobId;
  const user = useSelector((state) => state.user.selectedChildOffice ?? state.user.user);
  const [notesForTemp, setNotesForTemp] = useState(user.notes_for_temp || '');
  const { job, allCandidates } = useSelector((state) => state.job);
  const { fetchCandidates, reloadCandidates, isLoading: loadingCandidates } = useJobCandidates();
  const { isLoading } = useLoading(actions);
  const isShowPaymentMissingPopup = useSelector(
    (state) => state.payments.isShowPaymentMissingPopup,
  );
  const showConfirmCandidateAnotherJobPopup = useSelector(
    (state) => state.job.showConfirmCandidateAnotherJobPopup,
  );
  const candidateId = match?.params?.candidateId;
  const currentCounter = allCandidates?.find((counter) => counter?.candidate?.id === candidateId);
  const waitSuccessFetchJobRequest = useSelector((state) => successSelector(state));
  const isFirstRenderRef = useRef(true);
  const candidate = currentCounter?.candidate;
  const isCandidateListSortEnabled = getShouldEnableCandidatesListSort();

  const [showCollapsibleSelection, setShowCollapsibleSelection] = useState(false);
  const [showCollapsibleHeader, setShowCollapsibleHeader] = useState(false);
  const hasMultipleCounters = allCandidates?.length > 1;
  const isFilterEnabled = isCandidateListSortEnabled && allCandidates?.length > 1;

  const candidateFetchRef = useRef(false);
  const candidateSelectionRef = useRef(null);
  const candidateHeaderRef = useRef(null);

  const handleScroll = useCallback(() => {
    if (candidateSelectionRef.current) {
      const scrollPosition = window.scrollY;

      const candidateSelectionBottom =
        candidateSelectionRef.current.offsetTop + candidateSelectionRef.current.offsetHeight;

      if (scrollPosition >= candidateSelectionBottom + 5) {
        setShowCollapsibleSelection(true);
      } else {
        setShowCollapsibleSelection(false);
      }
    }

    if (candidateHeaderRef.current) {
      const scrollPosition = window.scrollY;
      const candidateHeaderBottom =
        candidateHeaderRef.current.offsetTop + candidateHeaderRef.current.offsetHeight;

      if (scrollPosition >= candidateHeaderBottom + (!hasMultipleCounters ? 100 : 220)) {
        setShowCollapsibleHeader(true);
      } else {
        setShowCollapsibleHeader(false);
      }
    }
  }, [hasMultipleCounters]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    if (jobId !== job?.id || isFirstRenderRef.current) {
      dispatch(
        fetchJob({
          jobId,
          candidateId: match?.params?.candidateId,
          requestCandidateOvertime: false,
        }),
      );
      isFirstRenderRef.current = false;
    } else if (waitSuccessFetchJobRequest && !isFirstRenderRef.current) {
      dispatch(fetchCandidateJobOvertime({ jobId, candidateId: match?.params?.candidateId }));
    }
  }, [dispatch, jobId, match.params.candidateId, job?.id, waitSuccessFetchJobRequest]);

  useEffect(() => {
    if (job && job.id !== jobId) {
      candidateFetchRef.current = false;
      return;
    }

    if (!waitSuccessFetchJobRequest || jobId !== job?.id) {
      return;
    }
    const backFromCandidateProfile = allCandidates?.length && history.action === 'POP';

    if (!jobId || candidateFetchRef.current) {
      return;
    }

    if (backFromCandidateProfile) {
      candidateFetchRef.current = true;
      return;
    }

    const specialty = job?.specialty;
    if (!specialty) {
      return;
    }

    fetchCandidates(jobId, specialty);

    candidateFetchRef.current = true;
  }, [
    jobId,
    job?.id,
    job?.specialty,
    fetchCandidates,
    reloadCandidates,
    allCandidates,
    history,
    location.state,
    match.params.jobId,
    waitSuccessFetchJobRequest,
  ]);

  useEffect(() => {
    // Reload candidates list when coming back from DeclineCandidate action
    const { jobId } = match.params;
    const fromDeclineCandidate = history.location.state?.fromDeclineCandidate;

    if (jobId && fromDeclineCandidate) {
      reloadCandidates(jobId);
    }
  }, [match.params, history.location.state, reloadCandidates]);

  if (isLoading || loadingCandidates || !job || !job.best_counter) {
    if (jobId === job?.id && job?.status === FilterStatusEnum.SCHEDULED) {
      history.replace(`/dashboard/job/${job.id}/details`);
      return <></>;
    }

    return <CardSpinner />;
  }

  return (
    <div className="component_container global_font">
      <div
        className="floating_container"
        style={{
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            width: '100%',
            maxWidth: 1200,
            alignItems: 'center',
            position: 'relative',
            margin: '40px 20px 20px',
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ flex: 1 }}>
              <BackButton />
            </div>

            <h1
              className="bold"
              style={{ flex: 1, textAlign: 'center', color: Colors.neutral_600 }}
            >
              Confirm Candidate
            </h1>

            <div style={{ flex: 1 }}>{isFilterEnabled && <Filters />}</div>
          </div>

          <div
            ref={candidateSelectionRef}
            style={{
              position: 'relative',
            }}
          >
            <div
              style={{
                opacity: showCollapsibleSelection ? 0 : 1,
              }}
            >
              <CandidateSelection />
            </div>
            <CollapsibleCandidateSelection show={showCollapsibleSelection} />
          </div>

          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              marginTop: -1,
              backgroundColor: Colors.white,
              boxShadow:
                '0px 3px 10px 1px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
              position: 'relative',
            }}
          >
            <CollapsibleCandidateHeader
              show={showCollapsibleHeader}
              candidate={candidate}
              notesForTemp={notesForTemp}
            />

            <div
              style={{
                padding: 20,
                position: 'relative',
              }}
            >
              <div
                style={{
                  opacity: showCollapsibleHeader ? 0 : 1,
                }}
              >
                <CandidateHeader
                  ref={candidateHeaderRef}
                  candidate={{
                    ...candidate,
                    hasWorkedWithDSO: currentCounter?.hasWorkedWithDSO,
                    hasWorkedWithOffice: currentCounter?.hasWorkedWithOffice,
                  }}
                  isFavorite={
                    allCandidates?.find((counter) => counter?.candidate?.id === candidate?.id)
                      ?.isFavorite
                  }
                  notesForTemp={notesForTemp}
                  jobId={job.id}
                />
              </div>

              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1.25fr 1fr',
                  gap: 20,
                  padding: '0 20px',
                }}
              >
                <CandidateInfo
                  candidate={{
                    ...candidate,
                    hasWorkedWithDSO: currentCounter?.hasWorkedWithDSO,
                    hasWorkedWithOffice: currentCounter?.hasWorkedWithOffice,
                  }}
                />
                <div>
                  <ShiftDetails currentCounter={currentCounter} />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      margin: '20px 0px',
                      flex: 1,
                    }}
                  >
                    <h1 className="bold_font" style={{ margin: 10, color: Colors.neutral_600 }}>
                      Notes for Temp:
                    </h1>
                    <textarea
                      className="text_notes"
                      style={{
                        borderWidth: 0,
                        borderRadius: 10,
                        backgroundColor: Colors.neutral_50,
                        boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset',
                        padding: '12px 20px',
                        width: 'auto',
                        height: 80,
                      }}
                      placeholder="Check in at front desk"
                      value={notesForTemp}
                      onChange={(e) => setNotesForTemp(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isShowPaymentMissingPopup ? (
        <ConfirmPopup
          Icon={() => (
            <div style={{ marginBottom: 20 }}>
              <WarningIcon width={64} height={64} color={Colors.warning_500} />
            </div>
          )}
          title="Payment Method Missing"
          description='Please add your preferred payment method to "Confirm" or "Decline" a candidate.'
          descriptionStyle={{ width: '76%', marginBottom: 50 }}
          leftButtonText="Go Back"
          leftButtonAction={() => hidePaymentMissingPopup()}
          rightButtonText="Add Payment"
          rightButtonAction={() => {
            dispatch(hidePaymentMissingPopup());
            history.push('/account/payment');
          }}
        />
      ) : null}

      {showConfirmCandidateAnotherJobPopup && (
        <ConfirmCandidateAnotherJobPopup job={job} candidate={candidate} />
      )}

      {isFilterEnabled && <CandidateSortAnnouncementPopup />}
    </div>
  );
};
