import moment from 'moment';
import {
  popupIncidentType,
  TIncidentType,
} from '~/components/JobList/TodayJobs/ReportIncidentPopup/utils/incidentPopupUtil';
import { ICountAndDatesJob } from '~/models';
import * as actions from '../../actions';
import { defaultFilters } from '../../components/ConfirmCandidate/utils';
import { apiMethodsConst } from '../../services/methods';
import {
  IJobReducerState,
  TFetchActionRequiredJobsRequest,
  TFetchInvoicesRequest,
  TFetchJobCandidatesRequest,
  TFetchJobsWithTransactions,
  TFetchOfficeJobInvitesAction,
  TFetchOfficeJobInvitesRequest,
  TFetchOpenJobRequest,
  TFetchOpenJobsForRecentProfessionalAction,
  TFetchOpenJobsForRecentProfessionalRequest,
  TFetchReceiptListRequest,
  TFetchReceiptsRequest,
  TFetchScheduledJobsRequest,
  TFetchTodayJobsRequest,
  TGetAllJobsRequest,
  TGetJobsByStatusDateActionRequest,
  TGetJobsByStatusDateRequest,
  TInviteFavoriteToJobRequest,
  TJobAction,
  TReportIncidentRequest,
  TShowConfirmReportIncidentPopup,
  TShowJobCandidatesForPage,
  TShowReportIncidentPopup,
} from './interfaces';

export const MAX_LIMIT_PROFESSIONAL_INVITES = 10;

export const initialState: IJobReducerState = {
  jobsPerDay: {},
  jobList: [],
  jobListPagination: {
    page: 1,
    limit: 10,
    status: null,
    isFinal: false,
    isActiveRequest: false,
    date: null,
  },
  incident: {
    job: null,
    professionalName: '',
    incidentType: '' as TIncidentType,
    repostMaxTime: null,
  },
  showReportIncidentPopup: false,
  reportIncidentPopupState: popupIncidentType.CONFIRMATION_SELECT_INCIDENT,
  dashboardJobListPagination: {
    page: 1,
    limit: 10,
    isFinal: false,
    isActiveRequest: false,
  },
  jobsWithTransaction: [],
  jobsWithTransactionPagination: {
    page: 1,
    limit: 10,
    isFinal: false,
  },
  job: null,
  allCandidates: [],
  candidatesFilters: defaultFilters,
  bestPickCandidates: [],
  visibleCandidates: [],
  isOverlappingJob: false,
  message: '',
  openJobs: [],
  scheduledJobs: [],
  recentOpenJobs: {
    professionalId: null,
    page: 1,
    total: null,
    limit: MAX_LIMIT_PROFESSIONAL_INVITES || 10,
    isFinal: false,
    isActiveRequest: false,
    offers: [],
  },
  actionRequiredJobs: [],
  todayJobs: [],
  expiredCounteOffers: [],
  receipts: [],
  receiptsPagination: {
    page: 1,
    limit: 25,
  },
  receiptDisplayMode: 'shift',
  totalReceipts: 0,
  isActiveRequest: false,
  isFinalPage: false,
  receiptReport: '',
  averageRating: {
    DN: { min: 18, max: 180, average: 89 },
    DA: { min: 18, max: 50, average: 23 },
    RDH: { average: 49, max: 100, min: 18 },
  },
  adjustmentsSend: [],
  invoices: [],
  invoicesPagination: {
    page: 1,
    limit: 25,
  },
  totalInvoices: 0,
  totalUnpaid: {
    count: 0,
    sum: 0,
  },
  isActiveRequestInvoices: false,
  isFinalPageInvoices: false,
  adjustments: [],
  cancelationReasons: [],
  showJobCreatePaymentError: false,
  schedule: {
    dateRange: {
      start: moment().startOf('month').format('YYYY-MM-DD'),
      end: moment().endOf('month').format('YYYY-MM-DD'),
    },
    view: 'calendar',
    filter: 'all',
  },
  showSuccessJobCancelationPopup: false,
  officeJobInvites: {
    jobs: [],
    page: 1,
    limit: 10,
    isFinal: false,
    isActiveRequest: false,
  },
  currentCandidateEndorsement: null,
  showConfirmCandidateAnotherJobPopup: false,
  lastJobsPosted: [],
  dashboardInfo: {
    scheduled: 0,
    activeRequest: 0,
    actionRequired: 0,
  },
};

export default (state = initialState, action: TJobAction) => {
  switch (action.type) {
    case apiMethodsConst.CREATE_JOB_REQUEST:
      return {
        ...state,
        job: action.payload,
        isOverlappingJob: false,
        message: '',
      };
    case actions.SHOW_JOB_CREATE_PAYMENT_ERROR:
      return {
        ...state,
        showJobCreatePaymentError: true,
      };
    case actions.HIDE_JOB_CREATE_PAYMENT_ERROR:
      return {
        ...state,
        showJobCreatePaymentError: false,
      };
    case apiMethodsConst.FETCH_CANCELLATION_REASONS_REQUEST:
      return {
        ...state,
        cancelationReasons: action.payload,
      };
    case 'ADJUST_HOURS_ACTION_SUCCESS': {
      return {
        ...state,
        adjustmentsSend: [...state.adjustmentsSend, action.payload],
      };
    }
    case apiMethodsConst.FETCH_JOBS_WITH_ADJUSTMENTS_REQUEST: {
      return {
        ...state,
        adjustments: action.payload,
      };
    }

    case 'CHANGE_STATUS_ADJUSTMENT_SUCCESS': {
      const index = state.adjustments.findIndex((job) => job.id === action.payload);
      return {
        ...state,
        adjustments: [...state.adjustments.slice(0, index), ...state.adjustments.slice(index + 1)],
      };
    }
    case apiMethodsConst.GET_ALL_JOBS_REQUEST: {
      const typedAction = action as TGetAllJobsRequest;
      const { openDates, pendingDates, confirmedDates, pastDates, todayDates } =
        typedAction.payload!;

      const datesWithStatus = [
        ...todayDates.map((data: ICountAndDatesJob) => ({ ...data, status: 'today' })),
        ...openDates.map((data: ICountAndDatesJob) => ({ ...data, status: 'open' })),
        ...pendingDates.map((data: ICountAndDatesJob) => ({ ...data, status: 'pending' })),
        ...confirmedDates.map((data: ICountAndDatesJob) => ({ ...data, status: 'filled' })),
        ...pastDates.map((data: ICountAndDatesJob) => ({ ...data, status: 'past' })),
      ];

      const jobsPerDay = datesWithStatus
        .map(({ localDate, ...rest }) => ({
          [moment(localDate, 'ddd, MM/DD/YYYY').format('YYYY-MM-DD')]: [{ localDate, ...rest }],
        }))
        .reduce((res, curr) => {
          const key = Object.keys(curr)[0];
          return {
            ...res,
            [key]: [...(res[key] || []), ...curr[key]],
          };
        }, {});

      // Clear days that are in the current schedule date range
      const oldJobsPerDay = { ...state.jobsPerDay };
      Object.keys(oldJobsPerDay).forEach((dateKey) => {
        const date = moment(dateKey, 'YYYY-MM-DD');
        if (
          date.isBetween(state.schedule.dateRange.start, state.schedule.dateRange.end, null, '[]')
        ) {
          oldJobsPerDay[dateKey] = [];
        }
      });

      return {
        ...state,
        jobsPerDay: { ...oldJobsPerDay, ...jobsPerDay },
      };
    }
    case 'GET_JOBS_BY_STATUS_DATE_ACTION_REQUEST': {
      const typedAction = action as TGetJobsByStatusDateActionRequest;
      const { status, enablePagination, date } = typedAction.payload!.status;

      if (
        (!enablePagination && status !== state.jobListPagination.status) ||
        (date && !state.jobListPagination.date)
      ) {
        return {
          ...state,
          jobList: [],
          jobListPagination: {
            ...state.jobListPagination,
            page: 1,
            status,
            date,
          },
        };
      }

      return state;
    }
    case apiMethodsConst.GET_JOBS_BY_STATUS_DATE_REQUEST: {
      const typedAction = action as TGetJobsByStatusDateRequest;
      const data = typedAction.payload!.jobs;

      if (state.jobListPagination.isFinal && state.jobListPagination.page > 1) return state;

      if (!typedAction.payload!.enablePagination) {
        return {
          ...state,
          jobList: typedAction.payload!.jobs,
          jobListPagination: {
            ...state.jobListPagination,
            isFinal: data.length === 0 || data.length < state.jobListPagination.limit,
          },
        };
      }

      return {
        ...state,
        jobList: [...state.jobList, ...typedAction.payload!.jobs],
        jobListPagination: {
          ...state.jobListPagination,
          page: typedAction.payload!.page,
          isFinal: data.length === 0 || data.length < state.jobListPagination.limit,
        },
      };
    }
    case apiMethodsConst.FETCH_JOBS_WITH_TRANSACTIONS: {
      const typedAction = action as TFetchJobsWithTransactions;
      const jobs = typedAction.payload!.response;

      const mergedJobs = [...state.jobsWithTransaction, ...jobs];

      return {
        ...state,
        jobsWithTransaction: typedAction.payload!.page === 1 ? jobs : mergedJobs,
        jobsWithTransactionPagination: {
          ...state.jobsWithTransactionPagination,
          page: typedAction.payload!.page,
          limit: typedAction.payload!.limit,
          isFinal: jobs.length === 0 || jobs.length < state.jobsWithTransactionPagination.limit,
        },
      };
    }
    case apiMethodsConst.FETCH_AVERAGE_RATING:
      return {
        ...state,
        averageRating: action.payload,
      };
    case 'IS_OVERLAPPING_JOB':
      return {
        ...state,
        isOverlappingJob: true,
        message: action.payload,
      };
    case apiMethodsConst.CANCEL_JOB_REQUEST:
      return {
        ...state,
        actionRequiredJobs: state.actionRequiredJobs?.filter(
          (job) => job.id !== action.payload?.jobId,
        ),
        showSuccessJobCancelationPopup: !action.payload?.redirect,
      };
    case actions.HIDE_SUCCESS_JOB_CANCELATION:
      return {
        ...state,
        showSuccessJobCancelationPopup: false,
      };
    case apiMethodsConst.FETCH_CANDIDATE_OVERTIME_REQUEST:
      return {
        ...state,
        job: {
          ...state.job,
          regularAndOvertimeHours: action.payload?.regularAndOvertimeHours,
          totalOvertime: action.payload?.totalOvertime,
          totalRegular: action.payload?.totalRegular,
          convenience_fee: action.payload?.convenience_fee,
          convenience_fee_percent: action.payload?.convenience_fee_percent,
          employeeOptionFee: action.payload?.employeeOptionFee,
          total: action.payload?.total,
          tm_fee: action.payload?.tm_fee,
          tm_fee_percent: action.payload?.tm_fee_percent,
        },
      };
    case apiMethodsConst.FETCH_JOB_REQUEST:
    case apiMethodsConst.DECLINE_COUNTER_OFFER_REQUEST:
    case actions.SELECT_JOB_ACTION:
      return {
        ...state,
        job: action.payload,
      };
    case actions.CLEAR_JOB_CANDIDATES_ACTION:
      return {
        ...state,
        allCandidates: initialState.allCandidates,
        visibleCandidates: initialState.visibleCandidates,
        bestPickCandidates: initialState.bestPickCandidates,
      };
    case apiMethodsConst.FETCH_JOB_CANDIDATES_REQUEST: {
      const typedAction = action as TFetchJobCandidatesRequest;
      const totalPages = Math.ceil(typedAction.payload!.length / state.candidatesFilters.limit);

      return {
        ...state,
        allCandidates: action.payload,
        candidatesFilters: {
          ...state.candidatesFilters,
          totalPages,
        },
      };
    }
    case actions.JOB_CANDIDATES_FILTER_CHANGE:
      return {
        ...state,
        candidatesFilters: {
          ...state.candidatesFilters,
          ...action.payload,
        },
      };
    case actions.SET_BEST_PICK_CANDIDATES:
      return {
        ...state,
        bestPickCandidates: action.payload,
      };
    case actions.SHOW_JOB_CANDIDATES_FOR_PAGE: {
      const typedAction = action as TShowJobCandidatesForPage;
      const page = typedAction.payload!;
      const { allCandidates, candidatesFilters } = state;
      const { limit, totalPages } = candidatesFilters;

      const newPage = Math.min(Math.max(page, 1), totalPages);

      return {
        ...state,
        visibleCandidates: allCandidates.slice((newPage - 1) * limit, newPage * limit),
        candidatesFilters: {
          ...state.candidatesFilters,
          page: newPage,
        },
      };
    }
    case apiMethodsConst.REPORT_INCIDENT_REQUEST: {
      const typedAction = action as TReportIncidentRequest;
      return {
        ...state,
        todayJobs: state.todayJobs.map((job) => {
          if (job.id === typedAction.payload!.id) {
            return { ...job, incident: true };
          }
          return job;
        }),
      };
    }
    case actions.SHOW_REPORT_INCIDENT_POPUP: {
      const typedAction = action as TShowReportIncidentPopup;
      return {
        ...state,
        incident: {
          ...state.incident,
          job: typedAction.payload!.job,
          professionalName: typedAction.payload!.professionalName,
          canReport: typedAction.payload!.canReport,
          canRepost: typedAction.payload!.canRepost,
        },
        showReportIncidentPopup: true,
        reportIncidentPopupState: popupIncidentType.CONFIRMATION_SELECT_INCIDENT,
      };
    }
    case actions.CANCEL_CONFIRM_REPORT_INCIDENT_POPUP:
      return {
        ...state,
        reportIncidentPopupState: popupIncidentType.CONFIRMATION_SELECT_INCIDENT,
      };
    case actions.SHOW_CONFIRM_REPORT_INCIDENT_POPUP: {
      const typedAction = action as TShowConfirmReportIncidentPopup;
      return {
        ...state,
        incident: {
          ...state.incident,
          incidentType: typedAction.payload!.incidentType,
        },
        reportIncidentPopupState: popupIncidentType.CONFIRMATION_INCIDENT,
      };
    }
    case actions.SHOW_REPOST_NCNS_POPUP:
      return {
        ...state,
        reportIncidentPopupState: popupIncidentType.CONFIRMATION_TO_REPOST,
      };
    case actions.SHOW_INCIDENT_REPORTED_POPUP:
      return {
        ...state,
        reportIncidentPopupState: popupIncidentType.SUCCESS_REPORT,
      };
    case actions.SHOW_SHIFT_REPOSTED_POPUP:
      return {
        ...state,
        reportIncidentPopupState: popupIncidentType.SUCCESS_REPOST,
      };
    case actions.RESET_INCIDENT:
      return {
        ...state,
        incident: {
          job: null,
          professionalName: '',
          incidentType: '',
          repostMaxTime: null,
        },
      };
    case actions.CLEAR_INCIDENT_POPUPS:
      return {
        ...state,
        showReportIncidentPopup: false,
        reportIncidentPopupState: popupIncidentType.CONFIRMATION_SELECT_INCIDENT,
      };
    case apiMethodsConst.FETCH_EXPIRED_COUNTER_OFFERS:
      return {
        ...state,
        expiredCounteOffers: action.payload,
      };
    case apiMethodsConst.FETCH_DASHBOARD_INFO:
      return {
        ...state,
        dashboardInfo: action.payload,
      };
    case apiMethodsConst.FETCH_OPEN_JOB_REQUEST: {
      const typedAction = action as TFetchOpenJobRequest;
      const mergedOpenJobs = [...state.openJobs, ...typedAction.payload!];

      return {
        ...state,
        openJobs: state.dashboardJobListPagination.page === 1 ? action.payload : mergedOpenJobs,
        dashboardJobListPagination: {
          ...state.dashboardJobListPagination,
          isFinal:
            typedAction.payload!.length === 0 ||
            typedAction.payload!.length < state.dashboardJobListPagination.limit,
          isActiveRequest: false,
        },
      };
    }
    case 'FETCH_SCHEDULED_JOBS_ACTION_REQUEST':
    case 'FETCH_ACTION_REQUIRED_JOBS_ACTION_REQUEST':
    case 'FETCH_OPEN_JOBS_ACTION_REQUEST':
    case 'FETCH_TODAY_JOBS_ACTION_REQUEST':
      return {
        ...state,
        dashboardJobListPagination: {
          ...state.dashboardJobListPagination,
          page: action.payload!.page,
          limit: action.payload!.limit,
          isFinal: false,
          isActiveRequest: true,
        },
      };
    case apiMethodsConst.FETCH_SCHEDULED_JOBS_REQUEST: {
      const typedAction = action as TFetchScheduledJobsRequest;
      return {
        ...state,
        scheduledJobs:
          state.dashboardJobListPagination.page === 1
            ? action.payload
            : [...state.scheduledJobs, ...typedAction.payload!],
        dashboardJobListPagination: {
          ...state.dashboardJobListPagination,
          isFinal:
            typedAction.payload!.length === 0 ||
            typedAction.payload!.length < state.dashboardJobListPagination.limit,
          isActiveRequest: false,
        },
      };
    }
    case apiMethodsConst.FETCH_ACTION_REQUIRED_JOBS_REQUEST: {
      const typedAction = action as TFetchActionRequiredJobsRequest;
      return {
        ...state,
        actionRequiredJobs:
          state.dashboardJobListPagination.page === 1
            ? action.payload
            : [...state.actionRequiredJobs, ...typedAction.payload!],
        dashboardJobListPagination: {
          ...state.dashboardJobListPagination,
          page: state.dashboardJobListPagination.page,
          isFinal:
            typedAction.payload!.length === 0 ||
            typedAction.payload!.length < state.dashboardJobListPagination.limit,
          isActiveRequest: false,
        },
      };
    }
    case apiMethodsConst.FETCH_TODAY_JOBS_REQUEST: {
      const typedAction = action as TFetchTodayJobsRequest;
      return {
        ...state,
        todayJobs:
          state.dashboardJobListPagination.page === 1
            ? action.payload
            : [...state.todayJobs, ...typedAction.payload!],
        dashboardJobListPagination: {
          ...state.dashboardJobListPagination,
          page: state.dashboardJobListPagination.page,
          isFinal:
            typedAction.payload!.length === 0 ||
            typedAction.payload!.length < state.dashboardJobListPagination.limit,
          isActiveRequest: false,
        },
      };
    }
    case actions.UPDATE_RECEIPT_DISPLAY_MODE:
      return {
        ...state,
        receiptDisplayMode: action.payload,
      };
    case 'FETCH_RECEIPT_LIST_REQUEST': {
      const typedAction = action as TFetchReceiptListRequest;
      return {
        ...state,
        receiptsPagination: {
          page: typedAction.payload!.page,
          limit: typedAction.payload!.limit,
        },
      };
    }
    case apiMethodsConst.FETCH_RECEIPTS_REQUEST: {
      const typedAction = action as TFetchReceiptsRequest;
      const receipts = typedAction.payload!.response;
      const { page, total, limit } = typedAction.payload!;

      const mergedReceipts = [...state.receipts, ...receipts];
      return {
        ...state,
        receipts: page === 1 ? receipts : mergedReceipts,
        receiptsPagination: {
          ...state.receiptsPagination,
          page,
          limit,
        },
        totalReceipts: total,
        isFinalPage:
          receipts.length === 0 ||
          receipts.length < state.receiptsPagination.limit ||
          receipts.length === state.totalReceipts,
        isActiveRequest: false,
      };
    }
    case apiMethodsConst.FETCH_RECEIPTS_REPORT_REQUEST:
      return {
        ...state,
        receiptReport: action.payload,
      };
    case actions.CLEAR_RECEIPTS_REPORT:
      return {
        ...state,
        receiptReport: '',
      };
    case actions.CLEAR_ALL_RECEIPTS:
      return {
        ...state,
        receipts: [],
        jobsWithTransaction: [],
      };
    case actions.CLOSE_OVERLAPPING_MODAL:
      return {
        ...state,
        isOverlappingJob: false,
      };
    case 'UPDATE_JOB_ACTION_SUCCESS':
      return {
        ...state,
        job: action.payload,
      };
    case 'SELECT_OFFICE_SUCCESS':
      return initialState;

    case 'FETCH_INVOICE_LIST_REQUEST': {
      return {
        ...state,
        invoicesPagination: {
          page: action.payload!.page,
          limit: action.payload!.limit,
        },
        isActiveRequestInvoices: true,
      };
    }
    case apiMethodsConst.FETCH_INVOICES_REQUEST: {
      const typedAction = action as TFetchInvoicesRequest;
      const { invoices } = typedAction.payload!;
      const pageInvoices = typedAction.payload!.page;
      const totalInvoices = typedAction.payload!.total;
      const limitInvoices = typedAction.payload!.limit;
      const { totalUnpaid } = typedAction.payload!;

      const mergedInvoices = [...state.invoices, ...invoices];
      return {
        ...state,
        invoices: pageInvoices === 1 ? invoices : mergedInvoices,
        invoicesPagination: {
          ...state.invoicesPagination,
          page: pageInvoices,
          limit: limitInvoices,
        },
        totalInvoices,
        totalUnpaid,
        isFinalPageInvoices:
          invoices.length === 0 ||
          invoices.length < state.invoicesPagination.limit ||
          invoices.length === state.totalInvoices,
        isActiveRequestInvoices: false,
      };
    }
    case apiMethodsConst.INVITE_FAVORITE_TO_JOB_REQUEST: {
      const typedAction = action as TInviteFavoriteToJobRequest;
      return {
        ...state,
        job: {
          ...state.job,
          favoriteInvites: [
            ...(state.job?.favoriteInvites ?? []),
            ...typedAction.payload!.userIds.map((id: string) => ({ professional: id })),
          ],
        },
      };
    }
    case actions.SHOW_CONFIRM_CANDIDATE_ANOTHER_JOB_POPUP:
      return {
        ...state,
        showConfirmCandidateAnotherJobPopup: true,
      };
    case actions.HIDE_CONFIRM_CANDIDATE_ANOTHER_JOB_POPUP:
      return {
        ...state,
        showConfirmCandidateAnotherJobPopup: false,
      };

    case apiMethodsConst.CONFIRM_JOB_REQUEST:
      return {
        ...state,
        actionRequiredJobs: state.actionRequiredJobs?.filter(
          (job) => job.id !== action.payload?.id,
        ),
      };
    case actions.CHANGE_SCHEDULE:
      return {
        ...state,
        schedule: {
          ...state.schedule,
          ...(action.payload && { [action.payload.option]: action.payload.value }),
        },
      };
    case actions.FETCH_OPEN_JOBS_FOR_RECENT_PROFESSIONAL_ACTION: {
      const typedAction = action as TFetchOpenJobsForRecentProfessionalAction;
      return {
        ...state,
        recentOpenJobs: {
          ...state.recentOpenJobs,
          page: typedAction.payload!.page,
          isFinal: false,
          isActiveRequest: true,
        },
      };
    }
    case apiMethodsConst.FETCH_OPEN_JOBS_FOR_RECENT_PROFESSIONAL_REQUEST: {
      const typedAction = action as TFetchOpenJobsForRecentProfessionalRequest;
      return {
        ...state,
        recentOpenJobs: {
          professionalId: typedAction.payload!.professionalId,
          limit: state.recentOpenJobs.limit,
          page: typedAction.payload!.page,
          offers:
            typedAction.payload!.page === 1
              ? typedAction.payload!.offers
              : [...state.recentOpenJobs.offers, ...typedAction.payload!.offers],
          isFinal:
            typedAction.payload!.offers.length === 0 ||
            typedAction.payload!.offers.length < state.recentOpenJobs.limit,
          isActiveRequest: false,
        },
      };
    }
    case actions.FETCH_OFFICE_JOB_INVITES_ACTION: {
      const typedAction = action as TFetchOfficeJobInvitesAction;
      return {
        ...state,
        officeJobInvites: {
          ...state.officeJobInvites,
          page: typedAction.payload!.page,
          isFinal: false,
          isActiveRequest: true,
        },
      };
    }
    case apiMethodsConst.FETCH_OFFICE_JOB_INVITES_REQUEST: {
      const typedAction = action as TFetchOfficeJobInvitesRequest;
      const totalJobsArr = [...state.officeJobInvites.jobs, ...typedAction.payload!.jobs];
      return {
        ...state,
        officeJobInvites: {
          ...action.payload,
          jobs: typedAction.payload!.page === 1 ? typedAction.payload!.jobs : totalJobsArr,
          isFinal:
            typedAction.payload!.jobs.length === 0 ||
            typedAction.payload!.jobs.length < state.officeJobInvites.limit,
          isActiveRequest: false,
        },
      };
    }
    case apiMethodsConst.FETCH_USER_PROCEDURE_ENDORSEMENT_REQUEST:
      return {
        ...state,
        currentCandidateEndorsement: action.payload,
      };

    case apiMethodsConst.GET_LAST_POSTED_JOBS_REQUEST:
      return {
        ...state,
        lastJobsPosted: action.payload,
      };

    default:
      return state;
  }
};
