import { useDispatch, useSelector } from 'react-redux';
import {
  FETCH_FAVORITE_PROFESSIONALS,
  FETCH_RECENT_PROFESSIONALS,
  GET_USER_OVERTIME_LIMITS_ACTION,
  SELECT_DEFAULT_INFO_MDB,
  selectDefaultInfoMDB,
} from '../../../../../actions';
import { trackEvent } from '../../../../../api/analytics';
import BackButton from '../../../../../commonComponents/BackButton';
import Button from '../../../../../commonComponents/Button';
import CardSpinner from '../../../../../commonComponents/CardSpinner';
import history from '../../../../../history';
import useLoading from '../../../../../hooks/useLoading';
import Form from '../../components/Form';
import ProgressBar from '../../components/ProgressBar';
import { useShiftDetailsStep } from '../../hooks/useShiftDetailsStep';

const actions = [
  SELECT_DEFAULT_INFO_MDB,
  FETCH_FAVORITE_PROFESSIONALS,
  FETCH_RECENT_PROFESSIONALS,
  GET_USER_OVERTIME_LIMITS_ACTION,
];

export function ShiftDetailsContent() {
  const dispatch = useDispatch();
  const editedDate = history.location?.state?.editedDate || '';
  const currentJob = useSelector(
    (state) => state.multiday.jobInfo.filter((job) => job.localDate === editedDate)[0],
  );
  const { formValues, setFormValues, jobData, isDisabled } = useShiftDetailsStep(currentJob);
  const { isLoading } = useLoading(actions);
  const jobDates = useSelector((state) => state.multiday.jobDates);

  const isButtonDisabled = isDisabled || !jobDates?.length;

  const handleNextButton = () => {
    trackEvent(
      `${
        formValues.professional?.id ? 'Re-Book' : 'Temp'
      } Request - Shift Details Entry "Next" Clicked`,
      { jobInfo: formValues },
    );
    dispatch(selectDefaultInfoMDB({ jobData }));
    history.push('/dashboard/multi-day/confirm');
  };

  if (isLoading) {
    return <CardSpinner />;
  }

  return (
    <div className="component_container">
      <div
        className="floating_container"
        style={{ justifyContent: 'flex-start', flexDirection: 'column' }}
      >
        <div className="card">
          <div style={{ marginTop: 20, marginLeft: 30 }}>
            <BackButton />
          </div>
          <div className="card-body" style={{ margin: 30 }}>
            <ProgressBar step={1} />

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                marginTop: 48,
              }}
            >
              <Form formValues={formValues} setFormValues={setFormValues} />
            </div>
          </div>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 120,
            marginTop: 20,
          }}
        >
          <Button
            text="Next"
            onClick={handleNextButton}
            disabled={isButtonDisabled}
            data-testid="next-button"
          />
        </div>
      </div>
    </div>
  );
}
