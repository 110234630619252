import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { Colors } from '../../../themes/colors';
import { showMessage } from '../../../actions';
import logoDarkIcon from '../../../images/logos/logoDarkIcon.svg';
import { renderIntroductionLine } from '../../../commonComponents/RenderListFunc';
import { createLoadingSelector } from '../../../api/selectors';
import InputSelect from '../../../commonComponents/InputSelect';
import { getShouldOfficeShowEfda } from '../../../growthbook';
import { getAllProfessionTypeOptions } from '../../../utils/DentistUtils';
import { UserProfession, UserSubProfession } from '../../../enums/UserProfession';
import { EnumHelper } from '../../../utils/EnumHelper';

export default function PPView({ btnSubmitClicked }) {
  const dispatch = useDispatch();

  const [date, setDate] = useState('ASAP');
  const [description, setDescription] = useState('');
  const [schedule, setSchedule] = useState('Full Time Preferred');
  const [rateFrom, setRateFrom] = useState('');
  const [rateTo, setRateTo] = useState('');
  const [benefits, setBenefits] = useState('Full Benefits');

  const loadingSelector = createLoadingSelector(['POST_PP_JOB']);
  const isLoading = useSelector((state) => loadingSelector(state));

  const [professionType, setProfessionType] = useState('RDH');

  // use profession. normalized sub-profession to profession parent
  const professionTypeNormalizedCode = UserProfession.getNormalizedCode(professionType);

  const allProfessions = useSelector((state) => state.user.allProfessions);
  const showEfda = getShouldOfficeShowEfda();
  const allProfessionOptions = getAllProfessionTypeOptions(allProfessions, { showEfda });

  const isValidData = description !== '' && rateFrom !== '' && rateTo !== '';
  const isValidPayRange = rateTo >= rateFrom;
  const buttonDisabled = !isValidData || !isValidPayRange;

  const submitHasBeenPressed = () => {
    const rate = `$${rateFrom} - $${rateTo}/hour`;
    if (!isValidData) {
      dispatch(
        showMessage({
          title: 'Error',
          message: 'Please enter all needed information.',
          isError: true,
        }),
      );
    } else {
      const data = {
        start_date: date,
        content: description,
        position: schedule,
        rate,
        benefits,
        // use profession. normalized if sub-profession is included
        profession: professionTypeNormalizedCode,

        // include sub-profession if it's a member of UserSubProfession
        subProfession: EnumHelper.getObjectKeys(UserSubProfession).includes(professionType)
          ? professionType
          : null,
      };
      btnSubmitClicked(data);
    }
  };

  return (
    <div className="card">
      <div style={{ margin: 30, maxWidth: 1100 }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            marginTop: 30,
          }}
        >
          <img src={logoDarkIcon} style={{ width: 40 }} alt="logo_dark_icon" />
        </div>
        <h1
          className="card-title global_font f-dark"
          style={{ fontFamily: 'Nunito', textAlign: 'center' }}
        >
          Permanent Hire
        </h1>

        <h3
          style={{
            fontFamily: 'Nunito',
            color: Colors.neutral_600,
            textAlign: 'center',
          }}
        >
          GoTu now offers a turn-key recruiting service to help you <br /> fill your open positions
          fast.
        </h3>
        <div style={{ display: 'flex' }}>
          <div>
            <h3
              style={{
                fontFamily: 'Nunito',
                color: Colors.primary_500,
                marginTop: 30,
              }}
            >
              Request Permanent Hire
            </h3>

            <div>
              <p style={styles.titleStyle}>Profession Type:</p>

              <InputSelect
                value={professionType}
                setValue={setProfessionType}
                options={allProfessionOptions?.map((profession) => ({
                  title: profession.name,
                  value: profession.code,
                }))}
              />
            </div>
            <div>
              <p style={styles.titleStyle}>Start Date:</p>

              <InputSelect
                value={date}
                setValue={setDate}
                options={[
                  { title: 'ASAP', value: 'ASAP' },
                  { title: '1-2 Weeks', value: '1-2 Weeks' },
                  { title: '2-4 Weeks', value: '2-4 Weeks' },
                  { title: '1-2 Months', value: '1-2 Months' },
                  { title: '3+ Months', value: '3+ Months' },
                ]}
              />
            </div>
            <div>
              <p style={styles.titleStyle}>Schedule Type:</p>

              <InputSelect
                value={schedule}
                setValue={setSchedule}
                options={[
                  { title: 'Full Time Preferred', value: 'Full Time Preferred' },
                  { title: 'Part Time Preferred', value: 'Part Time Preferred' },
                  { title: 'Flexible (Full or Part Time)', value: 'Flexible (Full or Part Time)' },
                ]}
              />
            </div>
            <div>
              <p style={styles.titleStyle}>Pay Range:</p>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <p style={styles.rateStyle}>$</p>
                <input
                  style={styles.inputStyle}
                  value={rateFrom}
                  type="number"
                  min="0"
                  onChange={(e) => setRateFrom(e.target.value.replaceAll('-', ''))}
                />
                <p style={styles.rateStyle}>-$</p>
                <input
                  style={styles.inputStyle}
                  value={rateTo}
                  type="number"
                  min="0"
                  onChange={(e) => setRateTo(e.target.value.replaceAll('-', ''))}
                />
                <p style={styles.rateStyle}>/hour</p>
              </div>
            </div>

            <div>
              <p style={styles.titleStyle}>Benefit Package:</p>

              <InputSelect
                value={benefits}
                setValue={setBenefits}
                options={[
                  { title: 'Full Benefits', value: 'Full Benefits' },
                  { title: 'Some Benefits', value: 'Some Benefits' },
                  { title: 'No Benefits', value: 'No Benefits' },
                  { title: 'Negotiable Benefit', value: 'Negotiable Benefit' },
                ]}
              />
            </div>

            <div>
              <p style={styles.titleStyle}>Job Description: </p>
              <textarea
                style={{
                  marginLeft: 0,
                  height: 300,
                  width: '100%',
                  padding: 10,
                  fontFamily: 'Nunito',
                }}
                className="text_notes"
                value={description}
                type="text"
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            {isLoading ? (
              <div>
                <Spinner
                  animation="border"
                  role="status"
                  variant="info"
                  style={{
                    height: 30,
                    width: 30,
                    marginLeft: 20,
                    marginTop: 10,
                  }}
                />
              </div>
            ) : (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <button
                  disabled={buttonDisabled}
                  type="button"
                  className="btn btn-success"
                  onClick={() => submitHasBeenPressed()}
                  style={{
                    width: 250,
                    height: 50,
                    borderRadius: 25,
                    fontSize: 16,
                    fontFamily: 'Nunito',
                    fontWeight: 'bold',
                    backgroundColor: !buttonDisabled ? Colors.primary_500 : Colors.neutral_200,
                    color: Colors.white,
                  }}
                >
                  Submit
                </button>
              </div>
            )}
          </div>
          <div
            style={{
              backgroundColor: Colors.primary_50,
              padding: '10px 20px',
              borderRadius: 20,
              height: 260,
              marginLeft: '10%',
            }}
          >
            <h3
              style={{
                fontFamily: 'Nunito',
                color: Colors.primary_500,
              }}
            >
              Recruiting Services Include:
            </h3>

            {renderIntroductionLine('A professional Recruiter')}
            {renderIntroductionLine('Reach ~95% of Dental Professionals')}
            {renderIntroductionLine('30 Day Great Hire Warranty')}
            {renderIntroductionLine('High-End Recruiter at Job Board Pricing')}
          </div>
        </div>
      </div>
    </div>
  );
}
const styles = {
  cardBodyStyle: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
  },
  selectStyle: {
    border: '1px solid #B0BEC5',
    backgroundColor: '#FBFBFB',
    fontSize: 16,
    fontFamily: 'Nunito',
    color: Colors.neutral_600,
    height: 45,
    padding: 5,
    minWidth: 440,
  },

  inputStyle: {
    border: '1px solid #B0BEC5',
    backgroundColor: '#FBFBFB',
    fontSize: 16,
    fontFamily: 'Nunito',
    color: Colors.neutral_600,
    marginTop: 0,
    marginRight: 10,
    marginLeft: 10,
    height: 40,
    padding: 0,
    paddingLeft: 5,
  },
  rateStyle: {
    fontSize: 16,
    fontFamily: 'Nunito',
    color: Colors.neutral_600,
    fontWeight: 'bold',
    marginBottom: 10,
    marginTop: 10,
  },
  titleStyle: {
    fontSize: 16,
    fontFamily: 'Nunito',
    color: Colors.neutral_600,
    fontWeight: 'bold',
    marginBottom: 10,
  },
};
