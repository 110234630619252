import { motion } from 'framer-motion';
import { useMemo } from 'react';
import Portal from '~/commonComponents/Portal';
import { useReportIncidentPopup } from '~/components/JobList/TodayJobs/ReportIncidentPopup/hooks';
import CustomSpinner from '~/commonComponents/CustomSpinner';
import { popupStateMetadata } from './components';

export const ReportIncidentPopup = () => {
  const {
    isLoading,
    submitReportIncident,
    showReportIncidentPopup,
    incident,
    closeReportIncident,
    reportIncidentPopupState,
    incidentType,
  } = useReportIncidentPopup();

  const PopupContentComponent = useMemo(
    () => popupStateMetadata[reportIncidentPopupState],
    [reportIncidentPopupState],
  );

  if (!showReportIncidentPopup || !PopupContentComponent) {
    return null;
  }

  return (
    <Portal>
      <div className="modal" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
        <motion.div
          onClick={(event) => event.stopPropagation()}
          className="modal_content"
          style={{ position: 'relative', padding: '45px 10px' }}
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          {isLoading ? (
            <CustomSpinner isLoading={isLoading} />
          ) : (
            <PopupContentComponent
              data={incident}
              incidentType={incidentType}
              onClose={closeReportIncident}
              onSubmit={submitReportIncident}
            />
          )}
        </motion.div>
      </div>
    </Portal>
  );
};
