import { forwardRef } from 'react';
import { Box, IBoxProps } from '../Box';

export const Column = forwardRef<HTMLDivElement, IBoxProps>(({ children, ...rest }, ref) => (
  <Box {...rest} ref={ref} direction="column">
    {children}
  </Box>
));

Column.displayName = 'Column';
