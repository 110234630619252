import { AnimatePresence, motion } from 'framer-motion';
import CustomSpinner from '../../../../commonComponents/CustomSpinner';
import { Colors } from '../../../../themes/colors';
import Tooltip from '../../../Tooltip';
import { CardActionsHeader } from '../CardActionsHeader';
import { ProfessionalCard } from '../ProfessionalCard';
import { EmptyRecentProfessionals } from './EmptyRecentProfessionals';
import { ProfessionalCardActions } from './ProfessionalCardActions';
import { ProfessionalCardDetails } from './ProfessionalCardDetails';
import { useRecentProfessionalsList } from './hooks/useRecentProfessionalsList';
import { EmptyFilteredList } from '../EmptyFilteredList';

export const MAX_RECENT_PROFESSIONALS = 6;

export const RecentProfessionalsList = () => {
  const { recentProfessionals, isLoading, isEmpty, isFiltered } = useRecentProfessionalsList();

  if (isLoading) {
    return <CustomSpinner isLoading size={24} />;
  }

  if (isEmpty) {
    if (isFiltered) {
      return <EmptyFilteredList />;
    }
    return <EmptyRecentProfessionals />;
  }

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <div
        style={{
          position: 'relative',
          fontFamily: 'Nunito',
          fontSize: '16px',
          fontStyle: 'italic',
          color: Colors.neutral_500,
          textAlign: 'center',
        }}
      >
        This section of the Professionals Hub shows the most recent professionals to have worked at
        your office.
        <span
          style={{
            marginLeft: 5,
            marginTop: -2,
            position: 'absolute',
            fontStyle: 'normal',
            fontWeight: 700,
          }}
        >
          <Tooltip
            content={`
              The recent professionals list displays the last ${MAX_RECENT_PROFESSIONALS} 
              professionals to have completed shifts at your office. 
              This list excludes favorites and blocked professionals.
            `}
          />
        </span>
      </div>

      <div
        style={{
          maxWidth: '827px',
          marginTop: 32,
          display: 'flex',
          justifyContent: 'center',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <motion.div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 24,
          }}
          layout
        >
          <AnimatePresence>
            <CardActionsHeader actions={['Invite', 'Favorite', 'Block']} />
            {recentProfessionals?.map((job) => (
              <ProfessionalCard
                key={job.id}
                user={{
                  id: job?.professional?.id,
                  fullname: job?.professional?.full_name,
                  profession: job.profession,
                  profileImage: job.professional?.image,
                  datetime: job?.job_datetime,
                  isAvailable: true,
                  jobId: job.id,
                  rating:
                    !job?.professionalRating && !job?.ratingCount
                      ? null
                      : {
                          averageRating: job?.professionalRating?.averageRating || job?.ratingCount,
                          interpersonalSkills:
                            job?.professionalRating?.interpersonalSkills || job?.ratingCount,
                          professionalSkills:
                            job?.professionalRating?.professionalSkills || job?.ratingCount,
                          timeManagement:
                            job?.professionalRating?.timeManagement || job?.ratingCount,
                        },
                }}
                Details={ProfessionalCardDetails}
                Actions={ProfessionalCardActions}
                showRemoveIcon={false}
              />
            ))}
          </AnimatePresence>
        </motion.div>
      </div>
    </div>
  );
};
