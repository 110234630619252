import React from 'react';
import moment from 'moment';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Colors } from '../../../themes/colors';
import { isEmployeeOption } from '../../WorkerClassification/utils';
import {
  CounterCoverStatusEnum,
  getReceiptStatusColor,
  getStatusText,
  returnTransactionComponent,
} from './utils';
import { FETCH_JOBS_WITH_TRANSACTIONS, FETCH_RECEIPT_LIST } from '../../../actions';
import { createLoadingSelector } from '../../../api/selectors';

import sortArrows from '../../../images/sortArrows.svg';
import arrowDown from '../../../images/arrowDown.svg';
import arrowUp from '../../../images/arrowUp.svg';
import { showCandidateOvertime } from '../../../growthbook';

const AllReceiptTable = React.forwardRef(({ sort, onSort, onScroll }, ref) => {
  const history = useHistory();
  const user = useSelector((state) => state.user.user);
  const receipts = useSelector((state) => state.job.receipts);

  const loadingSelector = createLoadingSelector([FETCH_RECEIPT_LIST, FETCH_JOBS_WITH_TRANSACTIONS]);
  const isLoading = useSelector((state) => loadingSelector(state));

  const renderSortArrowButton = (sortName) => {
    if (sortName === sort.field) {
      if (sort.order === 'desc') {
        return <img src={arrowDown} alt="arrow_down" style={Styles.arrowStyle} />;
      }
      return <img src={arrowUp} alt="arrow_up" style={Styles.arrowStyle} />;
    }
    return <img src={sortArrows} alt="sort_arrow" style={Styles.arrowStyle} />;
  };

  return (
    <div
      ref={ref}
      onScroll={onScroll}
      style={{
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 20,
        overflowY: 'auto',
        overflowX: 'auto',
      }}
    >
      <div
        style={{
          width: '100%',
        }}
      >
        <div
          style={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            backgroundColor: Colors.white,
          }}
        >
          <div
            style={{
              width: '100%',
              margin: 10,
              display: 'flex',
            }}
          >
            <span style={Styles.titleText}>Office</span>
            <span style={Styles.titleTextButton} onClick={() => onSort('date')}>
              Charge date
              {renderSortArrowButton('date')}
            </span>
            {!showCandidateOvertime() ? (
              <span style={Styles.titleText}>Professional Pay</span>
            ) : (
              <span style={Styles.titleText}>Total Professional Pay</span>
            )}
            <span style={Styles.titleText}>Service Fee</span>
            {isEmployeeOption(user) && <span style={Styles.titleText}>Employee Fee</span>}
            <span style={Styles.titleText}>Convenience Fee</span>
            <span style={Styles.titleText}>Total Amount</span>
            <span style={Styles.titleText}>Shift Date</span>
            <span style={Styles.titleTextButton} onClick={() => onSort('status')}>
              Status
              {renderSortArrowButton('status')}
            </span>
          </div>

          <div style={{ height: 1, backgroundColor: Colors.neutral_50 }} />
        </div>

        {receipts?.map((receipt, index) => {
          const { hygienistPay, tmFee, convenienceFee, employeeOptionFee } =
            returnTransactionComponent(receipt);

          return (
            <motion.div
              key={receipt.id}
              initial={{ opacity: 0, x: -10 * (index % 8) }}
              animate={{ opacity: 1, x: 0 }}
              whileHover={{
                boxShadow:
                  '0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
                transition: { duration: 0.3 },
              }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              style={{
                flex: 1,
                margin: 10,
                display: 'flex',
                backgroundColor: Colors.neutral_50,
                padding: '8px 0px',
                cursor: 'pointer',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '50px',
              }}
              onClick={() =>
                history.push(`job/${receipt?.jobId}/details`, {
                  job_id: receipt?.jobId,
                  jobId: receipt?.jobId,
                })
              }
            >
              <span style={Styles.normalText}>{receipt.offer_owner?.office_name}</span>

              <span style={Styles.normalText}>{`${moment(receipt.date).format(
                'ddd, MMM DD, YYYY',
              )}`}</span>

              <span style={Styles.normalText}>
                {hygienistPay
                  ? `${receipt.type === 'credit' ? '-' : ''} $${hygienistPay?.toFixed(2)}`
                  : '-'}
              </span>

              <span style={Styles.normalText}>
                {tmFee ? `${receipt.type === 'credit' ? '-' : ''} $${tmFee?.toFixed(2)}` : '-'}
                <br />
                {receipt.counterCover?.status === CounterCoverStatusEnum.APPLIED && (
                  <span style={Styles.discountText}>Discount Applied</span>
                )}
              </span>

              {isEmployeeOption(user) && (
                <span style={Styles.normalText}>
                  {employeeOptionFee
                    ? `${receipt.type === 'credit' ? '-' : ''} $${employeeOptionFee?.toFixed(2)}`
                    : '-'}
                </span>
              )}

              <span style={Styles.normalText}>
                {convenienceFee
                  ? `${receipt.type === 'credit' ? '-' : ''} $${convenienceFee?.toFixed(2)}`
                  : '-'}
              </span>

              <span
                style={{
                  fontFamily: 'Nunito',
                  fontSize: 16,
                  color: Colors.neutral_600,
                  textTransform: 'capitalize',
                  width: '20%',
                  margin: 10,
                  fontWeight: 'bold',
                  textAlign: 'center',
                }}
              >
                {receipt.type === 'credit'
                  ? `- $${receipt.amount.toFixed(2)}`
                  : `$${receipt.amount.toFixed(2)}`}
              </span>

              <span style={Styles.normalText}>
                {moment(receipt.jobDate).format('ddd, MMM DD, YYYY')}
              </span>

              <span
                style={{
                  fontFamily: 'Nunito',
                  fontSize: 12,
                  color: getReceiptStatusColor(receipt),
                  textTransform: 'uppercase',
                  width: '20%',
                  margin: 10,
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
              >
                {getStatusText(receipt)}
              </span>
            </motion.div>
          );
        })}

        {isLoading && (
          <div
            style={{
              width: '100%',
              height: 100,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ClipLoader size={50} color={Colors.primary_500} loading />
          </div>
        )}
      </div>
    </div>
  );
});

const Styles = {
  titleTextShift: {
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    fontSize: '18px',
    color: Colors.primary_500,
    width: 'calc(100% / 6)',
    textAlign: 'center',
  },
  titleText: {
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    fontSize: 16,
    color: Colors.primary_500,
    textTransform: 'capitalize',
    width: '20%',
    textAlign: 'center',
  },
  titleTextButton: {
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    fontSize: 16,
    color: Colors.primary_500,
    textTransform: 'capitalize',
    width: '20%',
    textAlign: 'center',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
  },
  normalText: {
    fontFamily: 'Nunito',
    fontSize: 15,
    color: Colors.neutral_600,
    textTransform: 'capitalize',
    width: '20%',
    margin: '0px 20px',
    textAlign: 'center',
  },
  discountText: {
    fontSize: 12,
    color: Colors.neutral_500,
  },
  arrowStyle: {
    width: 10,
    marginLeft: 8,
  },
};

AllReceiptTable.displayName = 'AllReceiptTable';

export default AllReceiptTable;
