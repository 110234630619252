import React, { useRef, useEffect } from 'react';

import { Colors } from '../../themes/colors';
import Button from '../../commonComponents/Button';

export default function ConfirmAdjustmentPopup({
  closePopupClicked,
  btnConfirmClicked,
  adjustedHours,
  adjustedBreak,
  totalDifferenceHours,
}) {
  const wrapperRef = useRef(null);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          closePopupClicked();
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef);

  const DetailedDataContainer = (data) => (
    <div
      style={{
        fontSize: 14,
        color: Colors.neutral_600,
        fontFamily: 'Nunito',
        textAlign: 'center',
      }}
    >
      <h3
        style={{
          fontSize: 18,
          color: Colors.neutral_600,
          fontFamily: 'Nunito',
          textAlign: 'center',
          marginBottom: 5,
          marginTop: 14.25,
          lineHeight: '22px',
        }}
      >
        {`${data.title}:`}
      </h3>
      {`${data.value}`}
    </div>
  );

  return (
    <div className="modal" style={{ position: 'fixed' }}>
      <div
        className="modal_content"
        ref={wrapperRef}
        style={{
          position: 'relative',
          width: 'auto',
          padding: '44px 55px',
        }}
      >
        <div style={{ width: 500 }}>
          <h1
            className="title_font"
            style={{
              color: Colors.neutral_600,
              fontFamily: 'Nunito',
              fontSize: 18,
              fontWeight: 700,
              lineHeight: '24px',
              marginBottom: 20,
              marginTop: 0,
              textAlign: 'center',
            }}
          >
            Confirm Adjustment
          </h1>
          <div
            style={{
              color: Colors.neutral_500,
              fontFamily: 'Nunito',
              fontSize: 16,
              lineHeight: '21px',
              textAlign: 'center',
            }}
          >
            Please confirm the difference in your adjustment request
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 8,
              padding: '20px 0 28px',
              width: '100%',
            }}
          >
            <DetailedDataContainer title="Hours" value={adjustedHours} />
            <DetailedDataContainer title="Meal Break" value={adjustedBreak} />
          </div>
          <div
            style={{
              fontSize: 14,
              color: Colors.neutral_600,
              fontFamily: 'Nunito',
              textAlign: 'center',
            }}
          >
            <h3
              style={{
                fontSize: 18,
                color: Colors.primary_500,
                fontFamily: 'Nunito',
                textAlign: 'center',
                marginBottom: 4,
                marginTop: 0,
              }}
            >
              Total Difference:
            </h3>
            <strong>{totalDifferenceHours}</strong>
          </div>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              margin: '28px 0 0',
              justifyContent: 'center',
              gap: 16,
            }}
          >
            <Button
              type="button"
              text="Go Back"
              style={{
                width: 152,
                height: 50,
                backgroundColor: Colors.secondary_500,
              }}
              onClick={closePopupClicked}
            />
            <Button
              type="button"
              text="Confirm"
              style={{ width: 152, height: 50 }}
              onClick={btnConfirmClicked}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
