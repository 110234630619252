import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  EDIT_USER,
  EDIT_USER_OFFICE_INFO,
  editUser,
  editUserOfficeInfo,
  fetchPartnershipOrganizations,
  getAllSoftwares,
  getOfficeEducation,
} from '../../../../actions';
import { UserProfession } from '../../../../enums/UserProfession';
import useLoading from '../../../../hooks/useLoading';
import { isObjectEqual } from '../../../../utils/Object';
import { SoftwareUtils } from '../../../../utils/SoftwareUtils';
import { useFormStatus } from '../useFormStatus/useFormStatus';

export const useAccountInformation = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.selectedChildOffice ?? state.user.user);
  const officeCredentials = useSelector((state) => state.userCredentials.credentials);

  const educationInfo = officeCredentials?.education;

  const { isLoading } = useLoading([EDIT_USER_OFFICE_INFO, EDIT_USER]);

  const softwares = useMemo(
    () => SoftwareUtils.getSoftwareTranslations(educationInfo?.softwares),
    [educationInfo],
  );

  const defaultValues = {
    officeName: user?.office_name || '',
    website: user?.web_site || '',
    dentistTitleName: user?.title || 'Dr.',
    dentistFirstName: user?.first_name || '',
    dentistLastName: user?.last_name || '',
    additionalContactTitleName: user?.account_admin?.[0]?.title || 'Dr.',
    additionalContactFirstName: user?.account_admin?.[0]?.first_name || '',
    additionalContactLastName: user?.account_admin?.[0]?.last_name || '',
    officeType: user?.office_info?.officeType || '',
    selectedSpecialty:
      Object.keys(educationInfo?.specialtiesAndProcedures || {})[0] ||
      'specialty_General Dentistry',
    softwares,
    insurances: officeCredentials?.insurances || [],
    notes: user?.notes_for_temp || '',
    isParkingFree: user?.parkingInformation?.isParkingFree,
    parkingCost: user?.parkingInformation?.cost || '',
    parkingCostType: user?.parkingInformation?.costType || 'hr',
    parkingType: user?.parkingInformation?.parkingType || '',
    timePerPatientRDH: user?.office_info?.timePerPatientRDH || '',
    timePerPatientDA: user?.office_info?.timePerPatientDA || '',
    timePerPatientDN: user?.office_info?.timePerPatientDN || '',
    isAssistedHygiene: user?.office_info?.isAssistedHygiene,
    productivity: {
      operatories: user?.office_info?.productivity?.operatories || null,
      permanentStaff: {
        [UserProfession.RDH]: user?.office_info?.productivity?.permanentStaff?.RDH || null,
        [UserProfession.DA]: user?.office_info?.productivity?.permanentStaff?.DA || null,
        [UserProfession.DN]: user?.office_info?.productivity?.permanentStaff?.DN || null,
      },
      bookedOutPatients: user?.office_info?.productivity?.bookedOutPatients || null,
    },
    partnershipOrganization: user?.partnerReferral?.partnershipCode?.organization || '',
    partnershipCode: user?.partnerReferral?.partnershipCode?.code || '',
  };

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const { control, handleSubmit, getValues, reset } = methods;
  const formValues = getValues();

  const { isButtonDisabled, isDirty, isValid, hasWarning } = useFormStatus({
    control,
    formValues,
    defaultValues,
  });

  const onSubmit = (values) => {
    const data = {
      office_name: values.officeName,
      web_site: values.website,
      title: values.dentistTitleName,
      first_name: values.dentistFirstName,
      last_name: values.dentistLastName,
      account_admin_title: values.additionalContactTitleName,
      account_admin_first_name: values.additionalContactFirstName,
      account_admin_last_name: values.additionalContactLastName,
      notes_for_temp: values.notes,
      parkingInformation: {
        isParkingFree: values.isParkingFree,
        cost: Number(values.parkingCost),
        costType: values.parkingCostType,
        parkingType: values.parkingType,
      },
      ...(!isObjectEqual(defaultValues.productivity, values.productivity) && {
        productivity: values.productivity,
      }),
    };

    dispatch(editUser({ data }));

    dispatch(
      editUserOfficeInfo({
        data: {
          education: {
            specialtiesAndProcedures: {
              [values.selectedSpecialty]: [],
            },
            softwares: SoftwareUtils.getSoftwareOriginalValues(values.softwares),
          },
          timePerPatientRDH: values.timePerPatientRDH,
          timePerPatientDA: values.timePerPatientDA,
          timePerPatientDN: values.timePerPatientDN,
          isAssistedHygiene: values.isAssistedHygiene,
          officeType: values.officeType,
        },
        insurances: values.insurances,
      }),
    );
  };

  useEffect(() => {
    dispatch(getAllSoftwares());
    dispatch(fetchPartnershipOrganizations());
    dispatch(getOfficeEducation());
  }, [dispatch]);

  // For some reason, useForm value is not updated when officeCredentials
  // even though `defaultValues` is updated
  //
  // This effect is for reassign values to useForm
  useEffect(() => {
    if (isDirty) {
      reset(defaultValues);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, educationInfo]);

  return {
    methods,
    isLoading,
    onSubmit: handleSubmit(onSubmit),
    isButtonDisabled,
    isDirty,
    isValid,
    hasWarning,
  };
};
