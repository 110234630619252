import moment from 'moment';
import { apiMethodsConst } from '~/services/methods';
import {
  IAuthReducerState,
  IForgotPasswordFlow,
  TAuthAction,
  TAuthRefreshRequestAction,
} from './interfaces';

export const initialState: IAuthReducerState = {
  accessToken: '',
  deviceToken: '',
  expirationDate: null,
  refreshToken: '',
  forgotPasswordFlow: {
    value: '',
    method: '',
    step: 'verification',
    showErrorModal: false,
  },
};

export default (
  state: IAuthReducerState = initialState,
  action: TAuthAction,
): IAuthReducerState => {
  switch (action.type) {
    case apiMethodsConst.SIGN_IN_REQUEST:
    case apiMethodsConst.AUTH_SIGN_UP_REQUEST:
    case apiMethodsConst.AUTH_REFRESH_REQUEST: {
      const typedAction = action as TAuthRefreshRequestAction;
      const newRefreshToken = typedAction.payload?.refresh_token || '';

      const tokenDuration = parseInt(typedAction.payload?.expires_in || '0', 10);
      const expirationDate =
        tokenDuration > 0
          ? moment()
              .add(tokenDuration - 10, 'seconds')
              .toDate()
          : null;

      localStorage.setItem('refresh_token_dental', newRefreshToken);

      return {
        ...state,
        accessToken: typedAction.payload?.access_token || '',
        expirationDate,
        refreshToken: newRefreshToken,
      };
    }

    case apiMethodsConst.FORGOT_PASSWORD_REQUEST.SEND_CODE: {
      return {
        ...state,
        forgotPasswordFlow: {
          ...action.payload,
          step: 'reset',
          showErrorModal: false,
        } as IForgotPasswordFlow,
      };
    }

    case apiMethodsConst.FORGOT_PASSWORD_REQUEST.RESET:
      return {
        ...state,
        forgotPasswordFlow: {
          value: '',
          method: '',
          step: 'successful',
          showErrorModal: false,
        },
      };

    case 'HIDE_FORGOT_PASSWORD_ERROR_MODAL':
      return {
        ...state,
        forgotPasswordFlow: {
          ...state.forgotPasswordFlow,
          showErrorModal: false,
        },
      };

    case 'CLEAR_FORGOT_PASSWORD_STATE':
      return {
        ...state,
        forgotPasswordFlow: {
          value: '',
          method: '',
          step: 'verification',
          showErrorModal: false,
        },
      };

    default:
      return state;
  }
};
