import { Colors } from '../../themes/colors';

export const BellIcon = ({ color = Colors.secondary_500, size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9996 2.3999C8.02319 2.3999 4.79964 5.62345 4.79964 9.5999V13.9028L3.95112 14.7514C3.60792 15.0946 3.50525 15.6107 3.69099 16.0591C3.87673 16.5075 4.31429 16.7999 4.79964 16.7999H19.1996C19.685 16.7999 20.1226 16.5075 20.3083 16.0591C20.494 15.6107 20.3914 15.0946 20.0482 14.7514L19.1996 13.9028V9.5999C19.1996 5.62345 15.9761 2.3999 11.9996 2.3999Z"
      fill={color}
    />
    <path
      d="M11.9996 21.5999C10.0114 21.5999 8.39961 19.9881 8.39961 17.9999H15.5996C15.5996 19.9881 13.9878 21.5999 11.9996 21.5999Z"
      fill={color}
    />
  </svg>
);
