import React, { useState, useEffect, useRef } from 'react';
import '../themes/global.scss';
import { useSelector } from 'react-redux';
import '../themes/job_summary.scss';
import '../themes/payment.scss';
import defaultProfileImage from '../images/defaultProfileImage.png';
import arrowIcon from '../images/arrow-switch-horizontal.png';
import { Colors } from '../themes/colors';

export default function SelectProfilePopup({ cardHasBennClicked, callSearchChildAction }) {
  const childOffices = useSelector((state) => state.user.childOffices);
  const filteredChilds = useSelector((state) => state.user.filteredChilds);
  const masterOfficeId = useSelector((state) => state.user.user.id);

  const user = useSelector((state) => state.user.selectedChildOffice);
  const selectedOfficeId = useSelector((state) => state.user.selectedOfficeId);

  const [showChangeOffice, setChangeOfficePopup] = useState(false);
  const [searchChildOffice, setSearchChildOffice] = useState('');

  const wrapperRef = useRef(null);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setChangeOfficePopup(false);
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef);

  useEffect(() => {
    callSearchChildAction(searchChildOffice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchChildOffice]);

  const rendeAdminLabel = (showAdminLabel) => {
    if (showAdminLabel) {
      return (
        <div
          style={{
            backgroundColor: Colors.secondary_500,
            borderRadius: 3,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: -10,
            zIndex: 4,
            alignSelf: 'center',
            position: 'initial',
            width: 55,
            height: 20,
          }}
        >
          <div
            style={{
              color: Colors.white,
              fontFamily: 'Nunito',
              fontSize: 12,
              fontWeight: 900,
            }}
          >
            ADMIN
          </div>
        </div>
      );
    }
    return <></>;
  };
  const renderProfileIcon = (profileImage, heightWidthStyle, borderRadius, showAdminLabel) => {
    if (profileImage) {
      return (
        <div
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column-reverse',
            display: 'flex',
          }}
        >
          {rendeAdminLabel(showAdminLabel)}
          <img
            alt="profile image"
            src={profileImage}
            style={{
              width: heightWidthStyle,
              height: heightWidthStyle,
              borderRadius,
              resizeMode: 'contain',
              zIndex: 0,
            }}
          />
        </div>
      );
    }
    return (
      <div
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column-reverse',
          display: 'flex',
        }}
      >
        {rendeAdminLabel(showAdminLabel)}

        <img
          src={defaultProfileImage}
          style={{
            width: heightWidthStyle,
            height: heightWidthStyle,
            resizeMode: 'contain',
          }}
          alt="profile image"
        />
      </div>
    );
  };
  const officeNameAddressHeader = () => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: 10,
        maxWidth: 250,
      }}
    >
      <div
        style={{
          fontSize: 18,
          color: Colors.neutral_600,
          fontFamily: 'Nunito',
          fontWeight: 'bold',
          marginBottom: 8,
        }}
      >
        {`${user.office_name || ''}`}
      </div>

      <div
        style={{
          fontFamily: 'Nunito',
          fontSize: 15,
          width: '90%',
          color: '#546e7a',
          marginTop: 5,
        }}
      >
        {user.address && user.address.length !== 0 ? user.address[0]?.formatted_address : 'N/A'}
      </div>
    </div>
  );
  const listOffices = searchChildOffice !== '' && filteredChilds ? filteredChilds : childOffices;

  const renderOfficeList = () => {
    if (listOffices.length === 0) {
      return (
        <div
          style={{
            width: '100%',
            display: 'flex',
            margin: 10,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              fontFamily: 'Nunito',
              fontWeight: 'bold',
              color: Colors.neutral_600,
              fontSize: 16,
            }}
          >
            There are no offices
          </div>
        </div>
      );
    }
    return listOffices
      .filter((o) => o.id !== selectedOfficeId)
      .map((office) => (
        <div
          key={office.id}
          style={{ width: '100%', cursor: 'pointer' }}
          onClick={() => {
            setChangeOfficePopup(!showChangeOffice);
            cardHasBennClicked(office.id);
          }}
        >
          <div
            style={{
              display: 'flex',
              margin: 10,
              alignItems: 'center',
            }}
          >
            <div style={{ marginRight: 10 }}>
              {renderProfileIcon(office?.profile_image, 60, 30, masterOfficeId === office.id)}
            </div>
            <div>
              <div
                style={{
                  fontFamily: 'Nunito',
                  fontWeight: 'bold',
                  color: masterOfficeId === office.id ? Colors.primary_500 : Colors.neutral_600,
                  fontSize: 16,
                }}
              >
                {office.office_name}
              </div>
              <div
                style={{
                  fontFamily: 'Nunito',
                  color: Colors.neutral_600,
                  margin: 2,
                  fontSize: 14,
                }}
              >
                {office.address && office.address.length !== 0
                  ? office.address[0]?.formatted_address
                  : 'N/A'}
              </div>
            </div>
          </div>
        </div>
      ));
  };
  const renderChangeOfficeView = () => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: 330,
        backgroundColor: Colors.white,
        position: 'absolute',
        zIndex: 5,
        padding: 10,
        left: 20,
        borderRadius: '10px',
        maxHeight: 500,
        overflowY: 'auto',
        boxShadow: '0px 2px 4px rgba(0,0,0,0.5)',
      }}
      ref={wrapperRef}
    >
      <div
        className="card-body"
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          minHeight: 130,
        }}
        onClick={() => setChangeOfficePopup(!showChangeOffice)}
      >
        <div style={{ margin: 20 }}>
          {renderProfileIcon(user?.profile_image, 80, 40, user.id === masterOfficeId)}
        </div>
        {officeNameAddressHeader()}
      </div>
      <div
        style={{
          height: 1,
          backgroundColor: Colors.neutral_100,
          opacity: 0.5,
          marginBottom: 20,
        }}
      />
      <input
        type="text"
        placeholder="Enter office name"
        onChange={(e) => {
          setChangeOfficePopup(true);
          setSearchChildOffice(e.target.value);
        }}
        value={searchChildOffice}
        style={{
          width: '80%',
          padding: 5,
          alignSelf: 'center',
          margin: 10,
          height: 35,
          border: `1px solid ${Colors.neutral_100}`,
          fontFamily: 'Nunito',
        }}
      />
      {renderOfficeList()}
    </div>
  );

  return (
    <div
      style={{
        cursor: 'pointer',
        marginBottom: 20,
      }}
    >
      {showChangeOffice ? renderChangeOfficeView() : <></>}
      <div
        className="card-body"
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          minHeight: 130,
        }}
        onClick={() => setChangeOfficePopup(!showChangeOffice)}
      >
        <div style={{ margin: 20 }}>
          {renderProfileIcon(user?.profile_image, 80, 40, user.id === masterOfficeId)}
        </div>
        {officeNameAddressHeader()}

        <div style={{ paddingRight: 25, alignSelf: 'center', marginTop: 10 }}>
          <img alt="arrow" src={arrowIcon} height={24} width={24} />
        </div>
      </div>
      <div
        style={{
          height: 1,
          width: '90%',
          backgroundColor: Colors.neutral_100,
          alignSelf: 'center',
        }}
      />
    </div>
  );
}
