import {
  CLEAR_MULTIDAY_FORM,
  DELETE_JOB_FROM_MDB,
  EDIT_JOB_INFO_MDB,
  SELECT_DAYS_MDB,
  SELECT_DEFAULT_INFO_MDB,
} from '~/actions';
import { sortDates } from '~/components/Request/multiday/utils';
import { IMultiDayJobInfo } from '~/models';
import { IMultiDayState, TMultiDayAction } from './interface';

const initialState: IMultiDayState = {
  jobDates: null,
  jobInfo: [],
};

export default (state: IMultiDayState = initialState, action: TMultiDayAction): IMultiDayState => {
  switch (action.type) {
    case SELECT_DAYS_MDB:
      return {
        ...state,
        jobDates: sortDates(Object.keys(action.jobData)),
      };

    case SELECT_DEFAULT_INFO_MDB: {
      return {
        ...state,
        jobInfo: (state.jobDates || [])
          .map((localDate: string) => ({ ...action.jobData, localDate }))
          .sort(
            (a: IMultiDayJobInfo, b: IMultiDayJobInfo) =>
              new Date(a.localDate).getTime() - new Date(b.localDate).getTime(),
          ),
      };
    }
    case DELETE_JOB_FROM_MDB: {
      const { deletedJob } = action;

      const deletedJobIndex = state.jobInfo.findIndex((job) => job.localDate === deletedJob);

      if (deletedJobIndex === -1) {
        return { ...state };
      }

      return {
        ...state,
        jobInfo: [
          ...state.jobInfo.slice(0, deletedJobIndex),
          ...state.jobInfo.slice(deletedJobIndex + 1),
        ],
      };
    }
    case EDIT_JOB_INFO_MDB: {
      const { jobData, jobDate } = action;

      const editJobIndex = state.jobInfo.findIndex((job) => job.localDate === jobDate);

      if (editJobIndex === -1) {
        return state;
      }

      return {
        ...state,
        jobInfo: [
          ...state.jobInfo.slice(0, editJobIndex),
          { ...jobData, localDate: jobDate },
          ...state.jobInfo.slice(editJobIndex + 1),
        ],
      };
    }
    case CLEAR_MULTIDAY_FORM:
    case 'MULTIDAY_BOOKING_INVITE_POST_SUCCESS':
    case 'MULTIDAY_BOOKING_POST_SUCCESS':
      return {
        ...state,
        jobInfo: [],
        jobDates: null,
      };

    default:
      return { ...state };
  }
};
