import React, { ButtonHTMLAttributes, CSSProperties, DetailedHTMLProps } from 'react';
import '../themes/button.scss';
import { ClipLoader } from 'react-spinners';
import { TColorKeys, Colors } from '../themes/colors';

interface IProps
  extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  text: string;
  Icon?: any;
  color?: 'primary' | 'secondary' | 'tertiary';
  iconClassName?: string;
  iconStyle?: CSSProperties;
  isLoading?: boolean;
  loadingColor?: TColorKeys;
  size?: 'xsmall' | 'small' | 'medium' | 'large';
  textClassName?: string;
  textStyle?: CSSProperties;
}

const getButtonClassName = (props: IProps) => {
  // Default class names
  const classNames = ['button', props.size, props.color];

  if (props.disabled || props.isLoading) {
    classNames.push('disabled');
  }

  if (props.className) {
    classNames.push(props.className);
  }

  return classNames.join(' ');
};

const getIconClassName = (props: IProps) => {
  // Default class names
  const classNames = ['icon'];

  if (props.iconClassName) {
    classNames.push(props.iconClassName);
  }

  return classNames.join(' ');
};

const getTextClassName = (props: IProps) => {
  // Default class names
  const classNames = ['text'];

  if (props.textClassName) {
    classNames.push(props.textClassName);
  }

  return classNames.join(' ');
};

const Button = (props: IProps) => {
  const {
    size = 'medium',
    color = 'primary',
    text,
    textStyle,
    Icon,
    iconStyle,
    disabled = false,
    isLoading = false,
    loadingColor = Colors.white,
    ...rest
  } = props;

  const defaultProps = {
    size,
    color,
    ...props,
  };

  return (
    <button
      type="button"
      {...rest}
      disabled={isLoading || disabled}
      className={getButtonClassName({ ...defaultProps })}
    >
      {!isLoading ? (
        <>
          {Icon && (
            <img
              src={Icon}
              style={iconStyle}
              className={getIconClassName({ ...defaultProps })}
              alt="button-icon"
            />
          )}
          {text && (
            <span style={textStyle} className={getTextClassName(defaultProps)}>
              {text}
            </span>
          )}
        </>
      ) : (
        <ClipLoader size={24} color={loadingColor} loading={isLoading} />
      )}
    </button>
  );
};

export default Button;
