import { HIDE_MESSAGE } from '~/actions';
import { ISuccessState, TSuccessAction } from './interface';

const initialState: ISuccessState = {};

export default (state: ISuccessState = initialState, action: TSuccessAction): ISuccessState => {
  const { type } = action;

  const isHideMessage = type === HIDE_MESSAGE;

  if (isHideMessage) {
    const { payload } = action;
    const isArrayPayload = Array.isArray(payload);

    if (isArrayPayload) {
      return payload.reduce(
        (newState, key) => ({
          ...newState,
          [key]: false,
        }),
        { ...state },
      );
    }

    return {
      ...state,
      [payload]: false,
    };
  }

  const matches = /(.*)_(REQUEST|SUCCESS)/.exec(type);

  if (!matches) {
    return {
      ...state,
    };
  }

  const [, requestName, requestState] = matches;

  return {
    ...state,
    [requestName as string]: requestState === 'SUCCESS',
  };
};
