export const SoftwareUtils = {
  /**
   * Translates software names into their abbreviated forms if available.
   *
   * @param {string[]} softwares - An array of software names to be translated.
   * @returns {string[]} An array of translated or original software names.
   */
  getSoftwareTranslations(softwares = []) {
    const translation = {
      'Dental Office Xpress (DOX)': 'DOX',
      'Quadra Dental Software': 'Quadra Dental',
    };

    return (softwares || []).map((software) => translation[software] || software);
  },

  /**
   * Translates abbreviated software names back to their original full names.
   *
   * @param {string[]} softwares - An array of abbreviated software names to be translated.
   * @returns {string[]} An array of original or untranslated software names.
   */
  getSoftwareOriginalValues(softwares = []) {
    const translation = {
      DOX: 'Dental Office Xpress (DOX)',
      'Quadra Dental': 'Quadra Dental Software',
    };

    return (softwares || []).map((software) => translation[software] || software);
  },
};
