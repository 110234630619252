export const CloseCircleIcon = ({ size = 24 }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M70.5549 37.2221C69.0219 37.2221 67.7771 38.4669 67.7771 39.9998C67.7771 55.3158 55.3153 67.7776 39.9993 67.7776C24.6834 67.7776 12.2216 55.3158 12.2216 39.9998C12.2216 24.6839 24.6834 12.2221 39.9993 12.2221C47.4559 12.2221 54.4559 15.1353 59.7112 20.4269C60.791 21.5172 62.5497 21.5224 63.6382 20.4408C64.7268 19.3609 64.732 17.6023 63.6521 16.5137C57.3483 10.163 48.9473 6.6665 39.9993 6.6665C21.6191 6.6665 6.66602 21.6196 6.66602 39.9998C6.66602 58.38 21.6191 73.3332 39.9993 73.3332C58.3796 73.3332 73.3327 58.38 73.3327 39.9998C73.3327 38.4669 72.0879 37.2221 70.5549 37.2221Z"
      fill="#307F92"
      stroke="#307F92"
      strokeWidth="0.925"
    />
    <path
      d="M29.9531 46.536C28.7656 47.7565 29.0712 49.6679 30.5434 50.4179C31.5104 50.9093 32.6024 50.7183 33.4462 49.878C35.5364 47.7981 37.6163 45.7113 39.7014 43.6263C39.8437 43.4839 39.9965 43.3502 40.1649 43.194C40.3298 43.3485 40.4687 43.4718 40.5989 43.6037C42.243 45.2461 43.8854 46.8919 45.5295 48.5343C46.0503 49.0551 46.5538 49.5968 47.0989 50.0898C48.5295 51.3815 50.7378 50.6575 51.1146 48.7773C51.3021 47.8415 50.9392 47.0863 50.2795 46.4318C48.1892 44.3537 46.1093 42.2652 44.026 40.1801C43.8854 40.0395 43.7534 39.8884 43.5503 39.6697C43.7743 39.4874 43.9635 39.3589 44.1232 39.1992C46.1701 37.1575 48.21 35.1089 50.2587 33.0707C50.7587 32.5742 51.1146 32.0169 51.1562 31.2964C51.2135 30.2999 50.658 29.3797 49.7569 28.9648C48.8055 28.5273 47.7864 28.7009 46.9948 29.4874C44.8628 31.6037 42.7448 33.7339 40.6198 35.8589C40.4774 36.0013 40.3246 36.1349 40.1562 36.2912C39.9774 36.1211 39.8264 35.9822 39.6823 35.8381C37.5972 33.7547 35.5104 31.6714 33.4305 29.5829C32.96 29.1106 32.4218 28.7895 31.7482 28.7461C30.75 28.6801 29.8229 29.2252 29.3975 30.1141C28.9427 31.069 29.1215 32.1124 29.9271 32.9231C32.0295 35.0412 34.1441 37.1506 36.2604 39.2565C36.4062 39.4006 36.6007 39.4961 36.842 39.661C35.7934 40.7027 34.8941 41.5915 33.9982 42.4839C32.6475 43.8311 31.283 45.1697 29.9531 46.536Z"
      fill="#307F92"
      stroke="#307F92"
      strokeWidth="1.54167"
      strokeMiterlimit="10"
    />
  </svg>
);
