import { call, put, take, all, fork, select } from 'redux-saga/effects';
import * as actions from '../actions';
import { apiMethods } from '../services/api';
import { apiMethodsConst } from '../services/methods';

function* callAndDispatch(method, payload) {
  const response = yield call(apiMethods[method], payload);
  yield put({ type: method, payload: response });
}

function* watchtabChangeInbox() {
  actions.createRequestTypes(actions.SELECT_CONTENT_TYPE_INBOX);
  while (true) {
    yield take(actions.SELECT_CONTENT_TYPE_INBOX);
  }
}

function* watchGetAllMessages() {
  const events = actions.createRequestTypes(actions.GET_USER_MESSAGES);
  while (true) {
    const { state } = yield take(actions.GET_USER_MESSAGES);

    try {
      yield select((store) => store.messages);
      if (JSON.stringify(state)) {
        yield put({ type: events.REQUEST, payload: state });

        yield* callAndDispatch(apiMethodsConst.GET_MESSAGES_REQUEST, state);

        yield put({
          type: events.SUCCESS,
        });
      }
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchGetMessagesByJob() {
  const events = actions.createRequestTypes(actions.GET_MESSAGES_BY_JOB_ID);
  while (true) {
    const { jobId } = yield take(actions.GET_MESSAGES_BY_JOB_ID);
    yield put({ type: events.REQUEST });
    try {
      const { job } = yield select((store) => store.job);

      if (job?.id !== jobId) {
        yield* callAndDispatch(apiMethodsConst.FETCH_JOB_REQUEST, jobId);
      }

      yield* callAndDispatch(apiMethodsConst.GET_MESSAGES_BY_JOB_ID_REQUEST, jobId);

      yield put({
        type: events.SUCCESS,
      });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchPostNewMessage() {
  const events = actions.createRequestTypes(actions.POST_NEW_MESSAGE);
  while (true) {
    const { jobId, message } = yield take(actions.POST_NEW_MESSAGE);
    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatch(apiMethodsConst.POST_MESSAGE_REQUEST, { jobId, message });
      yield put({
        type: events.SUCCESS,
      });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

function* watchRequestAdmin() {
  const events = actions.createRequestTypes(actions.REQUEST_ADMIN);
  while (true) {
    const { jobId } = yield take(actions.REQUEST_ADMIN);
    yield put({ type: events.REQUEST });
    try {
      yield* callAndDispatch(apiMethodsConst.INVITE_ADMIN_REQUEST, jobId);
      yield put({
        type: events.SUCCESS,
      });
    } catch (error) {
      yield put({ type: events.FAILURE, payload: error.response?.data });
    }
  }
}

// use them in parallel
export default function* sagaAlerts() {
  yield all([
    fork(watchGetAllMessages),
    fork(watchGetMessagesByJob),
    fork(watchPostNewMessage),
    fork(watchRequestAdmin),
    fork(watchtabChangeInbox),
  ]);
}
