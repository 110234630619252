import React, { useState } from 'react';
import { Colors } from '../themes/colors';

export default function DeclineCandidatePopup({ declineBtnPressed, goBackPressed }) {
  const [cancellationReason, setCancellationReason] = useState('');

  return (
    <div
      style={{
        flex: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        zIndex: 10,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <div
        style={{
          backgroundColor: Colors.white,
          alignContent: 'center',
          justifyContent: 'center',
          maxWidth: 700,
          minWidth: 450,
          width: '30%',
          borderRadius: 25,
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 50,
          boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3)',
          shadowColor: Colors.neutral_400,
          shadowOpacity: 0.3,
          shadowOffset: {
            height: 3,
            width: 0,
          },
        }}
      >
        <div
          style={{
            width: '85%',
            alignSelf: 'center',
            padding: 25,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              color: Colors.neutral_600,
              fontSize: 18,
              fontFamily: 'Nunito',
              textAlign: 'center',
              fontWeight: 'bold',
            }}
          >
            Decline Offer
          </div>

          <div
            style={{
              color: Colors.neutral_600,
              fontSize: 16,
              fontFamily: 'Nunito',
              textAlign: 'center',
              fontWeight: 'bold',
              marginTop: 30,
            }}
          >
            Are you sure you want to decline?
          </div>
          <div
            style={{
              color: Colors.neutral_600,
              fontSize: 16,
              fontFamily: 'Nunito',
              textAlign: 'center',
              fontWeight: 'bold',
            }}
          >
            Let us know why:
          </div>

          <textarea
            className="textarea-style"
            style={{ alignSelf: 'center', width: '85%', marginTop: 30 }}
            placeholder="Provide details here..."
            value={cancellationReason}
            onChange={(e) => setCancellationReason(e.target.value)}
          />

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '70%',
              alignSelf: 'center',
              gap: 20,
            }}
          >
            <button
              className="green-button"
              style={{
                alignSelf: 'center',
                width: 150,
                backgroundColor: Colors.secondary_500,
              }}
              onClick={() => goBackPressed()}
            >
              <p className="white-text-18">Go Back</p>
            </button>

            <button
              className="green-button"
              style={{
                alignSelf: 'center',
                width: 150,
                backgroundColor: Colors.primary_500,
              }}
              onClick={() => declineBtnPressed(cancellationReason)}
            >
              <p className="white-text-18">Decline</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
