import { Column, Row, Text } from '../../commonComponents';
import { Popover } from '../../commonComponents/Popover/Popover';
import StarIcon from '../../images/icons/StarIcon';
import { RatingItem } from './RatingItem';
import { useOfficeRating } from './hooks/useOfficeRating';

export const OfficeRating = () => {
  const { criteria, isShowRating, overall } = useOfficeRating();

  if (!isShowRating) {
    return null;
  }

  return (
    <Popover
      position="bottom"
      Content={() => (
        <Column
          gap={24}
          justifyContent="center"
          paddingBottom={8}
          paddingTop={8}
          style={{
            minWidth: 340,
          }}
        >
          <Text bold fontSize={18} textAlign="center">
            Office Rating
          </Text>
          {criteria.map((item) => (
            <RatingItem key={item} {...item} />
          ))}
        </Column>
      )}
      Target={() => (
        <Row
          alignItems="center"
          justifyContent="center"
          gap={4}
          style={{
            cursor: 'pointer',
          }}
        >
          <StarIcon width={24} height={24} />
          <Text
            bold
            fontSize={18}
            style={{
              textDecoration: 'underline',
            }}
          >
            {overall?.toFixed(1)}
          </Text>
        </Row>
      )}
    />
  );
};
