import { Colors } from '../../../themes/colors';

export const styles = {
  titleBlue: {
    color: Colors.primary_500,
    fontSize: 16,
    fontWeight: 'bold',
    fontFamily: 'Nunito',
    marginTop: 20,
  },
  descriptionGray: {
    color: Colors.neutral_600,
    fontSize: 16,
    fontFamily: 'Nunito',
  },
  descriptionWhite: {
    color: Colors.white,
    fontSize: 16,
    fontFamily: 'Nunito',
  },
  descriptionTitleGray: {
    marginTop: 10,
    color: Colors.neutral_600,
    fontWeight: 'bold',
    fontSize: 16,
    fontFamily: 'Nunito',
  },
  roundButton33: {
    width: '33%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  roundButton45: {
    width: '45%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  titleGreen: {
    color: Colors.success_500,
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Nunito',
  },
  titleGray: {
    color: Colors.neutral_600,
    fontSize: 16,
    fontWeight: 'bold',
    fontFamily: 'Nunito',
  },
  buttonWhiteTextStyle: {
    color: Colors.white,
    fontSize: 16,
    fontWeight: 'bold',
    fontFamily: 'Nunito',
  },
  blueInterviewButton: {
    width: '90%',
    height: 50,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.primary_500,
    borderRadius: 25,
    marginBottom: 10,
    cursor: 'pointer',
  },
  darkGrayTextStyle: {
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    fontSize: 15,
    color: Colors.neutral_600,
    textTransform: 'capitalize',
    marginBottom: 40,
  },
  interviewIconStyle: {
    width: 20,
    height: 20,
    marginRight: 5,
    marginBottom: 5,
  },
  greenTitle: {
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    fontSize: 15,
    color: Colors.success_500,
  },
  whiteTextStyle: {
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    fontSize: 15,
    color: Colors.white,
    textTransform: 'capitalize',
  },
  blueTextStyle: {
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    fontSize: 15,
    color: Colors.primary_500,
  },
  interviewView: {
    display: 'flex',
    alignItems: 'center',
  },

  whiteTextBigFont: {
    color: Colors.white,
    fontSize: 24,
    fontWeight: 'bold',
    fontFamily: 'Nunito',
  },

  descriptionWhiteBigFont: {
    color: Colors.white,
    fontSize: 18,
    fontFamily: 'Nunito',
  },
  whiteTextBigFount: {
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    fontSize: 20,
    color: Colors.white,
    textTransform: 'capitalize',
  },
};
