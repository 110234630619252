import { apiMethodsConst } from '~/services/methods';
import { UPDATE_RATING_COUNT } from '~/actions';
import { IFeedbackState, TFeedbackAction } from './interface';

const initialState: IFeedbackState = {
  feedbackJobId: '',
  feedbackRequired: [],
  feedbackRequiredDate: null,
  isFeedbackDone: false,
  ratingCount: 0,
};

export default (state: IFeedbackState = initialState, action: TFeedbackAction): IFeedbackState => {
  const { payload, type } = action;

  switch (type) {
    case apiMethodsConst.FEEDBACK_REQUEST: {
      const jobs = action.payload;
      return {
        ...state,
        feedbackRequired: jobs,
        feedbackRequiredDate: jobs.length <= 1 ? new Date() : null,
      };
    }
    case apiMethodsConst.FEEDBACK_SAVE_REQUEST: {
      return {
        ...state,
        isFeedbackDone: true,
        feedbackJobId: null,
        feedbackRequired: state.feedbackRequired.filter((job) => job.id !== payload.joboffer_id),
      };
    }
    case UPDATE_RATING_COUNT: {
      return {
        ...state,
        ratingCount: action.payload,
      };
    }
    case 'FEEDBACK_GET_STATUS_ACTION_SUCCESS': {
      return {
        ...state,
        isFeedbackDone: action.payload.iSRatingAndComments,
        feedbackJobId: action.payload.jobId,
      };
    }
    default: {
      return { ...state };
    }
  }
};
