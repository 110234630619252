import { Text } from '../../commonComponents';
import { Colors } from '../../themes/colors';
import { useJobStatus } from './hooks/useJobStatus/useJobStatus';

export const JobStatusInfo = ({ job }) => {
  const { color, label } = useJobStatus(job);

  return (
    <div
      className="global_font"
      style={{
        margin: 0,
        fontSize: 18,
        fontWeight: 'bold',
        color: Colors.DarkGray,
        display: 'flex',
        alignItems: 'center',
        gap: 4,
        alignSelf: 'center',
        marginBottom: 16,
      }}
    >
      <Text bold fontSize={18}>
        Status:
      </Text>
      <Text
        color={color}
        style={{
          textTransform: 'uppercase',
        }}
      >
        {label || job?.status}
      </Text>
    </div>
  );
};
