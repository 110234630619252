import { CSSProperties } from 'react';
import { getResolvedColor, getResolvedSpacing, getResolvedStyles } from '~/themes/utils';
import { ITextProps } from './interfaces';

export const Text = ({
  children,
  style,
  color = 'neutral_600',
  fontSize = 16,
  bold = false,
  ...rest
}: ITextProps) => {
  const baseStyles: CSSProperties = {
    fontFamily: 'Nunito',
    fontWeight: bold ? 700 : 500,
    fontSize,
    color: getResolvedColor(color),
    ...getResolvedSpacing(rest),
    ...style,
  };

  const { styles, htmlProps } = getResolvedStyles(baseStyles, rest);

  return (
    <span style={styles} {...htmlProps}>
      {children}
    </span>
  );
};
