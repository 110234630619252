import { Text } from '~/commonComponents';
import { ConfirmPopupContent } from '~/components/shared/ConfirmPopupContent';
import SuccessRepostIcon from '~/images/success-repost-icon.svg';

export const SuccessRepost = ({ onClose }) => (
  <ConfirmPopupContent
    Icon={() => <img src={SuccessRepostIcon} style={{}} alt="icon" />}
    title={
      <>
        Shift Reposted and Professionals
        <br />
        Have Been Alerted!
      </>
    }
    description={
      <Text textAlign="center" marginTop={-10} color="neutral_500">
        Alerts have been sent out to any on-call professionals, and the shift has been reposted as
        an urgent shift.
        <br />
        <br /> We apologize for any inconveniences that this may have caused.
      </Text>
    }
    descriptionStyle={{ marginBottom: 30 }}
    rightButtonStyle={{ width: 152 }}
    rightButtonAction={onClose}
    rightButtonText="Got It!"
  />
);
