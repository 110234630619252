import { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserMessages as getUserMessagesAction } from '../../../../actions';

const LIMIT_VALUE = 15;

export const useChatsInfiniteScroll = () => {
  const dispatch = useDispatch();

  const isActiveRequest = useSelector((state) => state.messages.isActiveRequest);
  const isFinalPage = useSelector((state) => state.messages.isFinalPage);

  const { page } = useSelector((store) => store.messages.searchParams);

  const elRef = useRef(null);

  const getUserMessages = useCallback(() => {
    if (isActiveRequest || isFinalPage) {
      return;
    }

    dispatch(getUserMessagesAction({ page: page + 1, limit: LIMIT_VALUE, status: 'unread' }));
  }, [dispatch, isActiveRequest, isFinalPage, page]);

  const onScrollListener = useCallback(() => {
    const el = elRef.current;

    const userScrollHeight = el?.scrollHeight - el?.scrollTop;
    const clientHeight = el?.clientHeight;

    if (userScrollHeight <= clientHeight) {
      getUserMessages();
    }
  }, [getUserMessages]);

  return {
    elRef,
    onScrollListener,
    getUserMessages,
  };
};
