import Tooltip from '../Tooltip';

export const headerUnselectedTitles = ['Office Name', 'State', 'Worker Type'];

export const headerConfirmedWorkerTypesTitles = [
  'Office Name',
  'State',
  'Request Date',
  'Set Date',
  'Worker Type',
];

export const pendingHeaderTitles = [
  'Office Name',
  'State',
  'Request Date',
  <span key="workerTypeRequested">
    Worker Type Requested
    <span
      style={{
        marginLeft: '5px',
        position: 'relative',
        top: '5px',
      }}
    >
      <Tooltip
        content={
          <>
            The worker type reflects the worker type selected by the office completing this shift.
            <br />
            <br />
            {'\u2022  '}
            “Employed” means that the professional will be engaged as an employee of our PEO.
            <br />
            <br />
            {'\u2022  '}
            “Contracted” means that an independent contractor has chosen to offer professional
            services through the GoTu platform.
          </>
        }
      />
    </span>
  </span>,
  'Approval',
];

export const WC_MANAGEMENT_STATUS = {
  CONFIRMED: 'confirmed',
  DECLINED: 'declined',
};

export const getInitialTab = (requests) => {
  if (requests?.pending?.length > 0) return 1;
  if (requests?.unselected?.length > 0) return 0;
  return 2;
};
