import { CSSProperties, FC, ReactNode } from 'react';
import { Colors } from '../../themes/colors';
import { Box } from '../Box';
import Button from '../Button';
import { Center } from '../Center';
import { Column } from '../Column';
import { Text } from '../Text';

interface IProps {
  Icon: FC;
  title: string;
  description: string | ReactNode;
  width?: number | string;
  buttonText?: string;
  onButtonClick?: () => void;
  descriptionStyle?: CSSProperties;
}

export const EmptyScreen = ({
  Icon,
  title,
  description,
  width = 405,
  buttonText,
  onButtonClick,
  descriptionStyle = {},
}: IProps) => (
  <Center>
    <Box
      style={{
        marginTop: 60,
        marginBottom: 28,
      }}
    >
      <Icon />
    </Box>
    <Column width={width} gap={8}>
      <Text fontSize={22} bold color={Colors.neutral_500} textAlign="center">
        {title}
      </Text>
      {/* @ts-expect-error -- missing props */}
      <Text fontSize={16} color={Colors.neutral_500} textAlign="center" {...descriptionStyle}>
        {description}
      </Text>
    </Column>
    {buttonText && onButtonClick && (
      <Button
        onClick={onButtonClick}
        text={buttonText}
        style={{ width: 296, height: 48, marginTop: 60 }}
      />
    )}
  </Center>
);
