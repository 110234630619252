import React from 'react';

import { motion } from 'framer-motion';
import ChevronRight from '../../../images/arrow_right.svg';
import MedicalBriefcaseIcon from '../../../images/medical-briefcase-icon.svg';
import Tooltip from '../../Tooltip';

import '../../../themes/procedure_collapsible_selection.scss';
import { SelectableChips } from '../../../commonComponents/SelectableChips';
import { EducationUtils } from '../../../utils/EducationUtils';
import { Colors } from '../../../themes/colors';
import { Text } from '../../../commonComponents';

export function ProcedureCollapsibleSelection({
  availableProcedures,
  selectedProcedureIndices,
  onItemPressed,
  useRowStyle = false,
  expanded = false,
  onPressed,
}) {
  const collapsableVariants = {
    open: {
      // TODO(lucky): calculate height instead of using fixed value
      maxHeight: 900,
      opacity: 1,
    },
    closed: {
      maxHeight: 0,
      opacity: 0,
    },
  };

  // TODO(lucky): refactor, can be replaced using CSS transition
  const arrowVariants = {
    open: {
      rotate: useRowStyle ? -90 : 90,
    },
    closed: {
      rotate: useRowStyle ? 90 : 0,
    },
  };

  return (
    <div
      className="procedure-collapsible-selection"
      style={{
        ...(useRowStyle && {
          border: `1.5px solid ${expanded ? Colors.secondary_500 : Colors.neutral_100}`,
          borderRadius: 8,
          width: '60%',
        }),
      }}
    >
      <details
        className="accordion"
        style={{
          ...(useRowStyle && {
            padding: '4px 32px',
            borderBottom: 0,
          }),
        }}
        onClick={(event) => {
          event.stopPropagation();

          if (onPressed) {
            onPressed();
          }
        }}
      >
        <summary className="accordion-label" style={{ width: '100%' }}>
          <div className="field-summary">
            <div className="field-summary-title">
              <img
                alt="icon"
                src={MedicalBriefcaseIcon}
                style={{
                  width: 20,
                  height: 20,
                  marginRight: 8,
                  marginLeft: 2,
                }}
              />
              <div className="field-placeholder">
                <p className="field-label">
                  <b>Expected Shift Procedures:</b>
                  <span className={['field-label--sub', expanded ? 'hidden' : ''].join(' ').trim()}>
                    &nbsp;&nbsp;(Optional)
                  </span>
                  <span className={['field-sub-label', expanded ? '' : 'hidden'].join(' ').trim()}>
                    (Select up to 4 procedures) (Optional)
                  </span>

                  <Tooltip
                    style={{
                      marginLeft: '5px',
                      position: 'relative',
                      top: '5px',
                    }}
                    content={
                      <div>
                        Shift Procedures:
                        <br />
                        <br />
                        Here you can select what the expected shift procedures will be for the
                        professional. This will provide clarity for visiting professionals, improve
                        matching accuracy, and elevate post-shift ratings.
                      </div>
                    }
                  />
                </p>
              </div>
            </div>
            <Text
              className="field-label--placeholder"
              color={selectedProcedureIndices?.length > 0 ? Colors.neutral_600 : Colors.neutral_300}
            >
              Shift Procedures
            </Text>
          </div>
          <motion.img
            initial="closed"
            animate={expanded ? 'open' : 'closed'}
            transition={{ duration: 0.3 }}
            variants={arrowVariants}
            alt="arrow"
            src={ChevronRight}
            style={{
              marginLeft: useRowStyle ? 16 : 30,
              marginRight: useRowStyle ? 0 : -30,
            }}
          />
        </summary>
      </details>
      <motion.div
        initial="closed"
        animate={expanded ? 'open' : 'closed'}
        transition={{ duration: 0.4 }}
        style={{ overflow: 'hidden' }}
        variants={collapsableVariants}
      >
        <div
          className="content"
          style={{
            ...(useRowStyle && {
              padding: '4px 32px',
            }),
          }}
        >
          {availableProcedures.length > 0 ? (
            <SelectableChips
              style={{ marginBottom: 24 }}
              values={EducationUtils.mapToRemoveProcedurePrefix(availableProcedures)}
              selectedIndices={new Set(selectedProcedureIndices)}
              onItemPressed={onItemPressed}
            />
          ) : (
            <p className="content-empty">
              <i>There are no shift procedures for this specialty preference at this time.</i>
            </p>
          )}
        </div>
      </motion.div>
    </div>
  );
}
