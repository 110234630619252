import { ILoadingState, TLoadingAction } from './interface';

const initialState: ILoadingState = {};

export default (state: ILoadingState = initialState, action: TLoadingAction): ILoadingState => {
  const { type } = action;

  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE|STOP_LOADING)/.exec(type);

  if (!matches) return state;

  const [, requestName, requestState] = matches;

  return {
    ...state,
    [requestName]: requestState === 'REQUEST',
  };
};
