import { jobCompletedByToday } from '../../utils';
import { Colors } from '../../../../themes/colors';
import { StatusEnum } from '../../../../utils/Enum';
import { getJobStatus } from '../../../Dashboard/ReceiptList/utils';

export const useJobStatus = (job) => {
  const { color, label } = getJobStatus(job?.status);

  const isJobCompletedByToday = jobCompletedByToday(job);

  return {
    color: isJobCompletedByToday ? Colors.primary_500 : color,
    label: isJobCompletedByToday ? StatusEnum.today : label,
  };
};
