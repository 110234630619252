import moment from 'moment';
import { ActionTypes } from '../components/PermanentJobs/CandidateCards/Enums';
import { apiMethodsConst } from '../services/methods';

const initialState = {
  applications: [],
  currentJob: null,
  allPPJobs: [],

  currentApplication: null,

  candidateApplication: [],
  declinedApplication: [],
  rejectedApplication: [],
  interviewApplication: [],
  decisiopnApplication: [],

  hiredCandidte: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case apiMethodsConst.FETCH_PP_APPLICATIONS_REQUEST: {
      const applications = action.payload;

      const candidateApplication = applications.filter(
        (ap) =>
          ap.lastApplicationAction?.action === 'show' ||
          ap.lastApplicationAction?.action === 'cancel-interview',
      );
      const declinedApplication = applications.filter(
        (ap) => ap.lastApplicationAction?.action === 'declined',
      );
      const rejectedApplication = applications.filter(
        (ap) => ap.lastApplicationAction?.action === 'rejected',
      );
      const interviewApplication = applications.filter(
        (ap) =>
          (ap.lastApplicationAction?.action.includes('interview-') &&
            ap.interview?.status === 'initialized' &&
            moment(ap.interview?.time).diff(moment(), 'minutes') < 0) ||
          (ap.interview?.status === 'scheduled' &&
            moment(ap.interview?.time).diff(moment(), 'minutes') > 0),
      );

      const decisiopnApplication = applications.filter(
        (ap) =>
          ap.lastApplicationAction?.action.includes('interview') &&
          ap.interview?.status === 'scheduled' &&
          moment(ap.interview?.time).diff(moment(), 'minutes') <= 0,
      );

      const hiredCandidte = applications.filter(
        (ap) => ap.lastApplicationAction?.action === 'hired',
      );

      return {
        ...state,
        applications,
        candidateApplication,
        declinedApplication,
        rejectedApplication,
        interviewApplication,
        decisiopnApplication,

        hiredCandidte,
      };
    }
    case apiMethodsConst.POST_PP_ACTIONS_REQUEST: {
      const { applicationId, lastApplicationAction } = action.payload;
      const index = state.applications.findIndex(
        (application) => application.applicationId === applicationId,
      );
      const updatedApplication = {
        ...state.applications[index],
        lastApplicationAction,
      };
      if (index === -1) {
        return state;
      }
      return {
        ...state,
        applications: [
          ...state.applications.slice(0, index),
          ...state.applications.slice(index + 1),
        ],

        candidateApplication: state.candidateApplication.filter(
          (app) => app.applicationId !== applicationId,
        ),
        // decline application
        declinedApplication:
          lastApplicationAction?.action === ActionTypes.declined
            ? [...state.declinedApplication, updatedApplication]
            : state.declinedApplication.filter((app) => app.applicationId !== applicationId),
        // reject application
        rejectedApplication:
          lastApplicationAction?.action === ActionTypes.rejected
            ? [...state.rejectedApplication, updatedApplication]
            : state.rejectedApplication.filter((app) => app.applicationId !== applicationId),
        // interview application
        interviewApplication: lastApplicationAction?.action.includes('interview')
          ? [...state.interviewApplication, updatedApplication]
          : state.interviewApplication.filter((app) => app.applicationId !== applicationId),
        decisiopnApplication: state.decisiopnApplication.filter(
          (app) => app.applicationId !== applicationId,
        ),

        hiredCandidte:
          lastApplicationAction?.action === ActionTypes.hired
            ? [...state.hiredCandidte, updatedApplication]
            : [...state.hiredCandidte],
      };
    }
    case apiMethodsConst.FETCH_ALL_PP_JOBS: {
      return {
        ...state,
        allPPJobs: action.payload.jobs,
        currentJob: action.payload[0],
      };
    }
    case apiMethodsConst.FETCH_PP_APPLICATION_BY_ID_REQUEST: {
      return {
        ...state,
        currentApplication: action.payload,
      };
    }
    default:
      return state;
  }
};
