import React, { Component } from 'react';
import '../themes/global.scss';
import { Link } from 'react-router-dom';

export default class Help extends Component {
  constructor(props) {
    super(props);
    this.state = {
      elementHeight: '',
      isVisible: true,
    };
  }

  componentDidMount() {
    if (sessionStorage.getItem('UserDetail') !== null) {
      if (sessionStorage.getItem('UserDetail').length > 0) {
        if (JSON.parse(sessionStorage.getItem('loggedIn')) !== true) {
          this.props.history.push('/');
        }

        this.setState({
          userDetails: JSON.parse(sessionStorage.getItem('UserDetail')),
          elementHeight: this.divRef.clientHeight,
        });
      }
    } else {
      this.props.history.push('/');
    }
  }

  render() {
    return (
      <div className="component_container" ref={(element) => (this.divRef = element)}>
        <div className="floating_container">
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <h1 className="global_font f-dark" style={{ marginLeft: 30 }}>
              Help Center
            </h1>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <p className="link" style={{ margin: '0px 0px 0px 55px', width: 430 }}>
                Customer Service Help Line: (786) 460-2170
              </p>
              <div className="line" style={{ width: '95%', margin: '0px 2.5%' }} />
              <p className="link" style={{ margin: '0px 0px 0px 55px', width: 430 }}>
                Text Help Line: (786) 460-2170
              </p>
              <div className="line" style={{ width: '95%', margin: '0px 2.5%' }} />
              <Link to="/dashboard/howitworks" className="link" style={{ marginLeft: 55 }}>
                How it Works
              </Link>
              <div className="line" style={{ width: '95%', margin: '0px 2.5%' }} />
              <Link to="/dashboard/about" className="link" style={{ marginLeft: 55 }}>
                About
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
