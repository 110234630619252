import {
  HIDE_CREATE_JOB_MESSAGE,
  HIDE_MESSAGE,
  SHOW_CREATE_JOB_MESSAGE,
  SHOW_MESSAGE,
} from '~/actions';
import { IMessageState, IPopupProps, TMessageAction } from './interface';

const initialState: IMessageState = {
  message: {
    isCreateJob: null,
    isError: null,
    isSuccess: null,
    jobData: null,
    params: null,
    popupProps: {} as IPopupProps,
    route: null,
    text: null,
    title: null,
  },
};

export default (state: IMessageState = initialState, action: TMessageAction): IMessageState => {
  const { type } = action;

  switch (type) {
    case SHOW_MESSAGE: {
      const { message, title, route, params, isSuccess, isError, popupProps } = action.payload;

      return {
        ...state,
        message: {
          text: message,
          title,
          route,
          params,
          isSuccess,
          isError,
          popupProps,
        } as IMessageState['message'],
      };
    }
    case SHOW_CREATE_JOB_MESSAGE: {
      return {
        ...state,
        message: {
          route: action.payload.route,
          params: action.payload.params,
          jobData: action.payload.jobData,
        } as IMessageState['message'],
      };
    }
    case HIDE_MESSAGE:
    case HIDE_CREATE_JOB_MESSAGE: {
      return {
        ...initialState,
      };
    }
    default:
      return { ...state };
  }
};
