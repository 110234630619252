import { useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  DELETE_ALL_ALERTS_ACTION,
  DELETE_ONE_ALERT_ACTION,
  GET_ALL_ALERTS_ACTION,
  GET_ALL_PAYMENTS_ACTION,
  GET_LAST_POSTED_JOBS,
  GET_USER_MESSAGES,
} from '../../../actions';
import { getHideNotificationsOnParentOffice } from '../../../growthbook';
import useLoading from '../../../hooks/useLoading';
import { BellWithAlertIcon, EmptyNotificationIcon } from '../../../images/icons';
import { EmptyInbox } from '../Empty';
import { ReminderList } from './Reminders/Reminder';
import { TaskList } from './Tasks/Task';
import { useNotificationCount } from './hooks/useNotificationCount';

export const Notification = () => {
  const isHideNotificationsOnParentOffice = useRef(getHideNotificationsOnParentOffice()).current;

  const isParentOffice = useSelector(
    (state) =>
      (state.user?.selectedChildOffice?.childOffices || state.user?.childOffices)?.length > 1,
  );

  const isParentOfficeAndHideNotificationsOnParentOffice =
    isParentOffice && isHideNotificationsOnParentOffice;

  const { isLoading } = useLoading([
    GET_ALL_ALERTS_ACTION,
    GET_USER_MESSAGES,
    DELETE_ALL_ALERTS_ACTION,
    DELETE_ONE_ALERT_ACTION,
    GET_LAST_POSTED_JOBS,
    GET_ALL_PAYMENTS_ACTION,
  ]);
  const totalNotifications = useNotificationCount();

  if (!isLoading && !totalNotifications) {
    return isParentOfficeAndHideNotificationsOnParentOffice ? (
      <EmptyInbox
        Icon={BellWithAlertIcon}
        title="No notifications here!"
        description={
          <>
            Check each office account individually
            <br />
            to review their notifications!
          </>
        }
      />
    ) : (
      <EmptyInbox
        Icon={EmptyNotificationIcon}
        title="You’re all caught up!"
        description={
          <>
            No notifications at the moment.
            <br />
            Check back later for any important updates!
          </>
        }
      />
    );
  }

  return (
    <>
      <ReminderList />
      <TaskList />
    </>
  );
};
