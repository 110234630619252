import { Box } from '~/commonComponents/Box';
import { Row } from '~/commonComponents/Row';
import { Text } from '~/commonComponents/Text';
import { Colors } from '~/themes/colors';

export const CalendarLegends = () => (
  <Row paddingHorizontal={16} paddingVertical={14} alignItems="center" gap={12}>
    <Text fontSize={18} bold>
      Shift Legend:
    </Text>
    <Row gap={12}>
      {[
        { label: 'Today', color: Colors.primary_500 },
        { label: 'Action Needed', color: Colors.error_450 },
        { label: 'Scheduled', color: Colors.secondary_500 },
        { label: 'Open', color: Colors.success_500 },
        { label: 'Completed', color: Colors.neutral_300 },
      ].map(({ label, color }) => (
        <Row key={label} marginLeft={10} alignItems="center" gap={8}>
          <Box backgroundColor={color} borderRadius="100%" height={14} width={14} />
          <Text color={Colors.neutral_500} fontSize={16} lineHeight="16px">
            {label}
          </Text>
        </Row>
      ))}
    </Row>
  </Row>
);
