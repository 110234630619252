import { Box } from '~/commonComponents';
import { EmptyFavoritesDescription } from '~/components/ProfessionalsHub/components/FavoritesList/EmptyFavoritesDescription';
import { TRootState } from '~/store/types';
import { fetchFavoriteProfessionals } from '../../../actions';
import { trackEvent } from '../../../api/analytics';
import { EmptyFavorites } from '../../../components/ProfessionalsHub/components/FavoritesList/EmptyFavorites';
import { SendInviteToModal } from '../SendInviteToModal';
import { filterFavorites, mapFavoriteToRowProps } from '../utils';

interface IProps {
  onClose: () => void;
  refetchJob?: boolean;
}

export const SendToFavoritesModal = ({ onClose, refetchJob = true }: IProps) => (
  <SendInviteToModal
    fetchProfessionalsAction={fetchFavoriteProfessionals}
    professionalsSelector={(state: TRootState) => state.user.favoriteProfessionals}
    filterFunction={filterFavorites}
    mapProfessionalToRowProps={mapFavoriteToRowProps}
    EmptyComponent={() => (
      <Box width="100%">
        <EmptyFavorites
          title="No Favorite Professionals"
          description={
            <Box marginTop={16}>
              <EmptyFavoritesDescription />
            </Box>
          }
          width="100%"
        />
      </Box>
    )}
    headerTitle="Favorite Professionals"
    headerSubtitle="Favorites will be confirmed if they accept the offer at the listed rate and time."
    onClose={onClose}
    refetchJob={refetchJob}
    listener={(job: any, professionalsIds: string[]) => {
      trackEvent('Send to - Favorite invite sent/submitted', {
        job: {
          professionalsIds,
          jobId: job.id,
          profession: job.profession,
          rate: job.rate,
          shiftId: job.shiftId,
          totalHours: job.totalHours,
          local_date: job.local_date,
          local_end_time: job.local_end_time,
          local_start_time: job.local_start_time,
          local_timezone: job.local_timezone,
          lunch_break_time: job.lunch_break_time,
          lunch_final_break_time: job.lunch_final_break_time,
          specialty: job.specialty,
        },
      });
    }}
  />
);
