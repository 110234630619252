import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { deleteAllAlerts, deleteOneAlert, hideMessage, showMessage } from '../../../../actions';

export const useChatsDeletion = () => {
  const dispatch = useDispatch();

  const handleDeleteAll = useCallback(() => {
    dispatch(
      showMessage({
        title: 'Confirm Delete',
        message: 'Are you sure you want to delete all your messages?',
        isError: true,
        popupProps: {
          leftButtonText: 'Go Back',
          leftButtonAction: () => dispatch(hideMessage()),
          rightButtonText: 'Remove',
          rightButtonAction: () => {
            dispatch(deleteAllAlerts());
            dispatch(hideMessage());
          },
        },
      }),
    );
  }, [dispatch]);

  const handleDeleteOneAlertSubmit = useCallback(
    (alertId) => {
      dispatch(deleteOneAlert({ alertId }));
    },
    [dispatch],
  );

  return {
    handleDeleteAll,
    handleDeleteOneAlertSubmit,
  };
};
