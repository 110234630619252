import Checkcircle from '../Checkcircle';

export const RadioOption = ({
  text,
  checked,
  onClick,
  textStyle,
  disabled = false,
  size = 'md',
  style = {},
}) => (
  <button
    type="button"
    style={{
      all: 'unset',
      display: 'flex',
      cursor: 'pointer',
      alignItems: 'center',
      ...style,
    }}
    onClick={onClick}
    disabled={disabled}
  >
    <Checkcircle checked={checked} size={size} />

    <p className="global_font f-dark" style={{ margin: '0px 0px 0px 12px', ...textStyle }}>
      {text}
    </p>
  </button>
);
