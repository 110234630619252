import { SELECT_CONTENT_TYPE_INBOX } from '~/actions';
import { MessageContent } from '~/enums/MessageContent';
import { apiMethodsConst } from '~/services/methods';
import { IMessagesState, TMessagesAction } from './interface';

const initialState: IMessagesState = {
  allJobsWithMessages: [],
  isActiveRequest: false,
  isFinalPage: false,
  messagedPerJob: [],
  searchParams: { limit: 10, page: 1 },
  selectedContent: MessageContent.Alerts,
  totalMessages: 0,
  totalUnread: 0,
};

export default (state: IMessagesState = initialState, action: TMessagesAction) => {
  const { type } = action;

  switch (type) {
    case apiMethodsConst.GET_MESSAGES_REQUEST: {
      const { conversations, page, total, unread, limit } = action.payload;

      const merged = [...state.allJobsWithMessages, ...conversations];

      return {
        ...state,
        allJobsWithMessages: page === 1 ? conversations : merged,
        isFinalPage:
          conversations.length === 0 ||
          conversations.length < (limit || state.searchParams.limit) ||
          conversations.length === state.totalMessages ||
          merged.length === state.totalMessages,
        totalMessages: total,
        isActiveRequest: false,
        totalUnread: unread,
      };
    }
    case apiMethodsConst.GET_MESSAGES_BY_JOB_ID_REQUEST:
      return {
        ...state,
        messagedPerJob: action.payload,
      };
    case apiMethodsConst.POST_MESSAGE_REQUEST:
    case apiMethodsConst.INVITE_ADMIN_REQUEST: {
      const msgCount = state.messagedPerJob.length;
      const lastMessage = state.messagedPerJob[msgCount - 1];

      if (msgCount !== 0 && lastMessage?.id === action.payload.id) {
        return { ...state };
      }

      return {
        ...state,
        messagedPerJob: [...state.messagedPerJob, action.payload],
      };
    }
    case SELECT_CONTENT_TYPE_INBOX: {
      return {
        ...state,
        selectedContent: action.payload,
      };
    }
    case 'GET_USER_MESSAGES_REQUEST': {
      return {
        ...state,
        searchParams: action.payload,
        isActiveRequest: true,
      };
    }
    default: {
      return { ...state };
    }
  }
};
