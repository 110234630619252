import arrowRightIcon from '../../../../../images/arrow_right.svg';
import shareIcon from '../../../../../images/share-icon-blue.svg';
import { StatusEnum } from '../../../../../utils/Enum';
import { getNumberOfCandidates } from '../../../../JobList/utils';

export const Image = ({
  profilePicture,
  label,
  onShareIconClick,
  small = false,
  multipleCandidates,
}) => {
  let source = '';
  let imgStyle = {};
  let onClick = () => {};

  switch (label) {
    case StatusEnum.pending:
      return getNumberOfCandidates(multipleCandidates);
    case StatusEnum.completed:
    case StatusEnum.scheduled:
    case StatusEnum.today:
      source = profilePicture;
      imgStyle = {
        width: small ? 60 : 48,
        height: small ? 60 : 48,
        borderRadius: '50%',
        resizeMode: 'contain',
      };
      break;
    case StatusEnum.open:
      source = arrowRightIcon;
      if (onShareIconClick) {
        source = shareIcon;
        onClick = (e) => {
          if (onShareIconClick) {
            e.stopPropagation();
            onShareIconClick();
          }
        };
      }
      break;
    default:
      break;
  }

  return source ? (
    <button style={{ all: 'unset' }} type="button" onClick={onClick}>
      <img alt="action" src={source} style={imgStyle} />
    </button>
  ) : null;
};
