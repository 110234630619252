import { useWatch } from 'react-hook-form';

export const useInsuranceProvider = (control) => {
  const watchedValues = useWatch({
    control,
    name: ['insurances'],
  });

  const formValues = {
    insurances: watchedValues[0],
  };

  const isValid = formValues.insurances?.length !== 0;

  return {
    formValues,
    isValid,
  };
};
