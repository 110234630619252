import React, { useRef, useEffect } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';

import GeneralHygInfo from './GeneralHygInfo';
import { Colors } from '../../../themes/colors';
import { InterviewType } from '../CandidateCards/Enums';
import { styles } from './StylesPopups';
import { createLoadingSelector } from '../../../api/selectors';
import CustomSpinner from '../../../commonComponents/CustomSpinner';
import { FETCH_APPLICATION_BY_ID_PP } from '../../../actions';

export default function InterviewDetailsPopup({ closePopupClicked, selectedCandidate }) {
  const wrapperRef = useRef(null);

  const loadingSelector = createLoadingSelector([FETCH_APPLICATION_BY_ID_PP]);

  const isLoading = useSelector((state) => loadingSelector(state));

  const currentApplication = useSelector((state) => state.pp.currentApplication);

  const isInterviewPending =
    selectedCandidate &&
    selectedCandidate.lastApplicationAction?.action.includes('interview') &&
    selectedCandidate.interview?.status === 'initialized';
  const isInterviewConfirmed =
    selectedCandidate &&
    selectedCandidate.lastApplicationAction?.action.includes('interview') &&
    selectedCandidate.interview?.status === 'scheduled';

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          closePopupClicked();
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef);

  const renderInterviewType = () => {
    if (selectedCandidate.interview?.type === InterviewType.call) {
      return 'Phone Interview';
    }
    if (selectedCandidate.interview?.type === InterviewType.inPerson) {
      return 'In-Person Interview';
    }
    if (selectedCandidate.interview?.type === InterviewType.working) {
      return 'Working Interview';
    }
  };
  return (
    <div
      style={{
        flex: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        zIndex: 10,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <div
        ref={wrapperRef}
        style={{
          backgroundColor: Colors.white,
          alignContent: 'center',
          justifyContent: 'center',
          maxWidth: 700,
          minWidth: 450,
          width: '30%',
          borderRadius: 25,
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 50,
          boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3)',
          shadowColor: Colors.neutral_400,
          shadowOpacity: 0.3,
          shadowOffset: {
            height: 3,
            width: 0,
          },
        }}
      >
        <div
          style={{
            marginTop: 8,
            fontSize: 20,
            fontWeight: 'bold',
            color: Colors.neutral_300,
            cursor: 'pointer',
            textAlign: 'right',
            marginRight: 20,
          }}
          onClick={() => closePopupClicked()}
        >
          X
        </div>
        {isLoading ? (
          <CustomSpinner isLoading={isLoading} />
        ) : (
          <div
            style={{
              width: '85%',
              alignSelf: 'center',
            }}
          >
            <GeneralHygInfo selectedCandidate={currentApplication} />

            {currentApplication?.resume?.officeNote && (
              <>
                <div style={styles.descriptionTitleGray}>Notes</div>

                <div style={styles.descriptionGray}>{currentApplication.resume.officeNote}</div>
              </>
            )}

            <div style={styles.descriptionTitleGray}>License #:</div>

            <div style={styles.descriptionGray}>{currentApplication?.resume?.licId || 'N/A'}</div>

            <div style={styles.descriptionTitleGray}>School Name:</div>

            <div style={styles.descriptionGray}>
              {currentApplication?.resume?.schoolName || 'N/A'}
            </div>

            <div style={styles.descriptionTitleGray}>State:</div>

            <div style={styles.descriptionGray}>{currentApplication?.resume?.state || 'N/A'}</div>

            <p style={styles.titleGreen}>Interview Status</p>

            {isInterviewPending ? (
              <div style={{ display: 'flex', gap: 5, marginBottom: 50 }}>
                <div style={styles.descriptionGray}>
                  {`${renderInterviewType()} Requested - Awaiting Confirmation of Interview`}
                </div>
              </div>
            ) : (
              <div />
            )}

            {isInterviewConfirmed ? (
              <div style={{ display: 'flex', gap: 5, marginBottom: 50 }}>
                <div style={styles.descriptionGray}>
                  {`${renderInterviewType()} Confirmed for ${moment(
                    selectedCandidate.interview?.time,
                  ).format('MM-DD-YYYY HH:mm A')}`}
                </div>
              </div>
            ) : (
              <div />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
