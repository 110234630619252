import { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  enableProfileCompletenessReminders,
  getHideNotificationsOnParentOffice,
} from '../../../../../growthbook';

export const useProfileCompleteness = () => {
  const isEnableProfileCompleteness = useRef(enableProfileCompletenessReminders()).current;
  const isHideNotificationsOnParentOffice = useRef(getHideNotificationsOnParentOffice()).current;

  const user = useSelector((state) => state.user.selectedChildOffice || state.user.user);
  const credentials = useSelector((state) => state.userCredentials);

  const isParentOffice = useSelector(
    (state) =>
      (state.user?.selectedChildOffice?.childOffices || state.user?.childOffices)?.length > 1,
  );
  const isParentOfficeAndHideNotificationsOnParentOffice =
    isParentOffice && isHideNotificationsOnParentOffice;

  const isAssistedHygieneFulfilled = typeof user?.office_info?.isAssistedHygiene === 'boolean';

  const isDentistNameFulfilled = useMemo(
    () => user?.full_name !== '' && user?.last_name !== '',
    [user?.full_name, user?.last_name],
  );

  const isInsurancesFulfilled = useMemo(
    () => (credentials?.credentials?.insurances || []).length > 0,
    [credentials?.credentials?.insurances],
  );

  const isOfficeNameFulfilled = useMemo(
    () => user?.office_info?.office_name !== '',
    [user?.office_info?.office_name],
  );

  const isOfficeSpecialtyFulfilled = useMemo(
    () =>
      Boolean(credentials?.credentials?.education) &&
      Boolean(credentials?.credentials?.education?.specialtiesAndProcedures) &&
      Object.keys(credentials?.credentials?.education?.specialtiesAndProcedures).length > 0,
    [credentials?.credentials?.education],
  );

  const isOperatorsFulfilled = useMemo(
    () =>
      Boolean(user?.office_info?.productivity) && user?.office_info?.productivity?.operatories > 0,
    [user?.office_info?.productivity],
  );

  const isParkingFulfilled = useMemo(
    () => Boolean(user?.parkingInformation),
    [user?.parkingInformation],
  );

  const isSoftwaresFulfilled = useMemo(
    () => (credentials?.credentials?.softwares || []).length > 0,
    [credentials?.credentials?.softwares],
  );

  const isTimePerPatientFulfilled = useMemo(() => {
    const timeFields = ['timePerPatientRDH', 'timePerPatientDA', 'timePerPatientDN'];
    const timeValues = timeFields.map((field) => user?.office_info?.[field]);

    const nullCount = timeValues.filter((value) => value === null).length;
    const nACount = timeValues.filter((value) => value === 'n/a').length;
    const validTimeCount = timeValues.filter(
      (value) => value && value !== 'n/a' && value !== null,
    ).length;

    if (nullCount === 3 || nACount === 3) return false;
    if (nullCount === 2 && validTimeCount === 1) return false;
    if (nullCount === 1 && validTimeCount === 2) return false;

    return nACount <= 2 && validTimeCount >= 1;
  }, [user?.office_info]);

  const isTypeOfOfficeFulfilled = useMemo(
    () => user?.office_info?.officeType?.length > 0,
    [user?.office_info?.officeType],
  );

  const isProfileFulfilled = useMemo(
    () =>
      isAssistedHygieneFulfilled &&
      isDentistNameFulfilled &&
      isInsurancesFulfilled &&
      isOfficeNameFulfilled &&
      isOfficeSpecialtyFulfilled &&
      isOperatorsFulfilled &&
      isParkingFulfilled &&
      isSoftwaresFulfilled &&
      isTimePerPatientFulfilled &&
      isTypeOfOfficeFulfilled,
    [
      isAssistedHygieneFulfilled,
      isDentistNameFulfilled,
      isInsurancesFulfilled,
      isOfficeNameFulfilled,
      isOfficeSpecialtyFulfilled,
      isOperatorsFulfilled,
      isParkingFulfilled,
      isSoftwaresFulfilled,
      isTimePerPatientFulfilled,
      isTypeOfOfficeFulfilled,
    ],
  );

  if (!isEnableProfileCompleteness || isParentOfficeAndHideNotificationsOnParentOffice) {
    return {
      isAssistedHygieneFulfilled: true,
      isDentistNameFulfilled: true,
      isInsurancesFulfilled: true,
      isOfficeNameFulfilled: true,
      isOfficeSpecialtyFulfilled: true,
      isOperatorsFulfilled: true,
      isParkingFulfilled: true,
      isProfileFulfilled: true,
      isSoftwaresFulfilled: true,
      isTimePerPatientFulfilled: true,
      isTypeOfOfficeFulfilled: true,
    };
  }

  return {
    isAssistedHygieneFulfilled,
    isDentistNameFulfilled,
    isInsurancesFulfilled,
    isOfficeNameFulfilled,
    isOfficeSpecialtyFulfilled,
    isOperatorsFulfilled,
    isParkingFulfilled,
    isProfileFulfilled,
    isSoftwaresFulfilled,
    isTimePerPatientFulfilled,
    isTypeOfOfficeFulfilled,
  };
};
