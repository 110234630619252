import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GET_ALL_JOBS_ACTION, changeSchedule } from '../../actions';
import { trackEvent } from '../../api/analytics';
import { createLoadingSelector } from '../../api/selectors';
import { ButtonGroup } from '../../commonComponents/ButtonGroup';
import CustomSpinner from '../../commonComponents/CustomSpinner';
import InputSelect from '../../commonComponents/InputSelect';
import calendarIconSVG from '../../images/calendar-outline.svg';
import allIconSVG from '../../images/receipts-all-icon.svg';
import { Colors } from '../../themes/colors';
import { Calendar } from './components/Calendar/Calendar';
import { CalendarLegends } from './components/Calendar/Legends';
import { JobList } from './components/JobList/JobList';
import { MonthSelection } from './components/MonthSelection/MonthSelection';

const loadingSelector = createLoadingSelector([GET_ALL_JOBS_ACTION]);

export const Schedule = () => {
  const dispatch = useDispatch();
  const schedule = useSelector((state) => state.job.schedule);
  const isLoading = useSelector((state) => loadingSelector(state));
  const [viewType, setViewType] = useState(schedule.view);
  const [statusFilter, setStatusFilter] = useState(schedule.filter);

  const handleFilterChange = (value) => {
    setStatusFilter(value);
    dispatch(changeSchedule({ payload: { option: 'filter', value } }));
  };

  const handleViewType = (value) => {
    setViewType(value);
    trackEvent(`Schedule ${viewType} view toggle clicked`);
    dispatch(changeSchedule({ payload: { option: 'view', value } }));
  };

  return (
    <div
      className="component_container"
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <span
        style={{
          color: Colors.neutral_600,
          textAlign: 'center',
          fontFamily: 'Nunito',
          fontSize: 32,
          fontWeight: '700',
          marginTop: 42,
        }}
      >
        Schedule
      </span>
      <div
        className="floating_container"
        style={{
          display: 'flex',
          boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.15)',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          height: '100%',
          width: 'auto',
        }}
      >
        <div
          style={{
            width: '-webkit-fill-available',
            display: 'flex',
            justifyContent: 'space-between',
            marginLeft: 60,
            marginRight: 60,
            alignItems: 'center',
            marginTop: 24,
            marginBottom: 36,
          }}
        >
          {viewType === 'calendar' && (
            <div>
              <MonthSelection />
            </div>
          )}
          {viewType === 'calendar' && isLoading && (
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
              }}
            >
              <CustomSpinner size={36} style={{ margin: 0 }} isLoading />
            </div>
          )}
          <div
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'flex-end',
              gap: 24,
            }}
          >
            <div
              style={{
                width: 210,
              }}
            >
              <InputSelect
                value={statusFilter}
                setValue={handleFilterChange}
                isSelectVisible={false}
                options={[
                  { title: 'All', value: 'all' },
                  { title: 'Today', value: 'today' },
                  { title: 'Action Needed', value: 'pending-counter' },
                  { title: 'Scheduled', value: 'filled' },
                  { title: 'Open', value: 'open' },
                  { title: 'Completed', value: 'past' },
                ]}
              />
            </div>
            <ButtonGroup
              value={viewType}
              options={[
                { value: 'calendar', src: calendarIconSVG },
                { value: 'list', src: allIconSVG },
              ]}
              onClick={handleViewType}
            />
          </div>
        </div>
        {viewType === 'list' && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              height: '100%',
              alignItems: 'center',
            }}
          >
            <JobList status={statusFilter} />
          </div>
        )}

        {viewType === 'calendar' && (
          <div style={{ width: '100%', marginBottom: 12 }}>
            <Calendar status={statusFilter} />
            <CalendarLegends />
          </div>
        )}
      </div>
    </div>
  );
};
