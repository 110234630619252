import { useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import {
  enablePaymentExpirationReminders,
  getHideNotificationsOnParentOffice,
} from '../../../../../growthbook';
import { useIsParentOfficeSelector } from '../../../../../hooks/useIsParentOfficeSelector';
import { useUserSelector } from '../../../../../hooks/useUserSelector';

export const useCheckPaymentExpiration = () => {
  const isParentOffice = useIsParentOfficeSelector();
  const payments = useSelector((state) => state.payments.allPayments);
  const user = useUserSelector();

  const isEnablePaymentExpiration = useRef(enablePaymentExpirationReminders()).current;
  const isHideNotificationsOnParentOffice = useRef(getHideNotificationsOnParentOffice()).current;

  const isParentOfficeAndHideNotificationsOnParentOffice =
    isParentOffice && isHideNotificationsOnParentOffice;

  const defaultPaymentMethod = useMemo(
    () => payments?.find((payment) => payment.is_selected),
    [payments],
  );

  const isBankAccount = defaultPaymentMethod?.type === 'bank_account';

  const checkPaymentExpiration = useCallback(
    (payment) => {
      const today = new Date();
      const currentYear = today.getFullYear();
      const currentMonth = today.getMonth() + 1;

      const isExpired =
        (payment?.expiry_year < currentYear ||
          (payment?.expiry_year === currentYear && payment?.expiry_month < currentMonth)) &&
        !isBankAccount;

      let isExpiring = false;

      if (!isExpired) {
        const monthsUntilExpiry =
          (payment?.expiry_year - currentYear) * 12 + (payment?.expiry_month - currentMonth);
        isExpiring = monthsUntilExpiry <= 2 && !isBankAccount;
      }

      return {
        isExpired,
        isExpiring,
      };
    },
    [isBankAccount],
  );

  const { isExpired, isExpiring } = useMemo(
    () => checkPaymentExpiration(defaultPaymentMethod),
    [checkPaymentExpiration, defaultPaymentMethod],
  );

  if (
    !isEnablePaymentExpiration ||
    user?.is_pre_approved ||
    isParentOfficeAndHideNotificationsOnParentOffice ||
    isBankAccount
  ) {
    return {
      isExpired: false,
      isExpiring: false,
      checkPaymentExpiration,
    };
  }

  return {
    isExpired,
    isExpiring,
    checkPaymentExpiration,
  };
};
