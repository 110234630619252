import { EmptyScreen } from '../../commonComponents/EmptyScreen/EmptyScreen';

export const EmptyInbox = ({ Icon, title, description }) => (
  <figure
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      margin: 0,
      gap: 24,
      boxShadow: '0px 2px 4px rgba(111, 131, 141, 0.325068)',
      borderRadius: 25,
      padding: '8px 0 64px',
    }}
  >
    <EmptyScreen
      Icon={Icon}
      title={title}
      description={description}
      descriptionStyle={{
        textAlign: 'center',
      }}
    />
  </figure>
);
