import { Colors } from '~/themes/colors';
import { Box, IBoxProps } from '../Box';

export const FloatingContainer = ({
  backgroundColor = Colors.white,
  borderRadius = 10,
  boxShadow = '0px 1px 3px rgba(0, 0, 0, 0.2)',
  children,
  flexDirection = 'column',
  margin = 25,
  minWidth = 800,
  padding = 10,
  ...rest
}: IBoxProps) => (
  <Box
    backgroundColor={backgroundColor}
    borderRadius={borderRadius}
    boxShadow={boxShadow}
    flexDirection={flexDirection}
    margin={margin}
    minWidth={minWidth}
    padding={padding}
    {...rest}
  >
    {children}
  </Box>
);
