import React, { useRef, useEffect, useState } from 'react';

import { Colors } from '../../../themes/colors';
import Button from '../../../commonComponents/Button';

export default function ConfirmHirePopup({
  closePopupClicked,
  confirmHasBeenClicked,
  selectedCandidate,
}) {
  const wrapperRef = useRef(null);
  const [confirmName, setConfirmName] = useState('');

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          closePopupClicked();
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef);

  const validateConfirm = () =>
    selectedCandidate.professional?.first_name.toString().replace(/ /g, '').toLowerCase() +
      selectedCandidate.professional?.last_name.replace(/ /g, '').toString().toLowerCase() ===
    confirmName.toString().replace(/ /g, '').toLowerCase();

  return (
    <div
      style={{
        flex: 1,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        zIndex: 10,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <div
        ref={wrapperRef}
        style={{
          backgroundColor: Colors.white,
          alignContent: 'center',
          padding: 10,
          justifyContent: 'center',
          maxWidth: 700,
          minWidth: 500,
          width: '40%',
          borderRadius: 25,
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 50,
          boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3)',
          shadowColor: Colors.neutral_400,
          shadowOpacity: 0.3,
          shadowOffset: {
            height: 3,
            width: 0,
          },
        }}
      >
        <div
          style={{
            marginTop: 8,
            fontSize: 20,
            fontWeight: 'bold',
            color: Colors.neutral_300,
            cursor: 'pointer',
            textAlign: 'right',
            marginRight: 20,
          }}
          onClick={() => closePopupClicked()}
        >
          X
        </div>

        <div
          style={{
            alignSelf: 'center',
          }}
        >
          <div
            style={{
              fontSize: 24,
              textAlign: 'center',
              marginBottom: 30,
              color: Colors.primary_500,
              fontFamily: 'Nunito',
              fontWeight: 'bold',
              margin: 10,
            }}
          >
            {
              // eslint-disable-next-line max-len
              `Please confirm that you wish to finalize the recruitment process and hire ${selectedCandidate.professional?.first_name} ${selectedCandidate.professional?.last_name}`
            }
          </div>
          <div
            className="global_font bold f-dark"
            style={{
              margin: 10,
              marginTop: 40,
              textAlign: 'center',
              fontFamily: 'Nunito',
            }}
          >
            Typing the hygienist’s full name below will confirm that you wish to close your
            recruitment contract
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignContent: 'center',
              justifyContent: 'center',
            }}
          >
            <input
              style={{
                backgroundColor: Colors.primary_50,
                padding: 10,
                fontFamily: 'Nunito',
                fontSize: 16,
                border: '0px solid #ffffff',
                borderRadius: 7,
                width: '80%',
                marginLeft: 0,
              }}
              placeholder=""
              value={confirmName}
              onChange={(e) => setConfirmName(e.target.value)}
            />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 30,
              marginBottom: 40,
              marginLeft: 40,
              marginRight: 40,
            }}
          >
            <Button
              text="Confirm"
              size="small"
              style={{ margin: 0 }}
              disabled={!validateConfirm()}
              onClick={() => {
                confirmHasBeenClicked();
              }}
            />

            <Button
              text="Cancel"
              size="small"
              color="secondary"
              style={{ margin: 0 }}
              onClick={() => {
                closePopupClicked();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
