import { useSelector } from 'react-redux';
import { Box, Column, Icon, Label, MultipleOptions, Row } from '../../../../commonComponents';
import { CollapsibleCard } from '../../../../commonComponents/CollapsibleCard';
import { Colors } from '../../../../themes/colors';
import Tooltip from '../../../Tooltip';
import { useFormContext } from '../../hooks/useFormContext';
import { CardTitle } from '../CardTitle';
import { useInsuranceProvider } from './hooks/useInsuranceProvider';

export const InsuranceProviderCard = ({ isOpen, onToggle }) => {
  const { setValue, control } = useFormContext();

  const { formValues, isValid } = useInsuranceProvider(control);
  const officeEducation = useSelector((state) => state.user.officeEducation);

  const handleOnClick = (code) => {
    let updatedInsurances;

    if (formValues.insurances.includes(code)) {
      updatedInsurances = formValues.insurances.filter((item) => item !== code);
    } else {
      updatedInsurances = [...formValues.insurances, code];
    }

    updatedInsurances.sort();

    setValue('insurances', updatedInsurances);
  };

  return (
    <CollapsibleCard
      TitleComponent={() => (
        <CardTitle name="Insurance Provider" showWarningIcon={!isOpen && !isValid} />
      )}
      isOpen={isOpen}
      onToggle={onToggle}
    >
      <Column gap={20} paddingHorizontal={80} marginBottom={40}>
        <Box>
          <Row alignItems="baseline">
            <Label text="What insurance provider does your office use?" marginBottom={20} />
            <Row
              style={{
                marginLeft: '5px',
                position: 'relative',
                top: '5px',
                marginRight: '5px',
                gap: 4,
              }}
            >
              <Tooltip content="GoTu now allows you to post temp shifts looking for a Associate Dentist. To be able to post those shifts, you will be required to indicate what insurance your office is covered under. This is to help us find Associate Dentists who are covered under the same insurance as your office." />
              {!isValid && <Icon name="warning-solid" color={Colors.warning_500} />}
            </Row>
          </Row>
          <MultipleOptions
            selected={formValues.insurances}
            onClick={handleOnClick}
            options={officeEducation?.insurances}
          />
        </Box>
      </Column>
    </CollapsibleCard>
  );
};
